import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "../Auth/axiosSetup";

function EditTechnicien() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const navigate = useNavigate();
  const { id } = useParams(); 
  const [loading, setLoading] = useState(false);
  const [formPrenom, setFormPrenom] = useState("");
  const [formNom, setFormNom] = useState("");
  const [formCode, setFormCode] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formTelephone, setFormTelephone] = useState("");
  const [formAdresse, setFormAdresse] = useState("");
  const [formCodePostal, setFormCodePostal] = useState("");
  const [formVille, setFormVille] = useState("");
  const [formLatitude, setFormLatitude] = useState("");
  const [formLongitude, setFormLongitude] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formPasswordConfirmation, setFormPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch the technician details when the component mounts
    const fetchTechnician = async () => {
      try {
        const response = await api.get(`/users/${id}`);
        const user = response.data;
        setFormPrenom(user.prenom);
        setFormNom(user.nom);
        setFormCode(user.code);
        setFormEmail(user.email);
        setFormTelephone(user.telephone);
        setFormAdresse(user.adresse);
        setFormCodePostal(user.code_postal);
        setFormVille(user.ville);
        setFormLatitude(user.latitude);
        setFormLongitude(user.longitude);
      } catch (error) {
        console.error("Failed to fetch technician:", error);
        alert("Erreur lors de la récupération des détails du technicien.");
      }
    };

    fetchTechnician();
  }, [apiHost, id]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await api.put(`/users/${id}`, {
        prenom: formPrenom,
        nom: formNom,
        code: formCode,
        email: formEmail,
        telephone: formTelephone,
        adresse: formAdresse,
        code_postal: formCodePostal,
        ville: formVille,
        latitude: formLatitude,
        longitude: formLongitude,
        password: formPassword,
        password_confirmation: formPasswordConfirmation,
      });
      navigate('/techniciens'); 
    } catch (error) {
      console.error("Failed to update user:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        alert("Erreur lors de la mise à jour du technicien.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {/*begin::Header */}
          <Header />
          {/*end::Header */}
          <div className="Customcontainer bg-white">
            <div
              className="row bg-white"
              style={{ flex: 1, minHeight: "91.1vh" }}
            >
              <div
                className="col-2 p-5"
                style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
              >
                {/* Contenu de la colonne de gauche */}
                <p
                  className="etech-sidebar-section"
                  style={{
                    color: "#3a3838",
                    fontWeight: "700",
                    padding: ".25em 0",
                  }}
                >
                  Rechercher un type d'activité
                </p>
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-subscription-table-filter="search"
                    className="form-control form-control-solid ps-12 mb-2 border"
                    placeholder="Recherche ..."
                    disabled
                  />
                </div>
                <div className="d-grid gap-2 col-6 mx-auto">
                  <Link to="/creatactivity" className="btn btn-primary">
                    Rechercher
                  </Link>
                </div>
              </div>
              <div className="col-10">
                {/* Contenu de la colonne de droite */}
                <h1
                  className="d-flex fw-bold my-1 fs-3 p-4"
                  style={{ fontSize: "1.5em", fontWeight: "400" }}
                >
                  Modifier Technicien
                </h1>
                <div className="card-body pt-0">
                  {/*begin::Post */}
                  <div className="content flex-row-fluid" id="kt_content">
                    {/*begin::Careers - Apply */}
                    <div className="card">
                      {/*begin::Body */}
                      <div className="card-body p-3">
                        {/*begin::Hero */}

                        <div className="d-flex flex-column flex-lg-row mb-17">
                          {/*begin::Content */}
                          <div className="flex-lg-row-fluid me-0">
                            {/*begin::Form */}
                            <form
                              action="m-0"
                              className="form mb-15"
                              method="post"
                              id="kt_careers_form"
                              onSubmit={handleFormSubmit}
                            >
                              {/* Include the same input groups as in the create form,
                                  but pre-fill with existing values */}
                              
                              {/* Nom Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Nom
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    value={formNom}
                                    onChange={(e) => setFormNom(e.target.value)}
                                    required
                                    placeholder="Nom"
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.nom ? 'is-invalid' : ''}`}
                                  />
                                  {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}
                                </div>
                              </div>

                              {/* Prénom Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Prénom
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder=""
                                    required
                                    value={formPrenom}
                                    onChange={(e) => setFormPrenom(e.target.value)}
                                    name="Prénom"
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.prenom ? 'is-invalid' : ''}`}
                                  />
                                  {errors.prenom && <div className="invalid-feedback">{errors.prenom}</div>}
                                </div>
                              </div>

                              {/* Code Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Code
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Code"
                                    required
                                    value={formCode}
                                    onChange={(e) => setFormCode(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                                  />
                                  {errors.code && <div className="invalid-feedback">{errors.code}</div>}
                                </div>
                              </div>

                              {/* Email Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Email
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="email"
                                    placeholder="Email"
                                    required
                                    value={formEmail}
                                    onChange={(e) => setFormEmail(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                  />
                                  {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </div>
                              </div>

                              {/* Telephone Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Téléphone
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Téléphone"
                                    required
                                    value={formTelephone}
                                    onChange={(e) => setFormTelephone(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                                  />
                                  {errors.telephone && <div className="invalid-feedback">{errors.telephone}</div>}
                                </div>
                              </div>

                              {/* Adresse Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Adresse
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Adresse"
                                    required
                                    value={formAdresse}
                                    onChange={(e) => setFormAdresse(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.adresse ? 'is-invalid' : ''}`}
                                  />
                                  {errors.adresse && <div className="invalid-feedback">{errors.adresse}</div>}
                                </div>
                              </div>

                              {/* Code Postal Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Code Postal
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Code Postal"
                                    required
                                    value={formCodePostal}
                                    onChange={(e) => setFormCodePostal(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.code_postal ? 'is-invalid' : ''}`}
                                  />
                                  {errors.code_postal && <div className="invalid-feedback">{errors.code_postal}</div>}
                                </div>
                              </div>

                              {/* Ville Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Ville
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Ville"
                                    required
                                    value={formVille}
                                    onChange={(e) => setFormVille(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.ville ? 'is-invalid' : ''}`}
                                  />
                                  {errors.ville && <div className="invalid-feedback">{errors.ville}</div>}
                                </div>
                              </div>

                              {/* Latitude Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Latitude
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Latitude"
                                    required
                                    value={formLatitude}
                                    onChange={(e) => setFormLatitude(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.latitude ? 'is-invalid' : ''}`}
                                  />
                                  {errors.latitude && <div className="invalid-feedback">{errors.latitude}</div>}
                                </div>
                              </div>

                              {/* Longitude Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Longitude
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="text"
                                    placeholder="Longitude"
                                    required
                                    value={formLongitude}
                                    onChange={(e) => setFormLongitude(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.longitude ? 'is-invalid' : ''}`}
                                  />
                                  {errors.longitude && <div className="invalid-feedback">{errors.longitude}</div>}
                                </div>
                              </div>

                              {/* Password Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Mot de passe
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="password"
                                    placeholder="Mot de passe"
                                    value={formPassword}
                                    onChange={(e) => setFormPassword(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                  />
                                  {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                </div>
                              </div>

                              {/* Password Confirmation Input Group */}
                              <div className="row mb-3">
                                <div className="col-md-3 d-flex justify-content-end">
                                  <label
                                    style={{
                                      color: "#0099e5",
                                      whiteSpace: "nowrap",
                                      paddingRight: "10px",
                                      paddingTop: "5px",
                                      fontSize: "14px",
                                    }}
                                    className="required fw-semibold text-right"
                                  >
                                    Confirmation du mot de passe
                                  </label>
                                </div>
                                <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                                  <input
                                    type="password"
                                    placeholder="Confirmation du mot de passe"
                                    value={formPasswordConfirmation}
                                    onChange={(e) => setFormPasswordConfirmation(e.target.value)}
                                    style={{
                                      width: "100%",
                                      maxWidth: "160px",
                                      height: "27px",
                                      marginLeft: "5px",
                                    }}
                                    className={`form-control ${errors.password_confirmation ? 'is-invalid' : ''}`}
                                  />
                                  {errors.password_confirmation && <div className="invalid-feedback">{errors.password_confirmation}</div>}
                                </div>
                              </div>

                              {/* Submit Button */}
                              <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                  >
                                    {loading ? "Enregistrement..." : "Enregistrer"}
                                  </button>
                                  <Link to="/techniciens" className="btn btn-secondary ms-2">
                                    Annuler
                                  </Link>
                                </div>
                              </div>
                            </form>
                            {/*end::Form */}
                          </div>
                          {/*end::Content */}
                        </div>
                        {/*end::Hero */}
                      </div>
                      {/*end::Body */}
                    </div>
                    {/*end::Careers - Apply */}
                  </div>
                  {/*end::Post */}
                </div>
              </div>
            </div>
          </div>
          {/*begin::Footer */}
          {/* <Footer /> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default EditTechnicien;
