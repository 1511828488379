import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { GoogleMap, Marker } from "@react-google-maps/api";

import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Recherche from "./components/Recherche";
import InterventionsTab from "./components/InterventionsTab";
import TechniciensTab from "./components/TechniciensTab";
import api from "../Auth/axiosSetup";

function DashbordPage() {
  const [widthLeft, setWidthLeft] = useState(900);
  const [widthRight, setWidthRight] = useState(window.innerWidth - 900);
  const minLeftWidth = 700; 
  const maxLeftWidth = window.innerWidth - 200; 

  const apiHost = process.env.REACT_APP_API_HOST;

  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true); 
  const [markerPosition, setMarkerPosition] = useState(null);

  const handleAddMarker = () => {
    const specificLatLng = {
      lat: 31.792306,
      lng: -7.080168,
    };
    setMarkerPosition(specificLatLng);
  };

  const handleResize = (event, { size }) => {
    // Ensure that the size.width doesn't go below the minimum or above the maximum
    const newWidthLeft = Math.max(minLeftWidth, Math.min(size.width, maxLeftWidth));
    setWidthLeft(newWidthLeft);
    setWidthRight(window.innerWidth - newWidthLeft);
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 31.792306,
    lng: -7.080168,
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(`/users`);
        setUsers(response.data); 
        setIsLoadingUsers(false); 
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoadingUsers(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="d-flex flex-column flex-root">
      <div className="page d-flex flex-row flex-column-fluid">
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div style={{ display: "flex", height: "89vh" }}>
            <ResizableBox
              width={widthLeft}
              height={Infinity}
              axis="x"
              minConstraints={[minLeftWidth, Infinity]} // Minimum width for the left panel
              maxConstraints={[maxLeftWidth, Infinity]} // Maximum width for the left panel
              resizeHandles={["e"]}
              onResizeStop={handleResize}
            >
              <div style={{ width: "100%", height: "100%", backgroundColor: "#f0f0f0" }}>
                <div className="card card-xl-stretch">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">Monitoring</span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Verifier vos Interventions & Techniciens
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <ul className="nav">
                        <li className="nav-item">
                          <a
                            className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1 active"
                            data-bs-toggle="tab"
                            href="#kt_table_widget_5_tab_1"
                          >
                            Interventions
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold px-4 me-1"
                            data-bs-toggle="tab"
                            href="#kt_table_widget_5_tab_2"
                          >
                            Techniciens
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body p-0" style={{ overflow: "scroll" }}>
                    <div className="tab-content">
                      <InterventionsTab />
                      <TechniciensTab />
                    </div>
                  </div>
                </div>
              </div>
            </ResizableBox>
            <div
              style={{
                flexGrow: 1,
                width: widthRight,
                backgroundColor: "#e0e0e0",
              }}
            >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={6}
                options={{ minZoom: "3" }}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashbordPage;
