import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ColumnSelectionModal from "../../components/ColumnSelectionModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import api from "../Auth/axiosSetup";

function Interventions() {
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const apiHost = process.env.REACT_APP_API_HOST;
  const [isLoadingInterventions, setIsLoadingInterventions] = useState(true);
  const [interventions, setInterventions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([
    "Description",
    "Status",
    "Date Creation",
  ]);
  const [totalPages, setTotalPages] = useState(0);
  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchString, setSearchString] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
   const fetchData = async () => {
      try {
        setIsLoadingInterventions(true);
        
        const response = await api.get(`/interventionsFront`, {
          params: {
            page: currentPage,
            limit: rowsPerPage,
            sort: sortColumn,
            order: sortDirection,
            search: searchString,
          },
        });
        
        setInterventions(response.data.data);
        setTotalPages(response.data.total_pages);
      } catch (error) {
        console.error("Error fetching interventions:", error);
        setError("Error fetching data");
      } finally {
        setIsLoadingInterventions(false);
      }
    };


    fetchData();
  }, [apiHost, currentPage, rowsPerPage, sortColumn, sortDirection, searchString]);

  const handleInputChange = (e) => {
    setSearchString(e.target.value);
  };

  const getBadgeClass = (row) => {
    if (row.sold_at) {
      return 'badge-light-danger';
    } else if (row.started_at) {
      return 'badge-light-warning';
    } else if (row.downloaded_at) {
      return 'badge-light-info';
    } else {
      return 'badge-light-success';
    }
  };

  const getStatusLabel = (row) => {
    if (row.sold_at) {
      return 'Réalisée';
    } else if (row.started_at) {
      return 'Démarrée';
    } else if (row.downloaded_at) {
      return 'Synchronisée';
    } else {
      return 'Planifié';
    }
  };

  const columns = [
    { name: "Description", selector: (row) => row.description, sortable: true, omit: !selectedColumns.includes("Description") },
    { name: "Status", selector: (row) => (<span className={`badge ${getBadgeClass(row)}`}>{getStatusLabel(row)}</span>), omit: !selectedColumns.includes("Status") },
    { name: "Date Creation", selector: (row) => new Date(row.created_at).toLocaleDateString(), sortable: true, omit: !selectedColumns.includes("Date Creation") },
    { name: "User Name", selector: (row) => `${row.user.prenom} ${row.user.nom}`, omit: !selectedColumns.includes("User Name") },
    { name: "Intervention Type", selector: (row) => row.intervention_type?.name, omit: !selectedColumns.includes("Intervention Type") },
    { name: "Form Name", selector: (row) => row.form.name, omit: !selectedColumns.includes("Form Name") },
    { name: "Before Date", selector: (row) => new Date(row.beforeDate).toLocaleDateString(), omit: !selectedColumns.includes("Before Date") },
    { name: "After Date", selector: (row) => new Date(row.afterDate).toLocaleDateString(), omit: !selectedColumns.includes("After Date") }
  ];

  const handleRowClick = (row) => {
    navigate(`/interventiondetail/${row.id}`);
  };

  const handleColumnChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions);
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <Header />
            <div className="Customcontainer bg-white">
              <div className="row bg-white" style={{ flex: 1, minHeight: "91.1vh" }}>
                <div className="col-2 p-5" style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}>
                  <p className="etech-sidebar-section" style={{ color: "#3a3838", fontWeight: "700", padding: ".25em 0" }}>
                    Liste des interventions
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <input
                      type="text"
                      className="form-control form-control-solid ps-12 mb-2 border"
                      placeholder="Recherche ..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-grid gap-2 col-6 mx-auto">
                    <Link to="/create-intervention" className="btn btn-primary">
                      Crée
                    </Link>
                  </div>
                  <ColumnSelectionModal
                    columns={columns}
                    selectedColumns={selectedColumns}
                    handleColumnChange={handleColumnChange}
                    pageKey="page_interventions" 
                  />

                </div>
                <div className="col-10">
                  <h1 className="d-flex fw-bold my-1 fs-3 p-4" style={{ fontSize: "1.5em", fontWeight: "400" }}>
                    Liste des interventions
                  </h1>
                  <div className="card-body pt-0">
                    <DataTable
                      noHeader={true}
                      columns={columns.filter((col) => !col.omit)}
                      data={interventions}
                      pagination
                      paginationServer
                      paginationTotalRows={totalPages * rowsPerPage}
                      onChangePage={(page) => setCurrentPage(page)}
                      progressPending={isLoadingInterventions}
                      progressComponent={<LoadingIndicator />}
                      noDataComponent="Aucune donnée disponible"
                      resizableColumns={true}
                      onRowClicked={handleRowClick}
                      highlightOnHover={true}
                    />
                    {error && <div className="text-danger">{error}</div>}
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interventions;
