import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Card,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { Link, useNavigate } from "react-router-dom";

import CategoryReferentielValReferentiel from "../Referentiel/CategoryReferentielValReferentiel";
import api from "../Auth/axiosSetup";
import LoadingIndicator from "../../components/LoadingIndicator";
function SearchIntervention() {
  const apiHost = process.env.REACT_APP_API_HOST;

  const [isLoading, setIsLoading] = useState(true);
  const [forms, setForms] = useState([]);
  const [users, setUsers] = useState([]);
  const [flags, setFlags] = useState([]);
  // const [valeurReferentiels, setValeurReferentiels] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedFlag, setSelectedFlag] = useState(null);
  // const [selectedValeurReferentiel, setSelectedValeurReferentiel] =
  //   useState(null);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [withAppointment, setWithAppointment] = useState("");
  const [status, setStatus] = useState("");
  const [selectedtypeInt, setSelectTypeInt] = useState("");
  
  const [unit, setUnit] = useState("");
  const [afterDate, setAfterDate] = useState("");
  const [beforeDate, setBeforeDate] = useState("");
  const [downloadedAt, setDownloadedAt] = useState("");
  const [soldAt, setSoldAt] = useState("");
  const [typedintv, setTypeDintv] = useState("");

  const [startedAt, setStartedAt] = useState("");
  //Refferentiel
  const [values, setValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  // const [selectedPhrase, setSelectedPhrase] = useState("");
  const [lastReferentiel, setLastReferentiel] = useState(null);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          referentielsResponse,
          formsResponse,
          usersResponse,
          flagsResponse,
          typeResponse,
        ] = await Promise.all([
          api.get(`/referentiels-intervention`),
          api.get(`/forms`),
          api.get(`/users`),
          api.get(`/flags`),
          api.get(`/intervention-types`),
        ]);
        setValues({
          0: referentielsResponse.data.referentiel_parent.map((item) => ({
            value: item.id,
            label: getDisplayValue(item),
          })),
        });
        
        setForms(
          formsResponse.data.data.map((form) => ({
            value: form.id,
            label: form.name,
          }))
        );
        
        setUsers(
          usersResponse.data.map((user) => ({
            value: user.id,
            label: user.name,
          }))
        );
        
        setFlags(
          flagsResponse.data.map((flag) => ({
            value: flag.id,
            label: flag.name,
          }))
        );
        setTypeDintv(
          typeResponse.data.map((type) => ({
            value: type.id,
            label: type.name,
            form: {
              id: type.form.id,
              name: type.form.name,
            },
            category: {
              id: type.category.id,
              name: type.category.name,
            },
          }))
        );

        const parents = referentielsResponse.data.referentiel_parent.map(
          (parent) => ({
            value: parent.id,
            label: parent.valeur,
          })
        );
        setOptions(parents);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Échec de la récupération des données");
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const queryParams = new URLSearchParams();
    setIsLoading(true);

    if (status) {
      queryParams.append("status", status);
    }
    if (soldAt) {
      queryParams.append("sold_at", soldAt);
    }
    if (startedAt) {
      queryParams.append("started_at", startedAt);
    }
    if (beforeDate) {
      queryParams.append("beforeDate", beforeDate);
    }
    if (downloadedAt) {
      queryParams.append("downloaded_at", downloadedAt);
    }
    if (unit) {
      queryParams.append("unit", unit);
    }
    if (afterDate) {
      queryParams.append("afterDate", afterDate);
    }
    if (withAppointment) {
      queryParams.append(
        "withAppointment",
        withAppointment.value === "true" ? "Yes" : "No"
      );
    }
    if (date) {
      queryParams.append("date", date);
    }
    if (time) {
      queryParams.append("time", time);
    }
    if (selectedtypeInt) {
      queryParams.append("typeInt", selectedtypeInt);
    }

    if (selectedUser?.value) {
      queryParams.append("user", selectedUser.value);
    }
    if (selectedForm?.value) {
      queryParams.append("form", selectedForm.value);
    }
    if (selectedFlag?.value) {
      queryParams.append("flag", selectedFlag.value);
    }
    if (lastReferentiel) {
      queryParams.append("valeur_referentiel", lastReferentiel);
    }
    

    const queryString = queryParams.toString();
    //console.log(queryString);
    setIsLoading(false);

    api.get(`/searchIntervention?${queryString}`)
      .then((response) => {
        const data = response.data; 
        navigate("/search-intervention/result", {
          state: { searchResults: data.data },
        });
      })
      .catch((error) => console.error("Error fetching search results:", error))
      .finally(() => {
        setIsLoading(false);
      });

  };



  const handleTypeInterventionChange = (selectedOption) => {
    if (selectedOption) {
      const selectedType = typedintv.find(
        (type) => type.id === selectedOption.value
      );
    }
      
  };

  const getDisplayValue = (value) => {
    return value.valeur || value.nom || "Unknown";
  };


  

  return (
    <div>
    <div className="d-flex flex-column flex-root">
      {/* {/*begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {/*begin::Header */}

          <Header />

          {/*end::Header */}
          <div
            className="Customcontainer bg-white" 
          >
            <div className="row bg-white" style={{ minHeight:"91.1vh" }}>
              
              
                {/* Contenu de la colonne de droite */}
                <div className="d-flex justify-content-between align-items-center p-5 " >
  <h1 className="fw-bold fs-3" style={{ fontSize: "1.5em", fontWeight: "400" }}>
    Recherche avancée
  </h1>
  <Button
    variant="primary"
    onClick={handleSearch}
    disabled={isLoading}
  >
    {isLoading ? (
      <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="visually-hidden">
          Chargement...
        </span>
      </>
    ) : (
      "Recherche"
    )}
  </Button>
</div>

                
                {/*begin::Container*/}
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              {/*begin::Post*/}
              <div className="content flex-row-fluid" id="kt_content">
                {/*begin::Card*/}
                <div className="card">
                  {/*begin::Card header*/}
                  <div className="card-body  pt-1">
                    {isLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (
                          <Container className="search-intervention p-3">
                          

                            <Row className="mb-3">
                              <Col>
                                <Card>
                                  <Card.Body
                                    style={{
                                      background:
                                        "linear-gradient(to right, #f0f0f0, white)",
                                    }}
                                  >
                                    <Row>
                                      <Col md={6} className="mb-3">
                                        <h5>Utilisateur:</h5>
                                        <Select
                                          options={users}
                                          isClearable
                                          onChange={(selectedOption) =>
                                            setSelectedUser(selectedOption)
                                          }
                                        />
                                      </Col>
                                      <Col md={6} className="mb-3">
                                        <h5>Formulaire:</h5>
                                        <Select
                                          options={forms}
                                          isClearable
                                          onChange={(selectedOption) =>
                                            setSelectedForm(selectedOption)
                                          }
                                        />
                                      </Col>
                                      <Col md={6} className="mb-3">
                                        <h5>Drapeaux:</h5>
                                        <Select
                                          options={flags}
                                          isClearable
                                          onChange={(selectedOption) =>
                                            setSelectedFlag(selectedOption)
                                          }
                                        />
                                      </Col>
                                      <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class="fs-5 fw-semibold mb-2">Type d'interv</label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div class="col-md-4 fv-row">
                            <Select
                              name="intervention_type_id"
                              options={typedintv}
                              // value={TypeInterventionsOptions.find(
                              //   (option) =>
                              //     option.value === formData.intervention_type_id
                              // )}
                              onChange={handleTypeInterventionChange}

                              className="basic-single"
                              isClearable
                              classNamePrefix="select"
                            />
                            {errorMessages.intervention_type_id && (
                              <div className="text-danger">
                                {errorMessages.intervention_type_id}
                              </div>
                            )}
                          </div>
                          
                        </div>
                                      <Col md={6} className="mb-3">
                                        <Form.Group controlId="withAppointment">
                                          <Form.Label>
                                            Avec Rendez-vous:
                                          </Form.Label>
                                          <Select
                                            onChange={(selectedOption) =>
                                              setWithAppointment(selectedOption)
                                            }
                                            isClearable={true}
                                            options={[
                                              { value: "true", label: "Yes" },
                                              { value: "false", label: "No" },
                                            ]}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card>
                              </Col>

                              <Col>
                                <Card>
                                  <Card.Body
                                    style={{
                                      background:
                                        "linear-gradient(to right, #f0f0f0, white)",
                                    }}
                                  >
                                    <h5>Informations Générales</h5>
                                    <Form>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Date:</Form.Label>
                                        <Form.Control
                                          type="date"
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            paddingTop: "0",
                                          }}
                                          value={date}
                                          onChange={(e) =>
                                            setDate(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Temps:</Form.Label>
                                        <Form.Control
                                          type="time"
                                          value={time}
                                          onChange={(e) =>
                                            setTime(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                      {/* <Form.Group>
                                      <Form.Label>Type:</Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={typeInt}
                                        onChange={(e) => setTypeInt(e.target.value)}
                                      />
                                    </Form.Group> */}
                                      {/* <Form.Group>
                                      <Form.Label>Unit:</Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={unit}
                                        onChange={(e) => setUnit(e.target.value)}
                                      />
                                    </Form.Group> */}
                                      <Form.Group>
                                        <Form.Label>Aprés le:</Form.Label>
                                        <Form.Control
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            paddingTop: "0",
                                          }}
                                          type="date"
                                          value={afterDate}
                                          onChange={(e) =>
                                            setAfterDate(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label>Avant le:</Form.Label>
                                        <Form.Control
                                          style={{
                                            width: "100%",
                                            height: "40px",
                                            paddingTop: "0",
                                          }}
                                          type="date"
                                          value={beforeDate}
                                          onChange={(e) =>
                                            setBeforeDate(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                      {/* <Form.Group>
                                      <Form.Label>Downloaded At:</Form.Label>
                                      <Form.Control
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                          paddingTop: "0",
                                        }}
                                        type="date"
                                        value={downloadedAt}
                                        onChange={(e) =>
                                          setDownloadedAt(e.target.value)
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group>
                                      <Form.Label>Sold At:</Form.Label>
                                      <Form.Control
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                          paddingTop: "0",
                                        }}
                                        type="date"
                                        value={soldAt}
                                        onChange={(e) => setSoldAt(e.target.value)}
                                      />
                                    </Form.Group>
                                    <Form.Group>
                                      <Form.Label>Started At:</Form.Label>
                                      <Form.Control
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                          paddingTop: "0",
                                        }}
                                        type="date"
                                        value={startedAt}
                                        onChange={(e) =>
                                          setStartedAt(e.target.value)
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Group>
                                      <Form.Label>Status:</Form.Label>
                                      <Form.Control
                                        type="text"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                      />
                                    </Form.Group> */}
                                    </Form>
                                  </Card.Body>
                                </Card>
                              </Col>

                              <Col>
                                <Card>
                                  <div className="card-body">
                                  {JSON.stringify(lastReferentiel)}
                                    {/* {error && <div className="alert alert-danger">{error}</div>} */}
                                    <h1>Réferentiel</h1>
                                    {errorMessages.valeur_referentiels_id && (
                                      <div className="text-danger">
                                        {errorMessages.valeur_referentiels_id}
                                      </div>
                                      
                                    )}
                                    <CategoryReferentielValReferentiel
                            valRefCallback={(val) => {
                              console.log("The last ref is ",val);
                              setSelectTypeInt(val)
                            }}
                            preselectedCategoryId={selectedtypeInt} // Pass the selected category id

                          />
                                  </div>
                                </Card>
                              </Col>
                            </Row>
                          </Container>
                        )}
                      </>
                    )}
                  </div>
                  {/*end::Card header*/}
                </div>
                {/*end::Card*/}
              </div>
              {/*end::Post*/}
            
            {/*end::Container*/}
              </div>
            </div>
          </div>

          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  </div>
  );
}

export default SearchIntervention;
