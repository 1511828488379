
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import api from "../Auth/axiosSetup";

function CategoryReferentielValReferentiel({ valRefCallback, preselectedCategoryId }) {
  const apiHost = process.env.REACT_APP_API_HOST;

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [referentiels, setReferentiels] = useState([]);
  const [niveaux, setNiveaux] = useState([]);
  const VALEUR_REFERENTIELS = useRef({});

  const [valeursReferentiels, setValeursReferentiels] = useState({});
  const [lastSelectedOption, setLastSelectedOption] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle change of selected category
  const handleChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  // Callback when category changes
useEffect(() => {
    const lastInsertedValue =
      lastSelectedOption && Object.keys(lastSelectedOption).length > 0
        ? Object.values(lastSelectedOption).pop()
        : null;

    const valRef =
      lastInsertedValue && lastInsertedValue.value !== undefined
        ? lastInsertedValue.value
        : null;

    //console.log("valRef:", valRef);

    valRefCallback(valRef);
  }, [selectedCategory,lastSelectedOption]);

  // Fetch categories and set preselected category if needed
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`/categories-referentiels`);
        const formattedData = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setCategories(formattedData);

        // Preselect category if preselectedCategoryId is provided
        if (preselectedCategoryId) {
          const preselectedCategory = formattedData.find(
            (category) => category.value === preselectedCategoryId
          );
          setSelectedCategory(preselectedCategory);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [apiHost, preselectedCategoryId]);

  // Fetch referentiels when category changes
  useEffect(() => {
    const fetchReferentiels = async () => {
      if (selectedCategory) {
        setLoading(true);
        try {
          const { data } = await api.get(
            `/referentiels?category_id=${selectedCategory.value}`
          );
          setReferentiels(data);

          const niveaux = data.map((ref) => ref.id);
          setNiveaux(niveaux);

          const valeursPromises = data.map((referentiel) =>
            api.get(
              `/valeur_referentiels?referentiel_id=${referentiel.id}`
            )
          );
          const valeursResponses = await Promise.all(valeursPromises);
          const valeursData = valeursResponses.reduce(
            (acc, response, index) => {
              acc[data[index].id] = response.data.map((item) => ({
                value: item.id,
                label: item.valeur,
              }));
              return acc;
            },
            {}
          );
          VALEUR_REFERENTIELS.current = valeursData;

          setValeursReferentiels(valeursData);
        } catch (error) {
          console.error("Error fetching referentiels:", error);
        } finally {
          setLoading(false);
        }
      } else {
        resetReferentiels();
      }
    };

    fetchReferentiels();
  }, [selectedCategory, apiHost]);

  // Reset referentiels data
  const resetReferentiels = () => {
    setReferentiels([]);
    setValeursReferentiels({});
    setNiveaux([]);
    setLastSelectedOption({});
  };

  // Handle filter change and update children and parents
  const handleFilterChange = async (referentielId, selectedOption) => {
    setLastSelectedOption((prev) => ({
      ...prev,
      [referentielId]: selectedOption,
    }));

    if (selectedOption) {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/filter-valeur-referentiels?id=${selectedOption.value}`
        );

        updateChildren(referentielId, data.enfants);
        updateParents(referentielId, data.parent);
      } catch (error) {
        console.error("Error fetching filtered values:", error);
      } finally {
        setLoading(false);
      }
    } else {
      resetFilteredValues(referentielId);
    }
  };

  // Update child referentiels based on selected option
  const updateChildren = (referentielId, enfants) => {
    const index = niveaux.findIndex((n) => n === referentielId);
    const childKey =
      index !== -1 && index + 1 < niveaux.length ? niveaux[index + 1] : null;

    if (childKey) {
      const filteredChildren = enfants.filter(
        (item) => item.referentiel_id === childKey
      );

      const filteredValeursReferentiels =
        VALEUR_REFERENTIELS.current[childKey]?.filter((item) =>
          filteredChildren.some((child) => child.id === item.value)
        ) || [];

      setValeursReferentiels((prevValeurs) => ({
        ...prevValeurs,
        [childKey]: filteredValeursReferentiels,
      }));
    }
  };

  // Update parent referentiels based on selected option
  const updateParents = (referentielId, parent) => {
    const parentKeys = niveaux
      .slice(0, niveaux.indexOf(referentielId))
      .reverse();

    parentKeys.forEach((parentKey) => {
      if (parent && parentKey === parent.referentiel_id) {
        const filteredValeursReferentielsParent =
          VALEUR_REFERENTIELS.current[parentKey]?.filter(
            (item) => parent.id === item.value
          ) || [];

        setLastSelectedOption((prevSelected) => ({
          ...prevSelected,
          [parentKey]: filteredValeursReferentielsParent,
        }));

        setValeursReferentiels((prevValeurs) => ({
          ...prevValeurs,
          [parentKey]: filteredValeursReferentielsParent,
        }));

        parent = parent.parent;
      }
    });
  };

  // Reset filtered values for referentiel
  const resetFilteredValues = (referentielId) => {
    niveaux.forEach((niv) => {
      if (niv >= referentielId && VALEUR_REFERENTIELS.current[niv]) {
        setValeursReferentiels((prevValeurs) => ({
          ...prevValeurs,
          [niv]: VALEUR_REFERENTIELS.current[niv],
        }));
      }
    });

    setLastSelectedOption((prevOptions) => {
      const updatedOptions = { ...prevOptions };
      niveaux.forEach((niv) => {
        if (niv >= referentielId) {
          delete updatedOptions[niv];
        }
      });
      return updatedOptions;
    });

    niveaux.forEach((niv) => {
      if (niv < referentielId && lastSelectedOption[niv]) {
        handleFilterChange(niv, lastSelectedOption[niv][0]);
      }
    });
  };

  return (
    <div>
      <div className="mb-3" style={{ display: "none" }}>
        <div className="form-text my-3">
          <label>Catégorie Référentiel </label>
        </div>
        <Select
          name="CategorieReferentiel"
          className="basic-single"
          isClearable
          classNamePrefix="select"
          value={selectedCategory}
          options={categories}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        {/* test = {preselectedCategoryId} */}
        {/* <div>Lastselected : {JSON.stringify(lastSelectedOption)}</div> */}

        {referentiels.length > 0 &&  preselectedCategoryId? (
          <ul>
            {referentiels.map((referentiel) => (
              <div key={referentiel.id} className="mb-3">
                <div className="form-text">
                  <label>{referentiel.nom}</label>
                </div>
                <Select
                  name={`referentiel-${referentiel.id}`}
                  className="basic-single"
                  isClearable
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    handleFilterChange(referentiel.id, selectedOption);
                  }}
                  value={lastSelectedOption[referentiel.id] || null}
                  options={valeursReferentiels[referentiel.id] || []}
                  isDisabled={loading}
                />
              </div>
            ))}
          </ul>
        ) : (
          <p>
            Aucun référentiel disponible.{" "}
            <span style={{ color: "red" }}>Veuillez choisir un type d'intervention.</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default CategoryReferentielValReferentiel;
