import React, { useState, useEffect } from "react";
import Header from "../../../Layout/menu/Header";
import Footer from "../../../Layout/menu/Footer";
import { Link } from "react-router-dom";

import { CirclePicker, CompactPicker } from "react-color";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaEdit,
} from "react-icons/fa";
import {
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import api from "../../Auth/axiosSetup";
function CreateInterventionType() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const navigate = useNavigate();
  const [errors, setErrors] = useState({}); // State to store validation errors
  const [selected, setSelected] = useState([]);
  const [optionsactivites, setOptionsActivities] = useState([]);
  const [interventionType, setInterventionType] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [Searchstring, setSearchstring] = useState("");
  const [filteredInterventionType, setFilteredInterventionType] = useState();
  const [categorie, setCategorie] = useState([]);
  const [formulaire, setFormulaire] = useState([]);
  const [errorCode, setErrorCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formInterventionType, setFormInterventionType] = useState({
    name: "",
    code: "",
    unit: "Heures/Minutes",
    duration: "",
    with_appointment: 0,
    self_planning: 0,
    form_id: null,
    category_id: null,
    travel_time: "Inclus dans la durée de intervention",
    description: "",
    visibility: "Tous les utilisateurs",
  });

  const isFormValid = () => {
    return (
      formInterventionType.name &&
      formInterventionType.code &&
      formInterventionType.duration &&
      formInterventionType.form_id &&
      formInterventionType.category_id
    );
  };

  useEffect(() => {
    setFilteredInterventionType(
      interventionType.filter((type) =>
        type.name.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, interventionType]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  useEffect(() => {
    const fetchInterventionTypes = api.get(`/intervention-types/`);
    const fetchCategories = api.get(`/categories-referentiels/`);
    const fetchForms = api.get(`/forms/`);

    Promise.all([fetchInterventionTypes, fetchCategories, fetchForms])
      .then((responses) => {
        const [interventionTypesResponse, categoriesResponse, formsResponse] =
          responses;
        setInterventionType(interventionTypesResponse.data);
        setCategorie(categoriesResponse.data);
        setFormulaire(formsResponse.data.data);
      })
      .catch((error) => {
        setError(error);
        console.error("There was an error fetching the data!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  

  const openModal = (row = null) => {
    if (row) {
      setSelectedRow(row);
      setFormInterventionType({
        name: row.name,
        code: row.code,
        unit: row.unit,
        duration: row.duration,
        with_appointment: row.with_appointment,
        self_planning: row.self_planning,
        form_id: row.form_id,
        category_id: row.category_id,
        travel_time: row.travel_time,
        description: row.description,
        visibility: row.visibility,
      });
      setIsEditMode(true);
    } else {
      setSelectedRow(null);
      setFormInterventionType({
        name: "",
        code: "",
        unit: "Heures/Minutes",
        duration: "",
        with_appointment: 0,
        self_planning: 0,
        form_id: null,
        category_id: null,
        travel_time: "Inclus dans la durée de intervention",
        description: "",
        visibility: "Tous les utilisateurs",
      });
      setIsEditMode(false);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
    setIsEditMode(false);
    setErrorCode(null);
  };

  const optionsRDV = [
    { value: "Heures/Minutes", label: "Heures/Minutes" },
    { value: "Jours", label: "Jours" },
  ];

  const travelTimeOptions = [
    {
      value: "Inclus dans la durée de intervention",
      label: "Inclus dans la durée de intervention",
    },
    { value: "Fixe", label: "Fixe" },
    { value: "Calculé", label: "Calculé" },
  ];

  const visibilityOptions = [
    { value: "Tous les utilisateurs", label: "Tous les utilisateurs" },
    { value: "Utilisateurs web", label: "Utilisateurs web" },
    { value: "Utilisateurs mobile", label: "Utilisateurs mobile" },
    {
      value: "Utilisateurs web service uniquement",
      label: "Utilisateurs web service uniquement",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const url = isEditMode
      ? `${apiHost}/intervention-types/${selectedRow.id}`
      : `${apiHost}/intervention-types`;
    const method = isEditMode ? "put" : "post";

    api({
      method: method,
      url: url,
      data: formInterventionType,
    })
      .then((response) => {
        setInterventionType((prevState) => {
          if (isEditMode) {
            return prevState.map((item) =>
              item.id === response.data.id ? response.data : item
            );
          } else {
            return [...prevState, response.data];
          }
        });
        closeModal();
      })
      .catch((error) => {
        console.error("Error:", error.response.data.errors.code);
        setErrorCode(error.response.data.errors.code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const [formData, setFormData] = useState({
    Nom: "",
    Couleur: "#FF5252",
    Durée_Unité: "Heures, minutes",
    debut: "Date et heure système",
    Fin: "Activité suivante",
    Temps_de_travail: "Non",
    Imputable_à_une_intervention: false,
    Début_d_intervention: false,
    Facturable: false,
    Temps_réel: false,
    Activité_initiale: false,
    Accès_compte_rendu_sur_le_mobile: "Non",
    Accès_solde: false,
    Fin_avec_solde: false,
    Utilisable: "Web et Mobile",
    Modifiable: "Non",
    Relevé_GPS: "Jamais",
    // Ajouter_une_activité_suivante: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(true);

  const onChange = (s) => {
    setSelected(s);
  };

  useEffect(() => {
    let ajouterActiviteSuivante = false;
    const selectedOptions = optionsactivites.filter((option) =>
      selected.includes(option.value)
    );
    let selectedOptionsString = null;

    if (selectedOptions.length > 0) {
      ajouterActiviteSuivante = true;
      selectedOptionsString = JSON.stringify(selectedOptions);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      // Ajouter_une_activité_suivante: ajouterActiviteSuivante,
      Activitessuivante: selectedOptionsString,
    }));
  }, [selected]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/activities/`);
        const activities = response.data.map((activity) => ({
          value: activity.id,
          label: activity.Nom,
        }));
        setOptionsActivities(activities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeBoolean = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value === "true", // Convert string to boolean
    }));
  };

  const handleColorChange = (color) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Couleur: color.hex,
    }));
  };
 

  const colors = [
    "#FF5252", // Red
    "#E040FB", // Purple
    "#448AFF", // Blue
    "#FFFF00", // Yellow
    "#00FF00", // Green
    "#000000", // Black
  ];
  // State to manage collapse
  const [isOpen, setIsOpen] = useState(false);

  // Toggle collapse state
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="d-flex flex-column flex-root">
      {/*begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {/*begin::Header */}
          <Header />
          {/*end::Header */}
          <div className="Customcontainer bg-white">
            <div
              className="row bg-white"
              style={{ flex: 1, minHeight: "91.1vh" }}
            >
              <div
                className="col-2 p-5 "
                style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
              >
                {/* Contenu de la colonne de gauche */}
                <p
                  className="etech-sidebar-section"
                  style={{
                    color: "#3a3838",
                    fontWeight: "700",
                    padding: ".25em 0",
                  }}
                >
                  Rechercher un type d'intervention
                </p>
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-subscription-table-filter="search"
                    className="form-control form-control-solid  ps-12 mb-2 border"
                    placeholder="Recherche ..."
                    onChange={"handleInputChange"}
                    disabled
                  />
                </div>
                <div class="d-grid gap-2 col-6 mx-auto">
                  <Link to="/creatactivity" className="btn btn-primary">
                    Rechercher
                  </Link>
                </div>{" "}
              </div>
              <div className="col-10">
                {/* Contenu de la colonne de droite */}
                <h1
                  className="d-flex  fw-bold my-1 fs-3 p-4"
                  style={{ fontSize: "1.5em", fontWeight: "400" }}
                >
                  Nouveau type d'intervention
                </h1>
                <div className="card-body pt-0">
                  {/*begin::Post */}
                  <div className="content flex-row-fluid" id="kt_content">
                    {/*begin::Careers - Apply */}
                    <div className="card">
                      {/*begin::Body */}
                      <div className="card-body p-3">
                        {/*begin::Hero */}

                        <div className="d-flex flex-column flex-lg-row mb-17">
                          {/*begin::Content */}
                          <div className="flex-lg-row-fluid me-0 ">
                            {/*begin::Form */}

<Form onSubmit={handleSubmit}>
  {/* Input group - Nom */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Nom
      </label>
    </div>
    <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
      <input
        type="text"
        value={formInterventionType.name}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            name: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
      />
    </div>
  </div>
  {/* Input group - Code */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Code
      </label>
    </div>
    <div className="col-md-2" style={{ borderLeft: "1px solid #ccc" }}>
      <input
        type="text"
        disabled={isEditMode}
        value={formInterventionType.code}
        onChange={(e) => {
          setFormInterventionType({
            ...formInterventionType,
            code: e.target.value,
          });
          setErrorCode(null);
        }}
        className={`form-control mb-2 ${errorCode ? "is-invalid" : ""}`}
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
      />
      {errorCode && (
        <span
          className="error"
          style={{ color: "red", fontWeight: "bold" }}
        >
          {errorCode}
        </span>
      )}
    </div>
  </div>
 {/* Input group - Unité */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      className="required fs-5 fw-semibold mb-2"
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
    >
      Unité
    </label>
  </div>
  <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
    <Select
      isClearable
      options={optionsRDV}
      value={optionsRDV.find(
        (option) => option.value === formInterventionType.unit
      )}
      onChange={(selectedOption) =>
        setFormInterventionType({
          ...formInterventionType,
          unit: selectedOption ? selectedOption.value : "Heures/Minutes",
        })
      }
      className="mb-2"
      styles={{ control: (base) => ({ ...base, marginLeft: "5px" }) }}
    />
  </div>
</div>

{/* Input group - Durée */}
{/* Input group - Durée */}
<div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      className="required fs-5 fw-semibold mb-2"
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
    >
      Durée
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    {formInterventionType.unit === "Heures/Minutes" ? (
      <div className="d-flex align-items-center">
        <input
          type="number"
          value={formInterventionType.hours || ""}
          onChange={(e) => {
            const newHours = e.target.value;
            setFormInterventionType((prev) => {
              const newDuration = `${newHours}:${prev.minutes || "00"}`; // Combine with minutes
              return {
                ...prev,
                hours: newHours,
                duration: newDuration, // Save in formData.duration
              };
            });
          }}
          className="form-control me-2"
          placeholder="Heures"
          style={{
            width: "100px",
            height: "27px",
            marginLeft: "5px",
          }}
          min="0"
          max="23"
        />
        <span style={{ fontWeight: "bold", margin: "0 5px" }}>:</span>
        <input
          type="number"
          value={formInterventionType.minutes || ""}
          onChange={(e) => {
            const newMinutes = e.target.value;
            setFormInterventionType((prev) => {
              const newDuration = `${prev.hours || "00"}:${newMinutes}`; // Combine with hours
              return {
                ...prev,
                minutes: newMinutes,
                duration: newDuration, // Save in formData.duration
              };
            });
          }}
          className="form-control ms-2"
          placeholder="Minutes"
          style={{
            width: "100px",
            height: "27px",
          }}
          min="0"
          max="59"
        />
      </div>
    ) : formInterventionType.unit === "Jours" ? (
      <input
        type="number"
        value={formInterventionType.duration || ""}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            duration: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
        placeholder="Jours"
      />
    ) : null}
  </div>
</div>


  {/* Input group - Avec RDV */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Avec RDV
      </label>
    </div>
    <div className="col-md-9 d-flex align-items-center" style={{ borderLeft: "1px solid #ccc" }}>
      <Form.Check
        type="radio"
        label="Oui"
        name="withAppointment"
        value={1}
        checked={formInterventionType.with_appointment === 1}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            with_appointment: parseInt(e.target.value),
          })
        }
        className="me-3"
        style={{ marginLeft: "5px" }}
      />
      <Form.Check
        type="radio"
        label="Non"
        name="withAppointment"
        value={0}
        checked={formInterventionType.with_appointment === 0}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            with_appointment: parseInt(e.target.value),
          })
        }
      />
    </div>
  </div>
  {/* Input group - Planification autonome */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Planification autonome
      </label>
    </div>
    <div className="col-md-9 d-flex align-items-center" style={{ borderLeft: "1px solid #ccc" }}>
      <Form.Check
        type="radio"
        label="Oui"
        name="selfPlanning"
        value={1}
        checked={formInterventionType.self_planning === 1}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            self_planning: parseInt(e.target.value),
          })
        }
        className="me-3"
        style={{ marginLeft: "5px" }}
      />
      <Form.Check
        type="radio"
        label="Non"
        name="selfPlanning"
        value={0}
        checked={formInterventionType.self_planning === 0}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            self_planning: parseInt(e.target.value),
          })
        }
      />
    </div>
  </div>
  {/* Input group - Formulaire */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Formulaire
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <Select
        options={formulaire.map((form) => ({
          value: form.id,
          label: form.name,
        }))}
        isClearable
        isDisabled={isEditMode}
        value={
          formulaire.find((form) => form.id === formInterventionType.form_id)
            ? {
                value: formInterventionType.form_id,
                label: formulaire.find(
                  (form) => form.id === formInterventionType.form_id
                ).name,
              }
            : null
        }
        onChange={(selectedOption) =>
          setFormInterventionType({
            ...formInterventionType,
            form_id: selectedOption ? selectedOption.value : null,
          })
        }
        className="mb-2"
        styles={{ control: (base) => ({ ...base, marginLeft: "5px" }) }}
      />
    </div>
  </div>
  {/* Input group - Formulaire */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Categorie Referentiel
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
    <Select
                options={categorie.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                isClearable
                value={
                  categorie.find(
                    (cat) => cat.id === formInterventionType.category_id
                  )
                    ? {
                        value: formInterventionType.category_id,
                        label: categorie.find(
                          (cat) => cat.id === formInterventionType.category_id
                        ).name,
                      }
                    : null
                }
                isDisabled={isEditMode}
                onChange={(selectedOption) =>
                  setFormInterventionType({
                    ...formInterventionType,
                    category_id: selectedOption ? selectedOption.value : null,
                  })
                }
              />
    </div>
  </div>
  {/* Input group - Nombre de jours avant */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Nombre de jours avant
      </label>
    </div>
    <div className="col-md-2" style={{ borderLeft: "1px solid #ccc" }}>
      <input
        type="number"
        value={formInterventionType.nb_days_before}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            nb_days_before: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
      />
    </div>
  </div>
  {/* Submit button */}
  <Button type="submit" className="my-4" disabled={loading}>
    {loading ? <Spinner animation="border" size="sm" /> : isEditMode ? "Modifier" : "Ajouter"}
  </Button>
</Form>

{/* Additional Input groups will follow the same pattern */}

                            {/*end::Form */}
                            {/*begin::Job */}
                            <div className="mb-10 mb-lg-0"></div>
                            {/*end::Job */}
                          </div>
                          {/*end::Content */}
                        </div>
                        {/*end::Layout */}
                      </div>
                    </div>
                    {/*end::Careers - Apply */}
                  </div>
                  {/*end::Post */}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default CreateInterventionType;
