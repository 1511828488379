import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Modal, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { TbCirclesRelation } from "react-icons/tb";
import Select from "react-select";
import { FaEdit } from "react-icons/fa";

import CreateValRef from "./components/CreateValRef";
import EditValRef from "./components/EditValRef";
import api from "../Auth/axiosSetup";
import LoadingIndicator from "../../components/LoadingIndicator";

function ValeurReferentielList() {
  const { id } = useParams(); // Retrieve id parameter from URL
  const [error, setError] = useState(null);

  const apiHost = process.env.REACT_APP_API_HOST;
  const [data, setData] = useState([]);
  const [valeurs, setValeurs] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);

  const [loading, setLoading] = useState(true);

  const [Searchstring, setSearchstring] = useState("");
  const [filteredValeurReferentiels, setFilteredValeurReferentiels] =
    useState();

  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormName, setEditFormName] = useState("");
  const [editValeurId, setEditValeurId] = useState(null);
  const [editSelectedParent, setEditSelectedParent] = useState(null);
  const [editFormContact, setEditFormContact] = useState("");
  const [editFormAdresse, setEditFormAdresse] = useState("");
  const [editFormCodePostal, setEditFormCodePostal] = useState("");
  const [editFormVille, setEditFormVille] = useState("");
  const [editFormPays, setEditFormPays] = useState("");
  const [editFormCoordonnees, setEditFormCoordonnees] = useState("");
  const [editFormDescription, setEditFormDescription] = useState("");
  const [editFormInfosSite, setEditFormInfosSite] = useState("");
  const [editFormLatitudeLongitude, setEditFormLatitudeLongitude] =
    useState("");
  const [editFormSite, setEditFormSite] = useState("");
  const [editFormStatut, setEditFormStatut] = useState("");
  const [formContact, setFormContact] = useState("");
  const [formAdresse, setFormAdresse] = useState("");
  const [formCodePostal, setFormCodePostal] = useState("");
  const [formVille, setFormVille] = useState("");
  const [formPays, setFormPays] = useState("");
  const [formCoordonnees, setFormCoordonnees] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [formInfosSite, setFormInfosSite] = useState("");
  const [formLatitudeLongitude, setFormLatitudeLongitude] = useState("");
  const [formSite, setFormSite] = useState("");
  const [formStatut, setFormStatut] = useState("");

  const handleFormContactChange = (e) => setFormContact(e.target.value);
  const handleFormAdresseChange = (e) => setFormAdresse(e.target.value);
  const handleFormCodePostalChange = (e) => setFormCodePostal(e.target.value);
  const handleFormVilleChange = (e) => setFormVille(e.target.value);
  const handleFormPaysChange = (e) => setFormPays(e.target.value);
  const handleFormCoordonneesChange = (e) => setFormCoordonnees(e.target.value);
  const handleFormDescriptionChange = (e) => setFormDescription(e.target.value);
  const handleFormInfosSiteChange = (e) => setFormInfosSite(e.target.value);
  const handleFormLatitudeLongitudeChange = (e) =>
    setFormLatitudeLongitude(e.target.value);
  const handleFormSiteChange = (e) => setFormSite(e.target.value);
  const handleFormStatutChange = (e) => setFormStatut(e.target.value);

  const handleShowEditModal = (row) => {
    // Set all relevant form fields from the `row` object
    setEditFormName(row.valeur);
    setEditValeurId(row.id);
    setEditSelectedParent(
      row.parent_id ? { value: row.parent_id, label: row.parent_nom } : null
    );
    setEditFormContact(row.contact || "");
    setEditFormAdresse(row.adresse || "");
    setEditFormCodePostal(row.code_postal || "");
    setEditFormVille(row.ville || "");
    setEditFormPays(row.pays || "");
    setEditFormCoordonnees(row.coordonnees || "");
    setEditFormDescription(row.description || "");
    setEditFormInfosSite(row.infos_site || "");
    setEditFormLatitudeLongitude(row.latitude_longitude || "");
    setEditFormSite(row.site || "");
    setEditFormStatut(row.statut || "");
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setEditFormName("");
    setEditValeurId(null);
  };

  useEffect(() => {
    setFilteredValeurReferentiels(
      valeurs.filter((valRef) =>
        valRef.valeur.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, valeurs]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  useEffect(() => {
    api.get(`${apiHost}/referentiels/${id}`)
      .then((response) => response.data)
      .then((data) => {
        setValeurs(data.valeurs);
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [loading]);
  const columns = [
    {
      name: "Nom",
      selector: (row) => row.valeur,
    },
    {
      name: "Parent",
      selector: (row) =>
        row.parent ? (
          <>
            <TbCirclesRelation /> {row.parent.valeur} (
            {row.parent.referentiel.nom})
          </>
        ) : (
          "Sans"
        ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => handleShowEditModal(row)}
          style={{
            color: "#007bff",
          }}
        >
          <FaEdit
            style={{
              fontSize: "24px",
            }}
          />
        </Button>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [formName, setFormName] = useState("");
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setFormName("");
    setFormContact("");
    setFormAdresse("");
    setFormCodePostal("");
    setFormVille("");
    setFormPays("");
    setFormCoordonnees("");
    setFormDescription("");
    setFormInfosSite("");
    setFormLatitudeLongitude("");
    setFormSite("");
    setFormStatut("");
    setSelectedParent(null);
  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/valeur_referentiels`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          referentiel_id: id, // Assurez-vous que 'id' est défini dans votre composant ou passé en tant que prop
          valeur: formName,
          valeur_referentiel_parent_id: selectedParent?.value,
          contact: formContact,
          adresse: formAdresse,
          code_postal: formCodePostal,
          ville: formVille,
          pays: formPays,
          coordonnees: formCoordonnees,
          description: formDescription,
          infos_site: formInfosSite,
          latitude_longitude: formLatitudeLongitude,
          site: formSite,
          statut: formStatut,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error adding Referentiel: ${response.statusText}`);
      }

      handleCloseModal();
    } catch (error) {
      console.error("Error adding Referentiel:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleParentChange = (selectedOption) => {
    setSelectedParent(selectedOption);
  };

  const handleEditFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.put(
        `/valeur_referentiels/${editValeurId}`,
        {
          test: "Hello",
          valeur: editFormName,
          contact: editFormContact,
          adresse: editFormAdresse,
          code_postal: editFormCodePostal,
          ville: editFormVille,
          pays: editFormPays,
          coordonnees: editFormCoordonnees,
          description: editFormDescription,
          infos_site: editFormInfosSite,
          latitude_longitude: editFormLatitudeLongitude,
          site: editFormSite,
          statut: editFormStatut,
          parent_id: editSelectedParent ? editSelectedParent.value : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(`Error updating Referentiel: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error updating Referentiel:", error);
    } finally {
      handleCloseEditModal();
      setLoading(false);
    }
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Liste des {data.nom}
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche..."
                      onChange={handleInputChange}
                    />
                  </div>
                  {!showModal && !showEditModal && (

                  <div class="d-grid gap-2 col-6 mx-auto">
                    <button
                      onClick={handleShowModal}
                      className="btn btn-primary"
                    >

                      Nouveau
                    </button>
                  </div>
                  ) }
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Liste des {data.nom}
                  </h1>
                  <div className="card-body pt-0">
  {loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingIndicator />
    </div>
  ) : error ? (
    <div>Data not Found</div>
  ) : (
    <>
      {!showModal && !showEditModal && (
        <DataTable
          columns={columns}
          data={filteredValeurReferentiels}
          pagination
          paginationPerPage={10} // Set the number of rows per page
          customStyles={customStyles}
        />
      )}
      {showModal && (
        <CreateValRef
          formName={formName}
          handleFormNameChange={handleFormNameChange}
          data={data}
          selectedParent={selectedParent}
          handleParentChange={handleParentChange}
          formContact={formContact}
          handleFormContactChange={handleFormContactChange}
          formAdresse={formAdresse}
          handleFormAdresseChange={handleFormAdresseChange}
          formCodePostal={formCodePostal}
          handleFormCodePostalChange={handleFormCodePostalChange}
          formVille={formVille}
          handleFormVilleChange={handleFormVilleChange}
          formPays={formPays}
          handleFormPaysChange={handleFormPaysChange}
          formCoordonnees={formCoordonnees}
          handleFormCoordonneesChange={handleFormCoordonneesChange}
          formDescription={formDescription}
          handleFormDescriptionChange={handleFormDescriptionChange}
          formInfosSite={formInfosSite}
          handleFormInfosSiteChange={handleFormInfosSiteChange}
          formLatitudeLongitude={formLatitudeLongitude}
          handleFormLatitudeLongitudeChange={handleFormLatitudeLongitudeChange}
          formSite={formSite}
          handleFormSiteChange={handleFormSiteChange}
          formStatut={formStatut}
          handleFormStatutChange={handleFormStatutChange}
          handleFormSubmit={handleFormSubmit}
          loading={loading}
          handleCloseModal={handleCloseModal}
        />
      )}
      {showEditModal && (
        <EditValRef
          editFormName={editFormName}
          setEditFormName={setEditFormName}
          data={data}
          editSelectedParent={editSelectedParent}
          setEditSelectedParent={setEditSelectedParent}
          editFormContact={editFormContact}
          setEditFormContact={setEditFormContact}
          editFormAdresse={editFormAdresse}
          setEditFormAdresse={setEditFormAdresse}
          editFormCodePostal={editFormCodePostal}
          setEditFormCodePostal={setEditFormCodePostal}
          editFormVille={editFormVille}
          setEditFormVille={setEditFormVille}
          editFormPays={editFormPays}
          setEditFormPays={setEditFormPays}
          editFormCoordonnees={editFormCoordonnees}
          setEditFormCoordonnees={setEditFormCoordonnees}
          editFormDescription={editFormDescription}
          setEditFormDescription={setEditFormDescription}
          editFormInfosSite={editFormInfosSite}
          setEditFormInfosSite={setEditFormInfosSite}
          editFormLatitudeLongitude={editFormLatitudeLongitude}
          setEditFormLatitudeLongitude={setEditFormLatitudeLongitude}
          editFormSite={editFormSite}
          setEditFormSite={setEditFormSite}
          editFormStatut={editFormStatut}
          setEditFormStatut={setEditFormStatut}
          handleEditFormSubmit={handleEditFormSubmit}
          loading={loading}
          handleCloseEditModal={handleCloseEditModal}
        />
      )}
    </>
  )}
</div>

                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
      {/* Modal for adding new form */}

      <Button variant="secondary" onClick={handleCloseEditModal}>
        Annuler
      </Button>
    </div>
  );
}

export default ValeurReferentielList;
