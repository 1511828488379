import React, { useEffect, useState } from "react";

import { Button, Form, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Layout/menu/Header";
import api from "../../Auth/axiosSetup";
import LoadingIndicator from "../../../components/LoadingIndicator";

function EditInterventionType() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const { id } = useParams(); // Get ID from URL params
  const navigate = useNavigate();

  const [formInterventionType, setFormInterventionType] = useState({
    name: "",
    code: "",
    unit: "Heures/Minutes",
    duration: "",
    with_appointment: 0,
    self_planning: 0,
    form_id: null,
    category_id: null,
    travel_time: "Inclus dans la durée de intervention",
    description: "",
    visibility: "Tous les utilisateurs",
  });
  const [formulaire, setFormulaire] = useState([]);
  const [categorie, setCategorie] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(null);
  const visibilityOptions = [
    { value: "Tous les utilisateurs", label: "Tous les utilisateurs" },
    { value: "Utilisateurs web", label: "Utilisateurs web" },
    { value: "Utilisateurs mobile", label: "Utilisateurs mobile" },
    {
      value: "Utilisateurs web service uniquement",
      label: "Utilisateurs web service uniquement",
    },
  ];
  const travelTimeOptions = [
    {
      value: "Inclus dans la durée de intervention",
      label: "Inclus dans la durée de intervention",
    },
    { value: "Fixe", label: "Fixe" },
    { value: "Calculé", label: "Calculé" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [formsResponse, categoriesResponse] = await Promise.all([
          api.get(`/forms/`),
          api.get(`/categories-referentiels/`),
        ]);

        setFormulaire(formsResponse.data.data);
        setCategorie(categoriesResponse.data);

        if (id) {
          const response = await api.get(`/intervention-types/${id}`);
          setFormInterventionType(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, apiHost]);

  const isFormValid = () => {
    return (
      formInterventionType.name &&
      formInterventionType.code &&
      formInterventionType.duration &&
      formInterventionType.form_id &&
      formInterventionType.category_id
    );
  };

  const handleInputChange = (e) => {
    setFormInterventionType({
      ...formInterventionType,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (id) {
        await api.put(`/intervention-types/${id}`, formInterventionType);
      } else {
        await api.post(`/intervention-types`, formInterventionType);
      }
      navigate("/type-intervention");
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorCode(error.response.data.errors.code);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingIndicator />;

  return (
    <div className="d-flex flex-column flex-root">
    {/*begin::Page */}
    <div className="page d-flex flex-row flex-column-fluid">
      {/*begin::Wrapper */}
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        {/*begin::Header */}
        <Header />
        {/*end::Header */}
        <div className="Customcontainer bg-white">
          <div
            className="row bg-white"
            style={{ flex: 1, minHeight: "91.1vh" }}
          >
            <div
              className="col-2 p-5 "
              style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
            >
              {/* Contenu de la colonne de gauche */}
              <p
                className="etech-sidebar-section"
                style={{
                  color: "#3a3838",
                  fontWeight: "700",
                  padding: ".25em 0",
                }}
              >
                Modifier type d'intervention
              </p>
              <div className="d-flex align-items-center position-relative my-1">
                <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <input
                  type="text"
                  data-kt-subscription-table-filter="search"
                  className="form-control form-control-solid  ps-12 mb-2 border"
                  placeholder="Recherche ..."
                  onChange={"handleInputChange"}
                  disabled
                />
              </div>
              <div class="d-grid gap-2 col-6 mx-auto">
                <Link to="/creatactivity" className="btn btn-primary">
                  Rechercher
                </Link>
              </div>{" "}
            </div>
            <div className="col-10">
              {/* Contenu de la colonne de droite */}
              <h1
                className="d-flex  fw-bold my-1 fs-3 p-4"
                style={{ fontSize: "1.5em", fontWeight: "400" }}
              >
                Modifier type d'intervention
              </h1>
              <div className="card-body pt-0">
    <div>
    <form onSubmit={handleSubmit}>
  {/* Input group - Nom */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Nom <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <input
        type="text"
        name="name"
        value={formInterventionType.name}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            name: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
      />
    </div>
  </div>

  {/* Input group - Code */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Code <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <input
        type="text"
        name="code"
        value={formInterventionType.code}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            code: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
      />
      {errorCode && (
        <span
          className="error"
          style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}
        >
          {errorCode}
        </span>
      )}
    </div>
  </div>

  {/* Input group - Unité */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Unité <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <Select
        isClearable
        options={[
          { value: "Heures/Minutes", label: "Heures/Minutes" },
          { value: "Jours", label: "Jours" },
        ]}
        value={
          formInterventionType.unit
            ? { value: formInterventionType.unit, label: formInterventionType.unit }
            : null
        }
        onChange={(selectedOption) =>
          setFormInterventionType({
            ...formInterventionType,
            unit: selectedOption ? selectedOption.value : "Heures/Minutes",
          })
        }
        styles={{ container: (provided) => ({ ...provided, marginLeft: '5px' }) }}
      />
    </div>
  </div>

  {/* Input group - Durée */}
  <div className="row mb-3">
  <div className="col-md-3 d-flex justify-content-end">
    <label
      className="required fs-5 fw-semibold mb-2"
      style={{
        color: "#0099e5",
        whiteSpace: "nowrap",
        paddingRight: "10px",
        paddingTop: "5px",
        fontSize: "14px",
      }}
    >
      Durée <span style={{ color: "red" }}>*</span>
    </label>
  </div>
  <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
    {/* Split the duration into hours and minutes */}
    {formInterventionType.unit === "Heures/Minutes" ? (
      <div className="d-flex align-items-center">
        <input
          type="number"
          value={formInterventionType.duration ? formInterventionType.duration.split(':')[0] : ""}
          onChange={(e) => {
            const newHours = e.target.value;
            setFormInterventionType((prev) => {
              const newDuration = `${newHours}:${prev.duration ? prev.duration.split(':')[1] : "00"}`; // Combine with minutes
              return {
                ...prev,
                hours: newHours,
                duration: newDuration, // Save in formInterventionType.duration
              };
            });
          }}
          className="form-control me-2"
          placeholder="Heures"
          style={{
            width: "100px",
            height: "27px",
            marginLeft: "5px",
          }}
          min="0"
          max="23"
        />
        <span style={{ fontWeight: "bold", margin: "0 5px" }}>:</span>
        <input
          type="number"
          value={formInterventionType.duration ? formInterventionType.duration.split(':')[1] : ""}
          onChange={(e) => {
            const newMinutes = e.target.value;
            setFormInterventionType((prev) => {
              const newDuration = `${prev.duration ? prev.duration.split(':')[0] : "00"}:${newMinutes}`; // Combine with hours
              return {
                ...prev,
                minutes: newMinutes,
                duration: newDuration, // Save in formInterventionType.duration
              };
            });
          }}
          className="form-control ms-2"
          placeholder="Minutes"
          style={{
            width: "100px",
            height: "27px",
          }}
          min="0"
          max="59"
        />
      </div>
    ) : (
      <input
        type="text"
        name="duration"
        value={formInterventionType.duration}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            duration: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          height: "27px",
          marginLeft: "5px",
        }}
      />
    )}
  </div>
</div>

  {/* Input group - Avec RDV */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Avec RDV
      </label>
    </div>
    <div className="col-md-3 d-flex align-items-center" style={{ borderLeft: "1px solid #ccc" }}>
      <div className="form-check me-2">
        <input
          type="radio"
          id="withAppointmentYes"
          name="with_appointment"
          value={1}
          checked={formInterventionType.with_appointment === 1}
          onChange={handleInputChange}
          className="form-check-input"
        />
        <label htmlFor="withAppointmentYes" className="form-check-label">Oui</label>
      </div>
      <div className="form-check">
        <input
          type="radio"
          id="withAppointmentNo"
          name="with_appointment"
          value={0}
          checked={formInterventionType.with_appointment === 0}
          onChange={handleInputChange}
          className="form-check-input"
        />
        <label htmlFor="withAppointmentNo" className="form-check-label">Non</label>
      </div>
    </div>
  </div>

  {/* Input group - Planification autonome */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Planification autonome
      </label>
    </div>
    <div className="col-md-3 d-flex align-items-center" style={{ borderLeft: "1px solid #ccc" }}>
      <div className="form-check me-2">
        <input
          type="radio"
          id="selfPlanningYes"
          name="self_planning"
          value={1}
          checked={formInterventionType.self_planning === 1}
          onChange={handleInputChange}
          className="form-check-input"
        />
        <label htmlFor="selfPlanningYes" className="form-check-label">Oui</label>
      </div>
      <div className="form-check">
        <input
          type="radio"
          id="selfPlanningNo"
          name="self_planning"
          value={0}
          checked={formInterventionType.self_planning === 0}
          onChange={handleInputChange}
          className="form-check-input"
        />
        <label htmlFor="selfPlanningNo" className="form-check-label">Non</label>
      </div>
    </div>
  </div>

  {/* Input group - Formulaire */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Formulaire <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <Select
        options={formulaire.map((form) => ({
          value: form.id,
          label: form.name,
        }))}
        isClearable
        isDisabled={id}
        value={
          formulaire.find((form) => form.id === formInterventionType.form_id)
            ? {
                value: formInterventionType.form_id,
                label: formulaire.find((form) => form.id === formInterventionType.form_id).name,
              }
            : null
        }
        onChange={(selectedOption) =>
          setFormInterventionType({
            ...formInterventionType,
            form_id: selectedOption ? selectedOption.value : null,
          })
        }
        styles={{ container: (provided) => ({ ...provided, marginLeft: '5px' }) }}
      />
    </div>
  </div>

  {/* Input group - Catégorie */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Catégorie <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <Select
        options={categorie.map((category) => ({
          value: category.id,
          label: category.name,
        }))}
        isClearable
        value={
          categorie.find((cat) => cat.id === formInterventionType.category_id)
            ? {
                value: formInterventionType.category_id,
                label: categorie.find((cat) => cat.id === formInterventionType.category_id).name,
              }
            : null
        }
        isDisabled={id}
        onChange={(selectedOption) =>
          setFormInterventionType({
            ...formInterventionType,
            category_id: selectedOption ? selectedOption.value : null,
          })
        }
        styles={{ container: (provided) => ({ ...provided, marginLeft: '5px' }) }}
      />
    </div>
  </div>

  {/* Input group - Temps de trajet */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Temps de trajet <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <Select
        options={travelTimeOptions}
        value={travelTimeOptions.find(
          (option) => option.value === formInterventionType.travel_time
        )}
        onChange={(selectedOption) =>
          setFormInterventionType({
            ...formInterventionType,
            travel_time: selectedOption ? selectedOption.value : "Inclus dans la durée de intervention",
          })
        }
        styles={{ container: (provided) => ({ ...provided, marginLeft: '5px' }) }}
      />
    </div>
  </div>

  {/* Input group - Description */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Description
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <textarea
        name="description"
        rows={3}
        value={formInterventionType.description}
        onChange={(e) =>
          setFormInterventionType({
            ...formInterventionType,
            description: e.target.value,
          })
        }
        className="form-control mb-2"
        style={{
          width: "100%",
          maxWidth: "160px",
          marginLeft: "5px",
        }}
      />
    </div>
  </div>

  {/* Input group - Visibilité */}
  <div className="row mb-3">
    <div className="col-md-3 d-flex justify-content-end">
      <label
        className="required fs-5 fw-semibold mb-2"
        style={{
          color: "#0099e5",
          whiteSpace: "nowrap",
          paddingRight: "10px",
          paddingTop: "5px",
          fontSize: "14px",
        }}
      >
        Visibilité <span style={{ color: "red" }}>*</span>
      </label>
    </div>
    <div className="col-md-3" style={{ borderLeft: "1px solid #ccc" }}>
      <Select
        options={visibilityOptions}
        value={visibilityOptions.find(
          (option) => option.value === formInterventionType.visibility
        )}
        onChange={(selectedOption) =>
          setFormInterventionType({
            ...formInterventionType,
            visibility: selectedOption ? selectedOption.value : "Tous les utilisateurs",
          })
        }
        styles={{ container: (provided) => ({ ...provided, marginLeft: '5px' }) }}
      />
    </div>
  </div>

  {/* Submit Button */}
  <div className="row mb-3">
    <div className="col-md-12 d-flex justify-content-start">
      <button
        type="submit"
        className="btn btn-primary"
        disabled={!isFormValid()}
        style={{ marginTop: '10px' }}
      >
        {id ? "Update" : "Create"}
      </button>
    </div>
  </div>
</form>

    </div>
    </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default EditInterventionType;
