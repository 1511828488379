import React, { useState } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import { GoogleMap, Polyline } from "@react-google-maps/api";

const Cartographie = ({ zoomLevel = 6 }) => {
  // Static array for points
  const staticPoints = [
    // { lat: 40.712776, lng: -74.005974 },
    // { lat: 34.052235, lng: -118.243683 },
    // { lat: 41.878113, lng: -87.629799 },
    // Add more points as needed
  ];

  // Set formatted points
  const [formattedPoints] = useState(staticPoints);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  // Log the formatted points to debug
  console.log("Formatted Points =>", formattedPoints);

  // Determine the center of the map
  const defaultCenter = formattedPoints.length > 0
    ? {
        lat: formattedPoints[0].lat,
        lng: formattedPoints[0].lng,
      }
    : {
        lat: 31.792306, // Default center
        lng: -7.080168,
      };

  // Create a unique key for the GoogleMap component
  const mapKey = JSON.stringify(formattedPoints);

  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <div className="page d-flex flex-row flex-column-fluid" style={{ flex: 1 }}>
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
          style={{ flex: 1 }}
        >
          <Header />
          <div className="Customcontainer bg-white" style={{ flex: 1 }}>
            <div style={{ height: "100%" }}>
              <GoogleMap
                key={mapKey}
                mapContainerStyle={containerStyle}
                center={defaultCenter}
                zoom={zoomLevel}
                options={{ minZoom: 3 }}
                onLoad={() => console.log("Map loaded")}
                onUnmount={() => console.log("Map unmounted")}
              >
                {formattedPoints.length > 0 && (
                  <Polyline
                    path={formattedPoints}
                    options={{
                      strokeColor: '#FF0000', // Use a bright color for visibility
                      strokeOpacity: 1.0,
                      strokeWeight: 15, // Increase weight for better visibility
                    }}
                  />
                )}
              </GoogleMap>
            </div>
          </div>
          {/* Uncomment the Footer if needed */}
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default Cartographie;
