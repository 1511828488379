import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DualListBox from 'react-dual-listbox';
import { FaCog } from 'react-icons/fa';

Modal.setAppElement('#root');

function ColumnSelectionModal({ columns, selectedColumns, handleColumnChange, pageKey }) {

  

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const storageKey = `selectedColumns_${pageKey}`;

  const availableOptions = columns.map(col => ({ value: col.name, label: col.name }));

  const [selectedOptions, setSelectedOptions] = useState(
    selectedColumns.map(col => col)
  );

  useEffect(() => {
    const savedColumns = JSON.parse(localStorage.getItem(storageKey));
    if (savedColumns) {
      const parsedColumns = savedColumns.filter(col => col);
      setSelectedOptions(parsedColumns.map(col => (col)));
      handleColumnChange(parsedColumns); 
    }
  }, []); 

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleChange = (newSelectedOptions) => {
    const columnNames = newSelectedOptions
      .map(option => option);
    setSelectedOptions(newSelectedOptions);
    handleColumnChange(columnNames); 
    localStorage.setItem(storageKey, JSON.stringify(columnNames));
  };
  
  

  return (
    <div>
      <button onClick={openModal} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
        <FaCog size={24} />
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Columns"
        style={{
          content: {
            width: '600px',
            height: '310px',
            margin: 'auto',
            padding: '20px',
            borderRadius: '8px',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <button onClick={closeModal} style={{ float: 'right', border: 'none', background: 'none', cursor: 'pointer' }}>
          &times;
        </button>
        <div className="column-selection mt-4">
          <h2>Sélection des colonnes à afficher</h2>
          <hr></hr>
          <DualListBox
            options={availableOptions} 
            selected={selectedOptions}  
            onChange={handleChange}
          />

          <div className='mt-2'>
            <p>Ces paramètres vous permettent de configurer la vue en personnalisant les colonnes que vous souhaitez afficher.</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ColumnSelectionModal;
