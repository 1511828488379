// FormComponent.js

import React from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';

const CreateValRef = ({
  formName,
  handleFormNameChange,
  data,
  selectedParent,
  handleParentChange,
  formContact,
  handleFormContactChange,
  formAdresse,
  handleFormAdresseChange,
  formCodePostal,
  handleFormCodePostalChange,
  formVille,
  handleFormVilleChange,
  formPays,
  handleFormPaysChange,
  formCoordonnees,
  handleFormCoordonneesChange,
  formDescription,
  handleFormDescriptionChange,
  formInfosSite,
  handleFormInfosSiteChange,
  formLatitudeLongitude,
  handleFormLatitudeLongitudeChange,
  formSite,
  handleFormSiteChange,
  formStatut,
  handleFormStatutChange,
  handleFormSubmit,
  loading,
  handleCloseModal
}) => {
  return (
    <>
        <h1>Ajout d'un ref</h1>
      <Form>
  <Row>
   
      {/* Nom */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="required fw-semibold text-right"
          >
            Nom
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            required
            value={formName}
            onChange={handleFormNameChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Référentiel Parent */}
      {data.parent && data.parent.valeurs && (
        <div className="row mb-3">
          <div className="col-md-3 d-flex justify-content-end">
            <label
              style={{
                color: "#0099e5",
                whiteSpace: "nowrap",
                paddingRight: "10px",
                paddingTop: "5px",
                fontSize: "14px",
              }}
              className="fw-semibold text-right"
            >
              Référentiel Parent ({data.parent.nom})
            </label>
          </div>
          <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
            <Select
              options={data.parent.valeurs.map((referentiel) => ({
                value: referentiel.id,
                label: `${referentiel.valeur}${
                  referentiel.parent_values.length > 0
                    ? ` -> ${referentiel.parent_values.join(' -> ')}`
                    : ''
                }`,
              }))}
              value={selectedParent}
              onChange={handleParentChange}
              placeholder="Sélectionner un parent"
              className="form-control"
              style={{
                width: "100%",
                maxWidth: "160px",
                marginLeft: "5px",
              }}
            />
          </div>
        </div>
      )}

      {/* Contact */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Contact
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            value={formContact}
            onChange={handleFormContactChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Adresse */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Adresse
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            value={formAdresse}
            onChange={handleFormAdresseChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Code Postal */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Code Postal
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            value={formCodePostal}
            onChange={handleFormCodePostalChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Ville */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Ville
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            value={formVille}
            onChange={handleFormVilleChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Pays */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Pays
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            value={formPays}
            onChange={handleFormPaysChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>
    

    
      {/* Coordonnées */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Coordonnées
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <input
            type="text"
            value={formCoordonnees}
            onChange={handleFormCoordonneesChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              height: "27px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Description */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Description
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <textarea
            rows={3}
            value={formDescription}
            onChange={handleFormDescriptionChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>

      {/* Infos Site */}
      <div className="row mb-3">
        <div className="col-md-3 d-flex justify-content-end">
          <label
            style={{
              color: "#0099e5",
              whiteSpace: "nowrap",
              paddingRight: "10px",
              paddingTop: "5px",
              fontSize: "14px",
            }}
            className="fw-semibold text-right"
          >
            Infos Site
          </label>
        </div>
        <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
          <textarea
            rows={3}
            value={formInfosSite}
            onChange={handleFormInfosSiteChange}
            style={{
              width: "100%",
              maxWidth: "160px",
              marginLeft: "5px",
            }}
            className="form-control"
          />
        </div>
      </div>
   
  </Row>
</Form>

      <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
      <Button
        variant="primary"
        onClick={handleFormSubmit}
        disabled={!formName || loading}
      >
        
        {loading ? <Spinner animation="border" size="sm" /> : 'Ajouter'}
      </Button>
    </>
  );
};

export default CreateValRef;
