import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const LoadingIndicator = ({ size = "3rem", color = "#04a0ac" ,message="Chargement ..."}) => {
  return (
    <div style={{ 
      display: "flex", 
      justifyContent: "center", 
      alignItems: "center", 
      height: "100%",
      flexDirection: "column"
    }}>
      <Spinner
        animation="border"
        role="status"
        style={{
          width: size,
          height: size,
          color: color,
        }}
      />
      <span style={{
        marginTop: "1rem",
        color: color,
        fontSize: "1.2rem",
        fontWeight: "bold"
      }}>
        {message}
      </span>
    </div>
  );
};

export default LoadingIndicator;
