import React from 'react'

function Footer() {
  return (
    <div>     {/*begin::Footer */}
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/*begin::Container */}
      <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/*begin::Copyright */}
        <div className="text-gray-900 order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">2024&copy;</span>
          <a href="/" target="_blank" className="text-gray-800 text-hover-primary">Swift Field Solutions</a>
        </div>
        {/*end::Copyright */}
        {/*begin::Menu */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          
          
          <li className="menu-item">
            <a className="menu-link px-2">Autre lien</a>
          </li>
        </ul>
        {/*end::Menu */}
      </div>
      {/*end::Container */}
    </div>
    {/*end::Footer */}</div>
  )
}

export default Footer