import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import api from "../Auth/axiosSetup";

function Formulaires() {
  const apiHost = process.env.REACT_APP_API_HOST;

  const { id } = useParams();
  const [fields, setFields] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [options, setOptions] = useState([{ label: "", value: "" }]);
  const [showModal, setShowModal] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [loading, setLoading] = useState(false);

  const [editingField, setEditingField] = useState(null);

  const handleFieldNameChange = (event) => {
    setFieldName(event.target.value);
  };

  useEffect(() => {
    const fetchFieldTypes = async () => {
      const response = await api.get(`/field_types`);
      setFieldTypes(response.data.data);
    };
    fetchFieldTypes();
  }, []);

  useEffect(() => {
    const fetchFields = async () => {
      const response = await api.get(`/forms/${id}/fields`);
      setFields(response.data);
    };
    fetchFields();
  }, [id]);

  const handleDelete = async (field) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this form?"
    );
    if (!confirmDelete) {
      return;
    }
    await api
      .delete(`${apiHost}/fields/${field.id}`)
      .then(() => {
        setFields(fields.filter((f) => f.id !== field.id));
      })
      .catch((error) => {
        console.error("Failed to delete the field", error);
      });
  };

  const handleOptionChange = (index, event) => {
    const newOptions = [...options];
    newOptions[index][event.target.name] = event.target.value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { label: "", value: "" }]);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    setFieldName("");
    setSelectedType("");
    setOptions([{ label: "", value: "" }]);
    setEditingField(null);
  };

  const openEditModal = (field) => {
    setEditingField(field);
    setFieldName(field.name);
    setSelectedType(field.field_type.name);
    setOptions(
      Array.isArray(field.options) ? field.options : [{ label: "", value: "" }]
    );
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    const field_type = fieldTypes.find((type) => type.name === selectedType);

    if (!fieldName || !selectedType || !field_type) {
      alert("Veuillez remplir tous les champs");
      return;
    }

    let opts =
      options.filter((option) => option.label && option.value).length > 0
        ? options.filter((option) => option.label && option.value)
        : null;
    // if (editingField) {
    //   if (
    //     selectedType !== "Selected" &&
    //     selectedType !== "Radio" &&
    //     selectedType !== "Checkbox"
    //   ) {
    //     opts = null;
    //   }
    // }

    const dataToSend = {
      name: fieldName,
      field_type_id: field_type.id,
      options: opts,
      form_id: id,
    };
  
    const url = editingField
      ? `${apiHost}/fields/${editingField.id}`
      : `${apiHost}/fields`;
    const method = editingField ? "put" : "post";

    await api({ method, url, data: dataToSend })
      .then((response) => {
        setLoading(true);
        setShowModal(false);
        response.data.data.field_type = field_type;
        if (editingField) {
          setFields(
            fields.map((f) =>
              f.id === editingField.id ? response.data.data : f
            )
          );
        } else {
          setFields([...fields, response.data.data]);
        }
      })
      .catch((error) => {
        console.error("Failed to save the field", error);
        alert("Failed to save the field");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}
            <Header />
            {/*end::Header */}
            {/*begin::Toolbar */}
            <div className="toolbar py-5 pb-lg-15" id="kt_toolbar">
              {/*begin::Container */}
              <div
                id="kt_toolbar_container"
                className="container-xxl d-flex flex-stack flex-wrap"
              >
                {/*begin::Page title */}
                <div className="page-title d-flex flex-column me-3">
                  {/*begin::Title */}
                  <h1 className="d-flex text-white fw-bold my-1 fs-3">
                    Liste des champs
                  </h1>
                  {/*end::Title */}
                  {/*begin::Breadcrumb */}
                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                    {/*begin::Item */}
                    <li className="breadcrumb-item text-white opacity-75">
                      <Link to="/" className="text-white text-hover-primary">
                        Accueil
                      </Link>
                    </li>
                    {/*end::Item */}

                    {/*begin::Item */}
                    <li className="breadcrumb-item">
                      <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                    </li>
                    {/*end::Item */}
                    {/*begin::Item */}
                    <li className="breadcrumb-item text-white opacity-75">
                      <Link
                        to="/formulaires"
                        className="text-white text-hover-primary"
                      >
                        Liste des formulaires
                      </Link>
                    </li>
                    {/*end::Item */}
                    {/*begin::Item */}
                    <li className="breadcrumb-item">
                      <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                    </li>
                    {/*end::Item */}
                    {/*begin::Item */}
                    <li className="breadcrumb-item text-white opacity-100">
                      Liste Des Champs
                    </li>
                    {/*end::Item */}
                  </ul>
                  {/*end::Breadcrumb */}
                </div>
                {/*end::Page title */}
              </div>
              {/*end::Container */}
            </div>
            {/*end::Toolbar */}
            {/*begin::Container */}
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              {/*begin::Post */}
              <div className="content flex-row-fluid" id="kt_content">
                {/*begin::Card */}
                <div className="card">
                  {/*begin::Card header */}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Liste des Champs
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Ce formulaire contient au total{" "}
                        <b>{fields.length} champs</b>.
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <button
                        className="btn  btn-primary"
                        onClick={toggleModal}
                      >
                        <i className="ki-duotone ki-plus fs-2" /> Nouveau Champ
                      </button>
                    </div>
                  </div>

                  {/*end::Card header */}
                  {/*begin::Card body */}
                  <div className="container ">
                    <div>
                      <Modal show={showModal} onHide={toggleModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Ajouter un nouveau champ</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Group>
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Entrez le nom du champ"
                              value={fieldName}
                              onChange={handleFieldNameChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                              as="select"
                              value={selectedType}
                              onChange={(e) => setSelectedType(e.target.value)}
                              disabled={editingField}
                            >
                              <option value="">Sélectionnez un type</option>
                              {fieldTypes.map((type) => (
                                <option key={type.id} value={type.name}>
                                  {type.name}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>

                          {selectedType === "Checkbox" ||
                          selectedType === "Radio" ||
                          selectedType === "Select" ? (
                            <Form.Group>
                              <Form.Label className="m-2">Options</Form.Label>
                              {Array.isArray(options)
                                ? options.map((option, index) => (
                                    <div
                                      key={index}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Label"
                                        name="label"
                                        value={option.label}
                                        onChange={(e) =>
                                          handleOptionChange(index, e)
                                        }
                                        className="m-2"
                                      />
                                      <Form.Control
                                        type="text"
                                        placeholder="Valeur"
                                        name="value"
                                        value={option.value}
                                        onChange={(e) =>
                                          handleOptionChange(index, e)
                                        }
                                        className="m-2"
                                      />
                                      <Button
                                        variant="danger"
                                        onClick={() => removeOption(index)}
                                      >
                                        Supprimer
                                      </Button>
                                    </div>
                                  ))
                                : null}
                              <Button onClick={addOption} className="mt-2">
                                Ajouter une option
                              </Button>
                            </Form.Group>
                          ) : null}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={toggleModal}>
                            Fermer
                          </Button>
                          <Button
                            variant="primary"
                            disabled={!fieldName || loading}
                            onClick={handleSaveChanges}
                          >
                            {loading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Sauvegarder les changements"
                            )}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        {/*begin::Table head*/}
                        <thead>
                          <tr className="fw-bold text-muted">
                            <th className="min-w-200px">Nom du champ</th>
                            <th className="min-w-150px">Type</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>
                        {/*end::Table head*/}
                        <tbody>
                          {loading && <h1>hiii</h1>}
                          {Array.isArray(fields)
                            ? fields.map((field) => (
                                <tr key={field.id}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex justify-content-start flex-column">
                                        {field.name}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{field.field_type.name}</td>

                                  <td>
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                      {/* <a
                   href="#"
                   className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                 >
                   <i className="ki-duotone ki-switch fs-2">
                     <span className="path1" />
                     <span className="path2" />
                   </i>{" "}
                 </a> */}
                                      <button
                                        onClick={() => openEditModal(field)}
                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                      >
                                        <i
                                          className="ki-duotone ki-pencil fs-2"
                                          style={{
                                            color: "#007bff",
                                          }}
                                        >
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>{" "}
                                      </button>

                                      {
                                        <button
                                          onClick={() => handleDelete(field)}
                                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                        >
                                          <i
                                            className="ki-duotone ki-trash fs-2"
                                            style={{
                                              color: "#C12869",
                                            }}
                                          >
                                            <span className="path1" />
                                            <span className="path2" />
                                            <span className="path3" />
                                            <span className="path4" />
                                            <span className="path5" />
                                          </i>{" "}
                                        </button>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/*end::Card body */}
                </div>
                {/*end::Card */}
              </div>
              {/*end::Post */}
            </div>
            {/*end::Container */}
            {/*begin::Footer */}
            {/* <Footer /> */}
            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
    </div>
  );
}

export default Formulaires;
