import React from 'react'

function DashbordPage() {

  return (
<>
  {/*begin::Theme mode setup on page load*/}
  {/*end::Theme mode setup on page load*/}
  {/*begin::Main*/}
  {/*begin::Root*/}
  <div className="d-flex flex-column flex-root">
    {/*begin::Page*/}
    <div className="page d-flex flex-row flex-column-fluid">
      {/*begin::Wrapper*/}
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        {/*begin::Header*/}
        <div
          id="kt_header"
          className="header align-items-stretch mb-5 mb-lg-10"
          data-kt-sticky="true"
          data-kt-sticky-name="header"
          data-kt-sticky-offset="{default: '200px', lg: '300px'}"
        >
          {/*begin::Container*/}
          <div className="container-xxl d-flex align-items-center">
            {/*begin::Heaeder menu toggle*/}
            <div
              className="d-flex topbar align-items-center d-lg-none ms-n2 me-3"
              title="Show aside menu"
            >
              <div
                className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                id="kt_header_menu_mobile_toggle"
              >
                <i className="ki-duotone ki-abstract-14 fs-1">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
            </div>
            {/*end::Heaeder menu toggle*/}
            {/*begin::Header Logo*/}
            <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
              <a href="/">
                <img
                  alt="Logo"
                  src="assets/media/logos/demo2.png"
                  className="logo-default h-25px"
                />
                <img
                  alt="Logo"
                  src="assets/media/logos/demo2-sticky.png"
                  className="logo-sticky h-25px"
                />
              </a>
            </div>
            {/*end::Header Logo*/}
            {/*begin::Wrapper*/}
            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              {/*begin::Navbar*/}
              <div className="d-flex align-items-stretch" id="kt_header_nav">
                {/*begin::Menu wrapper*/}
                <div
                  className="header-menu align-items-stretch"
                  data-kt-drawer="true"
                  data-kt-drawer-name="header-menu"
                  data-kt-drawer-activate="{default: true, lg: false}"
                  data-kt-drawer-overlay="true"
                  data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                  data-kt-drawer-direction="start"
                  data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                  data-kt-swapper="true"
                  data-kt-swapper-mode="prepend"
                  data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
                >
                  {/*begin::Menu*/}
                  <div
                    className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-500 fw-semibold my-5 my-lg-0 align-items-stretch px-2 px-lg-0"
                    id="#kt_header_menu"
                    data-kt-menu="true"
                  >
                    {/*begin:Menu item*/}
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="bottom-start"
                      className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2"
                    >
                      {/*begin:Menu link*/}
                      <span className="menu-link py-3">
                        <span className="menu-title">Dashboards</span>
                        <span className="menu-arrow d-lg-none" />
                      </span>
                      {/*end:Menu link*/}
                      {/*begin:Menu sub*/}
                      <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-850px">
                        {/*begin:Dashboards menu*/}
                        <div
                          className="menu-state-bg menu-extended overflow-hidden overflow-lg-visible"
                          data-kt-menu-dismiss="true"
                        >
                          {/*begin:Row*/}
                          <div className="row">
                            {/*begin:Col*/}
                            <div className="col-lg-8 mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6">
                              {/*begin:Row*/}
                              <div className="row">
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a href="/" className="menu-link">
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-element-11 text-primary fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          Default
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Reports &amp; statistics
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/ecommerce.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-basket text-danger fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          eCommerce
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Sales reports
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/projects.html"
                                      className="menu-link active"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-abstract-44 text-info fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          Projects
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Tasts, graphs &amp; charts
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/online-courses.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-color-swatch text-success fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                          <span className="path6" />
                                          <span className="path7" />
                                          <span className="path8" />
                                          <span className="path9" />
                                          <span className="path10" />
                                          <span className="path11" />
                                          <span className="path12" />
                                          <span className="path13" />
                                          <span className="path14" />
                                          <span className="path15" />
                                          <span className="path16" />
                                          <span className="path17" />
                                          <span className="path18" />
                                          <span className="path19" />
                                          <span className="path20" />
                                          <span className="path21" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          Online Courses
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Student progress
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/marketing.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-chart-simple text-gray-900 fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          Marketing
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Campaings &amp; conversions
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/bidding.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-switch text-warning fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          Bidding
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Campaings &amp; conversions
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/pos.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-abstract-42 text-danger fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          POS System
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Campaings &amp; conversions
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-6 mb-3">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="dashboards/call-center.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3">
                                        <i className="ki-duotone ki-call text-primary fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                          <span className="path4" />
                                          <span className="path5" />
                                          <span className="path6" />
                                          <span className="path7" />
                                          <span className="path8" />
                                        </i>
                                      </span>
                                      <span className="d-flex flex-column">
                                        <span className="fs-6 fw-bold text-gray-800">
                                          Call Center
                                        </span>
                                        <span className="fs-7 fw-semibold text-muted">
                                          Campaings &amp; conversions
                                        </span>
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                              </div>
                              {/*end:Row*/}
                              <div className="separator separator-dashed mx-5 my-5" />
                              {/*begin:Landing*/}
                              <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-2 mx-5">
                                <div className="d-flex flex-column me-5">
                                  <div className="fs-6 fw-bold text-gray-800">
                                    Landing Page Template
                                  </div>
                                  <div className="fs-7 fw-semibold text-muted">
                                    Onpe page landing template with pricing
                                    &amp; others
                                  </div>
                                </div>
                                <a
                                  href="landing.html"
                                  className="btn btn-sm btn-primary fw-bold"
                                >
                                  Explore
                                </a>
                              </div>
                              {/*end:Landing*/}
                            </div>
                            {/*end:Col*/}
                            {/*begin:Col*/}
                            <div className="menu-more bg-light col-lg-4 py-3 px-3 py-lg-6 px-lg-6 rounded-end">
                              {/*begin:Heading*/}
                              <h4 className="fs-6 fs-lg-4 text-gray-800 fw-bold mt-3 mb-3 ms-4">
                                More Dashboards
                              </h4>
                              {/*end:Heading*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/logistics.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">Logistics</span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/website-analytics.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">
                                    Website Analytics
                                  </span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/finance-performance.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">
                                    Finance Performance
                                  </span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/store-analytics.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">
                                    Store Analytics
                                  </span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/social.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">Social</span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/delivery.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">Delivery</span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/crypto.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">Crypto</span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/school.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">School</span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                              {/*begin:Menu item*/}
                              <div className="menu-item p-0 m-0">
                                {/*begin:Menu link*/}
                                <a
                                  href="dashboards/podcast.html"
                                  className="menu-link py-2"
                                >
                                  <span className="menu-title">Podcast</span>
                                </a>
                                {/*end:Menu link*/}
                              </div>
                              {/*end:Menu item*/}
                            </div>
                            {/*end:Col*/}
                          </div>
                          {/*end:Row*/}
                        </div>
                        {/*end:Dashboards menu*/}
                      </div>
                      {/*end:Menu sub*/}
                    </div>
                    {/*end:Menu item*/}
                    {/*begin:Menu item*/}
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="bottom-start"
                      className="menu-item menu-lg-down-accordion me-0 me-lg-2"
                    >
                      {/*begin:Menu link*/}
                      <span className="menu-link py-3">
                        <span className="menu-title">Pages</span>
                        <span className="menu-arrow d-lg-none" />
                      </span>
                      {/*end:Menu link*/}
                      {/*begin:Menu sub*/}
                      <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0">
                        {/*begin:Pages menu*/}
                        <div className="menu-active-bg px-4 px-lg-0">
                          {/*begin:Tabs nav*/}
                          <div className="d-flex w-100 overflow-auto">
                            <ul className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1">
                              {/*begin:Nav item*/}
                              <li className="nav-item mx-lg-1">
                                <a
                                  className="nav-link py-3 py-lg-6 active text-active-primary"
                                  href="#"
                                  data-bs-toggle="tab"
                                  data-bs-target="#kt_app_header_menu_pages_pages"
                                >
                                  General
                                </a>
                              </li>
                              {/*end:Nav item*/}
                              {/*begin:Nav item*/}
                              <li className="nav-item mx-lg-1">
                                <a
                                  className="nav-link py-3 py-lg-6 text-active-primary"
                                  href="#"
                                  data-bs-toggle="tab"
                                  data-bs-target="#kt_app_header_menu_pages_account"
                                >
                                  Account
                                </a>
                              </li>
                              {/*end:Nav item*/}
                              {/*begin:Nav item*/}
                              <li className="nav-item mx-lg-1">
                                <a
                                  className="nav-link py-3 py-lg-6 text-active-primary"
                                  href="#"
                                  data-bs-toggle="tab"
                                  data-bs-target="#kt_app_header_menu_pages_authentication"
                                >
                                  Authentication
                                </a>
                              </li>
                              {/*end:Nav item*/}
                              {/*begin:Nav item*/}
                              <li className="nav-item mx-lg-1">
                                <a
                                  className="nav-link py-3 py-lg-6 text-active-primary"
                                  href="#"
                                  data-bs-toggle="tab"
                                  data-bs-target="#kt_app_header_menu_pages_utilities"
                                >
                                  Utilities
                                </a>
                              </li>
                              {/*end:Nav item*/}
                              {/*begin:Nav item*/}
                              <li className="nav-item mx-lg-1">
                                <a
                                  className="nav-link py-3 py-lg-6 text-active-primary"
                                  href="#"
                                  data-bs-toggle="tab"
                                  data-bs-target="#kt_app_header_menu_pages_widgets"
                                >
                                  Widgets
                                </a>
                              </li>
                              {/*end:Nav item*/}
                            </ul>
                          </div>
                          {/*end:Tabs nav*/}
                          {/*begin:Tab content*/}
                          <div className="tab-content py-4 py-lg-8 px-lg-7">
                            {/*begin:Tab pane*/}
                            <div
                              className="tab-pane active w-lg-1000px"
                              id="kt_app_header_menu_pages_pages"
                            >
                              {/*begin:Row*/}
                              <div className="row">
                                {/*begin:Col*/}
                                <div className="col-lg-8">
                                  {/*begin:Row*/}
                                  <div className="row">
                                    {/*begin:Col*/}
                                    <div className="col-lg-3 mb-6 mb-lg-0">
                                      {/*begin:Menu heading*/}
                                      <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                        User Profile
                                      </h4>
                                      {/*end:Menu heading*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="pages/user-profile/overview.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Overview
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="pages/user-profile/projects.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Projects
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="pages/user-profile/campaigns.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Campaigns
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="pages/user-profile/documents.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Documents
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="pages/user-profile/followers.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Followers
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="pages/user-profile/activity.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Activity
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                    </div>
                                    {/*end:Col*/}
                                    {/*begin:Col*/}
                                    <div className="col-lg-3 mb-6 mb-lg-0">
                                      {/*begin:Menu section*/}
                                      <div className="mb-6">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Corporate
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/about.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              About
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/team.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Our Team
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/contact.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Contact Us
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/licenses.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Licenses
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/sitemap.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Sitemap
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                      {/*begin:Menu section*/}
                                      <div className="mb-0">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Careers
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/careers/list.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Careers List
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/careers/apply.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Careers Apply
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                    </div>
                                    {/*end:Col*/}
                                    {/*begin:Col*/}
                                    <div className="col-lg-3 mb-6 mb-lg-0">
                                      {/*begin:Menu section*/}
                                      <div className="mb-6">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          FAQ
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/faq/classic.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              FAQ Classic
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/faq/extended.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              FAQ Extended
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                      {/*begin:Menu section*/}
                                      <div className="mb-6">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Blog
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/blog/home.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Blog Home
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/blog/post.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Blog Post
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                      {/*begin:Menu section*/}
                                      <div className="mb-0">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Pricing
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/pricing.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Column Pricing
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/pricing/table.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Table Pricing
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                    </div>
                                    {/*end:Col*/}
                                    {/*begin:Col*/}
                                    <div className="col-lg-3 mb-6 mb-lg-0">
                                      {/*begin:Menu section*/}
                                      <div className="mb-0">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Social
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/social/feeds.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Feeds
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/social/activity.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Activty
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/social/followers.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Followers
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="pages/social/settings.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Settings
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                    </div>
                                    {/*end:Col*/}
                                  </div>
                                  {/*end:Row*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-4">
                                  <img
                                    src="assets/media/stock/600x600/img-82.jpg"
                                    className="rounded mw-100"
                                    alt=""
                                  />
                                </div>
                                {/*end:Col*/}
                              </div>
                              {/*end:Row*/}
                            </div>
                            {/*end:Tab pane*/}
                            {/*begin:Tab pane*/}
                            <div
                              className="tab-pane w-lg-600px"
                              id="kt_app_header_menu_pages_account"
                            >
                              {/*begin:Row*/}
                              <div className="row">
                                {/*begin:Col*/}
                                <div className="col-lg-5 mb-6 mb-lg-0">
                                  {/*begin:Row*/}
                                  <div className="row">
                                    {/*begin:Col*/}
                                    <div className="col-lg-6">
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/overview.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Overview
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/settings.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Settings
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/security.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Security
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/activity.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Activity
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/billing.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Billing
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                    </div>
                                    {/*end:Col*/}
                                    {/*begin:Col*/}
                                    <div className="col-lg-6">
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/statements.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Statements
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/referrals.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Referrals
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/api-keys.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            API Keys
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                      {/*begin:Menu item*/}
                                      <div className="menu-item p-0 m-0">
                                        {/*begin:Menu link*/}
                                        <a
                                          href="account/logs.html"
                                          className="menu-link"
                                        >
                                          <span className="menu-title">
                                            Logs
                                          </span>
                                        </a>
                                        {/*end:Menu link*/}
                                      </div>
                                      {/*end:Menu item*/}
                                    </div>
                                    {/*end:Col*/}
                                  </div>
                                  {/*end:Row*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-7">
                                  <img
                                    src="assets/media/stock/900x600/46.jpg"
                                    className="rounded mw-100"
                                    alt=""
                                  />
                                </div>
                                {/*end:Col*/}
                              </div>
                              {/*end:Row*/}
                            </div>
                            {/*end:Tab pane*/}
                            {/*begin:Tab pane*/}
                            <div
                              className="tab-pane w-lg-1000px"
                              id="kt_app_header_menu_pages_authentication"
                            >
                              {/*begin:Row*/}
                              <div className="row">
                                {/*begin:Col*/}
                                <div className="col-lg-3 mb-6 mb-lg-0">
                                  {/*begin:Menu section*/}
                                  <div className="mb-6">
                                    {/*begin:Menu heading*/}
                                    <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                      Corporate Layout
                                    </h4>
                                    {/*end:Menu heading*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/corporate/sign-in.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-In
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/corporate/sign-up.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-Up
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/corporate/two-factor.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Two-Factor
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/corporate/reset-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Reset Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/corporate/new-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          New Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                  </div>
                                  {/*end:Menu section*/}
                                  {/*begin:Menu section*/}
                                  <div className="mb-0">
                                    {/*begin:Menu heading*/}
                                    <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                      Overlay Layout
                                    </h4>
                                    {/*end:Menu heading*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/overlay/sign-in.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-In
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/overlay/sign-up.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-Up
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/overlay/two-factor.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Two-Factor
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/overlay/reset-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Reset Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/overlay/new-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          New Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                  </div>
                                  {/*end:Menu section*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-3 mb-6 mb-lg-0">
                                  {/*begin:Menu section*/}
                                  <div className="mb-6">
                                    {/*begin:Menu heading*/}
                                    <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                      Creative Layout
                                    </h4>
                                    {/*end:Menu heading*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/creative/sign-in.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-in
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/creative/sign-up.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-up
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/creative/two-factor.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Two-Factor
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/creative/reset-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Reset Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/creative/new-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          New Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                  </div>
                                  {/*end:Menu section*/}
                                  {/*begin:Menu section*/}
                                  <div className="mb-6">
                                    {/*begin:Menu heading*/}
                                    <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                      Fancy Layout
                                    </h4>
                                    {/*end:Menu heading*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/fancy/sign-in.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-In
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/fancy/sign-up.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Sign-Up
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/fancy/two-factor.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Two-Factor
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/fancy/reset-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Reset Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/layouts/fancy/new-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          New Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                  </div>
                                  {/*end:Menu section*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-3 mb-6 mb-lg-0">
                                  {/*begin:Menu section*/}
                                  <div className="mb-0">
                                    {/*begin:Menu heading*/}
                                    <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                      General
                                    </h4>
                                    {/*end:Menu heading*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/extended/multi-steps-sign-up.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Multi-Steps Sign-Up
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/welcome.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Welcome Message
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/verify-email.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Verify Email
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/coming-soon.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Coming Soon
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/password-confirmation.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Password Confirmation
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/account-deactivated.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Account Deactivation
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/error-404.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Error 404
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/general/error-500.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Error 500
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                  </div>
                                  {/*end:Menu section*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-3 mb-6 mb-lg-0">
                                  {/*begin:Menu section*/}
                                  <div className="mb-0">
                                    {/*begin:Menu heading*/}
                                    <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                      Email Templates
                                    </h4>
                                    {/*end:Menu heading*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/welcome-message.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Welcome Message
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/reset-password.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Reset Password
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/subscription-confirmed.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Subscription Confirmed
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/card-declined.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Credit Card Declined
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/promo-1.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Promo 1
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/promo-2.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Promo 2
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                    {/*begin:Menu item*/}
                                    <div className="menu-item p-0 m-0">
                                      {/*begin:Menu link*/}
                                      <a
                                        href="authentication/email/promo-3.html"
                                        className="menu-link"
                                      >
                                        <span className="menu-title">
                                          Promo 3
                                        </span>
                                      </a>
                                      {/*end:Menu link*/}
                                    </div>
                                    {/*end:Menu item*/}
                                  </div>
                                  {/*end:Menu section*/}
                                </div>
                                {/*end:Col*/}
                              </div>
                              {/*end:Row*/}
                            </div>
                            {/*end:Tab pane*/}
                            {/*begin:Tab pane*/}
                            <div
                              className="tab-pane w-lg-1000px"
                              id="kt_app_header_menu_pages_utilities"
                            >
                              {/*begin:Row*/}
                              <div className="row">
                                {/*begin:Col*/}
                                <div className="col-lg-7">
                                  {/*begin:Row*/}
                                  <div className="row">
                                    {/*begin:Col*/}
                                    <div className="col-lg-4 mb-6 mb-lg-0">
                                      {/*begin:Menu section*/}
                                      <div className="mb-0">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          General Modals
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/general/invite-friends.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Invite Friends
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/general/view-users.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              View Users
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/general/select-users.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Select Users
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/general/upgrade-plan.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Upgrade Plan
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/general/share-earn.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Share &amp; Earn
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/forms/new-target.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              New Target
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/forms/new-card.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              New Card
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/forms/new-address.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              New Address
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/forms/create-api-key.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create API Key
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/forms/bidding.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Bidding
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                    </div>
                                    {/*end:Col*/}
                                    {/*begin:Col*/}
                                    <div className="col-lg-4 mb-6 mb-lg-0">
                                      {/*begin:Menu section*/}
                                      <div className="mb-6">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Advanced Modals
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/create-app.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create App
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/create-campaign.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create Campaign
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/create-account.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create Business Acc
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/create-project.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create Project
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/top-up-wallet.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Top Up Wallet
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/offer-a-deal.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Offer a Deal
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/two-factor-authentication.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Two Factor Auth
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                      {/*begin:Menu section*/}
                                      <div className="mb-0">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Search
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/search/horizontal.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Horizontal
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/search/vertical.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Vertical
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/search/users.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Users
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/search/select-location.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Select Location
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                    </div>
                                    {/*end:Col*/}
                                    {/*begin:Col*/}
                                    <div className="col-lg-4 mb-6 mb-lg-0">
                                      {/*begin:Menu section*/}
                                      <div className="mb-0">
                                        {/*begin:Menu heading*/}
                                        <h4 className="fs-6 fs-lg-4 fw-bold mb-3 ms-4">
                                          Wizards
                                        </h4>
                                        {/*end:Menu heading*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/horizontal.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Horizontal
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/vertical.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Vertical
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/two-factor-authentication.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Two Factor Auth
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/create-app.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create App
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/create-campaign.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create Campaign
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/create-account.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create Account
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/create-project.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Create Project
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/modals/wizards/top-up-wallet.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Top Up Wallet
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                        {/*begin:Menu item*/}
                                        <div className="menu-item p-0 m-0">
                                          {/*begin:Menu link*/}
                                          <a
                                            href="utilities/wizards/offer-a-deal.html"
                                            className="menu-link"
                                          >
                                            <span className="menu-title">
                                              Offer a Deal
                                            </span>
                                          </a>
                                          {/*end:Menu link*/}
                                        </div>
                                        {/*end:Menu item*/}
                                      </div>
                                      {/*end:Menu section*/}
                                    </div>
                                    {/*end:Col*/}
                                  </div>
                                  {/*end:Row*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-5 pe-lg-5">
                                  <img
                                    src="assets/media/stock/600x600/img-84.jpg"
                                    className="rounded mw-100"
                                    alt=""
                                  />
                                </div>
                                {/*end:Col*/}
                              </div>
                              {/*end:Row*/}
                            </div>
                            {/*end:Tab pane*/}
                            {/*begin:Tab pane*/}
                            <div
                              className="tab-pane w-lg-500px"
                              id="kt_app_header_menu_pages_widgets"
                            >
                              {/*begin:Row*/}
                              <div className="row">
                                {/*begin:Col*/}
                                <div className="col-lg-4 mb-6 mb-lg-0">
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="widgets/lists.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-title">Lists</span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="widgets/statistics.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-title">
                                        Statistics
                                      </span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="widgets/charts.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-title">Charts</span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="widgets/mixed.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-title">Mixed</span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="widgets/tables.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-title">Tables</span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                  {/*begin:Menu item*/}
                                  <div className="menu-item p-0 m-0">
                                    {/*begin:Menu link*/}
                                    <a
                                      href="widgets/feeds.html"
                                      className="menu-link"
                                    >
                                      <span className="menu-title">Feeds</span>
                                    </a>
                                    {/*end:Menu link*/}
                                  </div>
                                  {/*end:Menu item*/}
                                </div>
                                {/*end:Col*/}
                                {/*begin:Col*/}
                                <div className="col-lg-8">
                                  <img
                                    src="assets/media/stock/900x600/44.jpg"
                                    className="rounded mw-100"
                                    alt=""
                                  />
                                </div>
                                {/*end:Col*/}
                              </div>
                              {/*end:Row*/}
                            </div>
                            {/*end:Tab pane*/}
                          </div>
                          {/*end:Tab content*/}
                        </div>
                        {/*end:Pages menu*/}
                      </div>
                      {/*end:Menu sub*/}
                    </div>
                    {/*end:Menu item*/}
                    {/*begin:Menu item*/}
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="bottom-start"
                      className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2"
                    >
                      {/*begin:Menu link*/}
                      <span className="menu-link py-3">
                        <span className="menu-title">Apps</span>
                        <span className="menu-arrow d-lg-none" />
                      </span>
                      {/*end:Menu link*/}
                      {/*begin:Menu sub*/}
                      <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-250px">
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-rocket fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Projects</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/list.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">My Projects</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/project.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">View Project</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/targets.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Targets</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/budget.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Budget</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/users.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Users</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/files.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Files</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/activity.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Activity</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/projects/settings.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Settings</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-handcart fs-2" />
                            </span>
                            <span className="menu-title">eCommerce</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                              data-kt-menu-placement="right-start"
                              className="menu-item menu-lg-down-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Catalog</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/catalog/products.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Products</span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/catalog/categories.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Categories
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/catalog/add-product.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Add Product
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/catalog/edit-product.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Edit Product
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/catalog/add-category.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Add Category
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/catalog/edit-category.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Edit Category
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="click"
                              className="menu-item menu-accordion menu-sub-indention"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Sales</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-accordion">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/sales/listing.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Orders Listing
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/sales/details.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Order Details
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/sales/add-order.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Add Order
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/sales/edit-order.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Edit Order
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="click"
                              className="menu-item menu-accordion menu-sub-indention"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Customers</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-accordion">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/customers/listing.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Customers Listing
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/customers/details.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Customers Details
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="click"
                              className="menu-item menu-accordion menu-sub-indention"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Reports</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-accordion">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/reports/view.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Products Viewed
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/reports/sales.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Sales</span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/reports/returns.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Returns</span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/reports/customer-orders.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Customer Orders
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/ecommerce/reports/shipping.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Shipping</span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/ecommerce/settings.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Settings</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-chart fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Support Center</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/support-center/overview.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Overview</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                              data-kt-menu-placement="right-start"
                              className="menu-item menu-lg-down-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Tickets</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/support-center/tickets/list.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Ticket List
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/support-center/tickets/view.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Ticket View
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                              data-kt-menu-placement="right-start"
                              className="menu-item menu-lg-down-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Tutorials</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/support-center/tutorials/list.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Tutorials List
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/support-center/tutorials/post.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Tutorials Post
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/support-center/faq.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">FAQ</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/support-center/licenses.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Licenses</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/support-center/contact.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Contact Us</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-shield-tick fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">User Management</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                              data-kt-menu-placement="right-start"
                              className="menu-item menu-lg-down-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Users</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/user-management/users/list.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Users List
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/user-management/users/view.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      View User
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                              data-kt-menu-placement="right-start"
                              className="menu-item menu-lg-down-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Roles</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/user-management/roles/list.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Roles List
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/user-management/roles/view.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      View Roles
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/user-management/permissions.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Permissions</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-phone fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Contacts</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/contacts/getting-started.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Getting Started
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/contacts/add-contact.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Add Contact</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/contacts/edit-contact.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Edit Contact</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/contacts/view-contact.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">View Contact</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-basket fs-2">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                              </i>
                            </span>
                            <span className="menu-title">Subscriptions</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/subscriptions/getting-started.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Getting Started
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/subscriptions/list.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Subscription List
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/subscriptions/add.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Add Subscription
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/subscriptions/view.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  View Subscription
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-briefcase fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Customers</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/customers/getting-started.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Getting Started
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/customers/list.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Customer Listing
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/customers/view.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Customer Details
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-credit-cart fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">
                              Invoice Management
                            </span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div
                              data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                              data-kt-menu-placement="right-start"
                              className="menu-item menu-lg-down-accordion"
                            >
                              {/*begin:Menu link*/}
                              <span className="menu-link py-3">
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Profile</span>
                                <span className="menu-arrow" />
                              </span>
                              {/*end:Menu link*/}
                              {/*begin:Menu sub*/}
                              <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/invoices/view/invoice-1.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Invoice 1
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/invoices/view/invoice-2.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Invoice 2
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                                {/*begin:Menu item*/}
                                <div className="menu-item">
                                  {/*begin:Menu link*/}
                                  <a
                                    className="menu-link py-3"
                                    href="apps/invoices/view/invoice-3.html"
                                  >
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">
                                      Invoice 3
                                    </span>
                                  </a>
                                  {/*end:Menu link*/}
                                </div>
                                {/*end:Menu item*/}
                              </div>
                              {/*end:Menu sub*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/invoices/create.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Create Invoice
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-file-added fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">File Manager</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/file-manager/folders.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Folders</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/file-manager/files.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Files</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/file-manager/blank.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  Blank Directory
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/file-manager/settings.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Settings</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-sms fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Inbox</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/inbox/listing.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Messages</span>
                                <span className="menu-badge">
                                  <span className="badge badge-light-success">
                                    3
                                  </span>
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/inbox/compose.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Compose</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/inbox/reply.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">
                                  View &amp; Reply
                                </span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div
                          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                          data-kt-menu-placement="right-start"
                          className="menu-item menu-lg-down-accordion"
                        >
                          {/*begin:Menu link*/}
                          <span className="menu-link py-3">
                            <span className="menu-icon">
                              <i className="ki-duotone ki-message-text-2 fs-2">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </i>
                            </span>
                            <span className="menu-title">Chat</span>
                            <span className="menu-arrow" />
                          </span>
                          {/*end:Menu link*/}
                          {/*begin:Menu sub*/}
                          <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-active-bg px-lg-2 py-lg-4 w-lg-225px">
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/chat/private.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Private Chat</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/chat/group.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Group Chat</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                            {/*begin:Menu item*/}
                            <div className="menu-item">
                              {/*begin:Menu link*/}
                              <a
                                className="menu-link py-3"
                                href="apps/chat/drawer.html"
                              >
                                <span className="menu-bullet">
                                  <span className="bullet bullet-dot" />
                                </span>
                                <span className="menu-title">Drawer Chat</span>
                              </a>
                              {/*end:Menu link*/}
                            </div>
                            {/*end:Menu item*/}
                          </div>
                          {/*end:Menu sub*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div className="menu-item">
                          {/*begin:Menu link*/}
                          <a
                            className="menu-link py-3"
                            href="apps/calendar.html"
                          >
                            <span className="menu-icon">
                              <i className="ki-duotone ki-calendar-8 fs-2">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                                <span className="path5" />
                                <span className="path6" />
                              </i>
                            </span>
                            <span className="menu-title">Calendar</span>
                          </a>
                          {/*end:Menu link*/}
                        </div>
                        {/*end:Menu item*/}
                      </div>
                      {/*end:Menu sub*/}
                    </div>
                    {/*end:Menu item*/}
                    {/*begin:Menu item*/}
                    <div
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="bottom-start"
                      className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2"
                    >
                      {/*begin:Menu link*/}
                      <span className="menu-link py-3">
                        <span className="menu-title">Help</span>
                        <span className="menu-arrow d-lg-none" />
                      </span>
                      {/*end:Menu link*/}
                      {/*begin:Menu sub*/}
                      <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">
                        {/*begin:Menu item*/}
                        <div className="menu-item">
                          {/*begin:Menu link*/}
                          <a
                            className="menu-link py-3"
                            href="https://preview.keenthemes.com/html/metronic/docs/base/utilities"
                            target="_blank"
                            title="Check out over 200 in-house components, plugins and ready for use solutions"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-dismiss="click"
                            data-bs-placement="right"
                          >
                            <span className="menu-icon">
                              <i className="ki-duotone ki-rocket fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Components</span>
                          </a>
                          {/*end:Menu link*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div className="menu-item">
                          {/*begin:Menu link*/}
                          <a
                            className="menu-link py-3"
                            href="https://preview.keenthemes.com/html/metronic/docs"
                            target="_blank"
                            title="Check out the complete documentation"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-dismiss="click"
                            data-bs-placement="right"
                          >
                            <span className="menu-icon">
                              <i className="ki-duotone ki-abstract-26 fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Documentation</span>
                          </a>
                          {/*end:Menu link*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div className="menu-item">
                          {/*begin:Menu link*/}
                          <a
                            className="menu-link py-3"
                            href="https://preview.keenthemes.com/metronic8/demo2/layout-builder.html"
                            title="Build your layout and export HTML for server side integration"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-dismiss="click"
                            data-bs-placement="right"
                          >
                            <span className="menu-icon">
                              <i className="ki-duotone ki-switch fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                            <span className="menu-title">Layout Builder</span>
                          </a>
                          {/*end:Menu link*/}
                        </div>
                        {/*end:Menu item*/}
                        {/*begin:Menu item*/}
                        <div className="menu-item">
                          {/*begin:Menu link*/}
                          <a
                            className="menu-link py-3"
                            href="https://preview.keenthemes.com/html/metronic/docs/getting-started/changelog"
                            target="_blank"
                          >
                            <span className="menu-icon">
                              <i className="ki-duotone ki-code fs-2">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                              </i>
                            </span>
                            <span className="menu-title">Changelog v8.2.5</span>
                          </a>
                          {/*end:Menu link*/}
                        </div>
                        {/*end:Menu item*/}
                      </div>
                      {/*end:Menu sub*/}
                    </div>
                    {/*end:Menu item*/}
                  </div>
                  {/*end::Menu*/}
                </div>
                {/*end::Menu wrapper*/}
              </div>
              {/*end::Navbar*/}
              {/*begin::Toolbar wrapper*/}
              <div className="topbar d-flex align-items-stretch flex-shrink-0">
                {/*begin::Search*/}
                <div className="d-flex align-items-stretch ms-1 ms-lg-3">
                  {/*begin::Search*/}
                  <div
                    id="kt_header_search"
                    className="header-search d-flex align-items-stretch"
                    data-kt-search-keypress="true"
                    data-kt-search-min-length={2}
                    data-kt-search-enter="enter"
                    data-kt-search-layout="menu"
                    data-kt-menu-trigger="auto"
                    data-kt-menu-overflow="false"
                    data-kt-menu-permanent="true"
                    data-kt-menu-placement="bottom-end"
                  >
                    {/*begin::Search toggle*/}
                    <div
                      className="d-flex align-items-center"
                      data-kt-search-element="toggle"
                      id="kt_header_search_toggle"
                    >
                      <div className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
                        <i className="ki-duotone ki-magnifier fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                    </div>
                    {/*end::Search toggle*/}
                    {/*begin::Menu*/}
                    <div
                      data-kt-search-element="content"
                      className="menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px"
                    >
                      {/*begin::Wrapper*/}
                      <div data-kt-search-element="wrapper">
                        {/*begin::Form*/}
                        <form
                          data-kt-search-element="form"
                          className="w-100 position-relative mb-3"
                          autoComplete="off"
                        >
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-magnifier fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-0">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Input*/}
                          <input
                            type="text"
                            className="search-input form-control form-control-flush ps-10"
                            name="search"
                            defaultValue=""
                            placeholder="Search..."
                            data-kt-search-element="input"
                          />
                          {/*end::Input*/}
                          {/*begin::Spinner*/}
                          <span
                            className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1"
                            data-kt-search-element="spinner"
                          >
                            <span className="spinner-border h-15px w-15px align-middle text-gray-500" />
                          </span>
                          {/*end::Spinner*/}
                          {/*begin::Reset*/}
                          <span
                            className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none"
                            data-kt-search-element="clear"
                          >
                            <i className="ki-duotone ki-cross fs-2 fs-lg-1 me-0">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </span>
                          {/*end::Reset*/}
                          {/*begin::Toolbar*/}
                          <div
                            className="position-absolute top-50 end-0 translate-middle-y"
                            data-kt-search-element="toolbar"
                          >
                            {/*begin::Preferences toggle*/}
                            <div
                              data-kt-search-element="preferences-show"
                              className="btn btn-icon w-20px btn-sm btn-active-color-primary me-1"
                              data-bs-toggle="tooltip"
                              title="Show search preferences"
                            >
                              <i className="ki-duotone ki-setting-2 fs-2">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </div>
                            {/*end::Preferences toggle*/}
                            {/*begin::Advanced search toggle*/}
                            <div
                              data-kt-search-element="advanced-options-form-show"
                              className="btn btn-icon w-20px btn-sm btn-active-color-primary"
                              data-bs-toggle="tooltip"
                              title="Show more search options"
                            >
                              <i className="ki-duotone ki-down fs-2" />
                            </div>
                            {/*end::Advanced search toggle*/}
                          </div>
                          {/*end::Toolbar*/}
                        </form>
                        {/*end::Form*/}
                        {/*begin::Separator*/}
                        <div className="separator border-gray-200 mb-6" />
                        {/*end::Separator*/}
                        {/*begin::Recently viewed*/}
                        <div
                          data-kt-search-element="results"
                          className="d-none"
                        >
                          {/*begin::Items*/}
                          <div className="scroll-y mh-200px mh-lg-350px">
                            {/*begin::Category title*/}
                            <h3
                              className="fs-5 text-muted m-0 pb-5"
                              data-kt-search-element="category-title"
                            >
                              Users
                            </h3>
                            {/*end::Category title*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <img
                                  src="assets/media/avatars/300-6.jpg"
                                  alt=""
                                />
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Karina Clark
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Marketing Manager
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <img
                                  src="assets/media/avatars/300-2.jpg"
                                  alt=""
                                />
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Olivia Bold
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Software Engineer
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <img
                                  src="assets/media/avatars/300-9.jpg"
                                  alt=""
                                />
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Ana Clark
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  UI/UX Designer
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <img
                                  src="assets/media/avatars/300-14.jpg"
                                  alt=""
                                />
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Nick Pitola
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Art Director
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <img
                                  src="assets/media/avatars/300-11.jpg"
                                  alt=""
                                />
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Edward Kulnic
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  System Administrator
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Category title*/}
                            <h3
                              className="fs-5 text-muted m-0 pt-5 pb-5"
                              data-kt-search-element="category-title"
                            >
                              Customers
                            </h3>
                            {/*end::Category title*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <img
                                    className="w-20px h-20px"
                                    src="assets/media/svg/brand-logos/volicity-9.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Company Rbranding
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  UI Design
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <img
                                    className="w-20px h-20px"
                                    src="assets/media/svg/brand-logos/tvit.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Company Re-branding
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Web Development
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <img
                                    className="w-20px h-20px"
                                    src="assets/media/svg/misc/infography.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Business Analytics App
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Administration
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <img
                                    className="w-20px h-20px"
                                    src="assets/media/svg/brand-logos/leaf.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  EcoLeaf App Launch
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Marketing
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <img
                                    className="w-20px h-20px"
                                    src="assets/media/svg/brand-logos/tower.svg"
                                    alt=""
                                  />
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column justify-content-start fw-semibold">
                                <span className="fs-6 fw-semibold">
                                  Tower Group Website
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  Google Adwords
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Category title*/}
                            <h3
                              className="fs-5 text-muted m-0 pt-5 pb-5"
                              data-kt-search-element="category-title"
                            >
                              Projects
                            </h3>
                            {/*end::Category title*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-notepad fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                    <span className="path5" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <span className="fs-6 fw-semibold">
                                  Si-Fi Project by AU Themes
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  #45670
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-frame fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <span className="fs-6 fw-semibold">
                                  Shopix Mobile App Planning
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  #45690
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-message-text-2 fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <span className="fs-6 fw-semibold">
                                  Finance Monitoring SAAS Discussion
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  #21090
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <a
                              href="#"
                              className="d-flex text-gray-900 text-hover-primary align-items-center mb-5"
                            >
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-profile-circle fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <span className="fs-6 fw-semibold">
                                  Dashboard Analitics Launch
                                </span>
                                <span className="fs-7 fw-semibold text-muted">
                                  #34560
                                </span>
                              </div>
                              {/*end::Title*/}
                            </a>
                            {/*end::Item*/}
                          </div>
                          {/*end::Items*/}
                        </div>
                        {/*end::Recently viewed*/}
                        {/*begin::Recently viewed*/}
                        <div className="mb-5" data-kt-search-element="main">
                          {/*begin::Heading*/}
                          <div className="d-flex flex-stack fw-semibold mb-4">
                            {/*begin::Label*/}
                            <span className="text-muted fs-6 me-2">
                              Recently Searched:
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Heading*/}
                          {/*begin::Items*/}
                          <div className="scroll-y mh-200px mh-lg-325px">
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-laptop fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  BoomApp by Keenthemes
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #45789
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-chart-simple fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  "Kept API Project Meeting
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #84050
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-chart fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  "KPI Monitoring App Launch
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #84250
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-chart-line-down fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  Project Reference FAQ
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #67945
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-sms fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  "FitPro App Development
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #84250
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-bank fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  Shopix Mobile App
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #45690
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-5">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-40px me-4">
                                <span className="symbol-label bg-light">
                                  <i className="ki-duotone ki-chart-line-down fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-semibold"
                                >
                                  "Landing UI Design" Launch
                                </a>
                                <span className="fs-7 text-muted fw-semibold">
                                  #24005
                                </span>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Item*/}
                          </div>
                          {/*end::Items*/}
                        </div>
                        {/*end::Recently viewed*/}
                        {/*begin::Empty*/}
                        <div
                          data-kt-search-element="empty"
                          className="text-center d-none"
                        >
                          {/*begin::Icon*/}
                          <div className="pt-10 pb-10">
                            <i className="ki-duotone ki-search-list fs-4x opacity-50">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </div>
                          {/*end::Icon*/}
                          {/*begin::Message*/}
                          <div className="pb-15 fw-semibold">
                            <h3 className="text-gray-600 fs-5 mb-2">
                              No result found
                            </h3>
                            <div className="text-muted fs-7">
                              Please try again with a different query
                            </div>
                          </div>
                          {/*end::Message*/}
                        </div>
                        {/*end::Empty*/}
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Preferences*/}
                      <form
                        data-kt-search-element="advanced-options-form"
                        className="pt-1 d-none"
                      >
                        {/*begin::Heading*/}
                        <h3 className="fw-semibold text-gray-900 mb-7">
                          Advanced Search
                        </h3>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="mb-5">
                          <input
                            type="text"
                            className="form-control form-control-sm form-control-solid"
                            placeholder="Contains the word"
                            name="query"
                          />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-5">
                          {/*begin::Radio group*/}
                          <div className="nav-group nav-group-fluid">
                            {/*begin::Option*/}
                            <label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="type"
                                defaultValue="has"
                                defaultChecked="checked"
                              />
                              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                                All
                              </span>
                            </label>
                            {/*end::Option*/}
                            {/*begin::Option*/}
                            <label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="type"
                                defaultValue="users"
                              />
                              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                Users
                              </span>
                            </label>
                            {/*end::Option*/}
                            {/*begin::Option*/}
                            <label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="type"
                                defaultValue="orders"
                              />
                              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                Orders
                              </span>
                            </label>
                            {/*end::Option*/}
                            {/*begin::Option*/}
                            <label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="type"
                                defaultValue="projects"
                              />
                              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                Projects
                              </span>
                            </label>
                            {/*end::Option*/}
                          </div>
                          {/*end::Radio group*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-5">
                          <input
                            type="text"
                            name="assignedto"
                            className="form-control form-control-sm form-control-solid"
                            placeholder="Assigned to"
                            defaultValue=""
                          />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-5">
                          <input
                            type="text"
                            name="collaborators"
                            className="form-control form-control-sm form-control-solid"
                            placeholder="Collaborators"
                            defaultValue=""
                          />
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-5">
                          {/*begin::Radio group*/}
                          <div className="nav-group nav-group-fluid">
                            {/*begin::Option*/}
                            <label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="attachment"
                                defaultValue="has"
                                defaultChecked="checked"
                              />
                              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary">
                                Has attachment
                              </span>
                            </label>
                            {/*end::Option*/}
                            {/*begin::Option*/}
                            <label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="attachment"
                                defaultValue="any"
                              />
                              <span className="btn btn-sm btn-color-muted btn-active btn-active-primary px-4">
                                Any
                              </span>
                            </label>
                            {/*end::Option*/}
                          </div>
                          {/*end::Radio group*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-5">
                          <select
                            name="timezone"
                            aria-label="Select a Timezone"
                            data-control="select2"
                            data-dropdown-parent="#kt_header_search"
                            data-placeholder="date_period"
                            className="form-select form-select-sm form-select-solid"
                          >
                            <option defaultValue="next">Within the next</option>
                            <option defaultValue="last">Within the last</option>
                            <option defaultValue="between">Between</option>
                            <option defaultValue="on">On</option>
                          </select>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row mb-8">
                          {/*begin::Col*/}
                          <div className="col-6">
                            <input
                              type="number"
                              name="date_number"
                              className="form-control form-control-sm form-control-solid"
                              placeholder="Lenght"
                              defaultValue=""
                            />
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                          <div className="col-6">
                            <select
                              name="date_typer"
                              aria-label="Select a Timezone"
                              data-control="select2"
                              data-dropdown-parent="#kt_header_search"
                              data-placeholder="Period"
                              className="form-select form-select-sm form-select-solid"
                            >
                              <option defaultValue="days">Days</option>
                              <option defaultValue="weeks">Weeks</option>
                              <option defaultValue="months">Months</option>
                              <option defaultValue="years">Years</option>
                            </select>
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className="d-flex justify-content-end">
                          <button
                            type="reset"
                            className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                            data-kt-search-element="advanced-options-form-cancel"
                          >
                            Cancel
                          </button>
                          <a
                            href="utilities/search/horizontal.html"
                            className="btn btn-sm fw-bold btn-primary"
                            data-kt-search-element="advanced-options-form-search"
                          >
                            Search
                          </a>
                        </div>
                        {/*end::Actions*/}
                      </form>
                      {/*end::Preferences*/}
                      {/*begin::Preferences*/}
                      <form
                        data-kt-search-element="preferences"
                        className="pt-1 d-none"
                      >
                        {/*begin::Heading*/}
                        <h3 className="fw-semibold text-gray-900 mb-7">
                          Search Preferences
                        </h3>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="pb-4 border-bottom">
                          <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                              Projects
                            </span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                              defaultChecked="checked"
                            />
                          </label>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="py-4 border-bottom">
                          <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                              Targets
                            </span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                              defaultChecked="checked"
                            />
                          </label>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="py-4 border-bottom">
                          <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                              Affiliate Programs
                            </span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                            />
                          </label>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="py-4 border-bottom">
                          <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                              Referrals
                            </span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                              defaultChecked="checked"
                            />
                          </label>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="py-4 border-bottom">
                          <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                            <span className="form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2">
                              Users
                            </span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue={1}
                            />
                          </label>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Actions*/}
                        <div className="d-flex justify-content-end pt-7">
                          <button
                            type="reset"
                            className="btn btn-sm btn-light fw-bold btn-active-light-primary me-2"
                            data-kt-search-element="preferences-dismiss"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm fw-bold btn-primary"
                          >
                            Save Changes
                          </button>
                        </div>
                        {/*end::Actions*/}
                      </form>
                      {/*end::Preferences*/}
                    </div>
                    {/*end::Menu*/}
                  </div>
                  {/*end::Search*/}
                </div>
                {/*end::Search*/}
                {/*begin::Activities*/}
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  {/*begin::Drawer toggle*/}
                  <div
                    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                    id="kt_activities_toggle"
                  >
                    <i className="ki-duotone ki-chart-simple fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                    </i>
                  </div>
                  {/*end::Drawer toggle*/}
                </div>
                {/*end::Activities*/}
                {/*begin::Notifications*/}
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  {/*begin::Menu- wrapper*/}
                  <div
                    className="position-relative btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i className="ki-duotone ki-binance fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                    </i>
                  </div>
                  {/*begin::Menu*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                    data-kt-menu="true"
                    id="kt_menu_notifications"
                  >
                    {/*begin::Heading*/}
                    <div
                      className="d-flex flex-column bgi-no-repeat rounded-top"
                      style={{
                        backgroundImage:
                          'url("assets/media/misc/menu-header-bg.jpg")'
                      }}
                    >
                      {/*begin::Title*/}
                      <h3 className="text-white fw-semibold px-9 mt-10 mb-6">
                        Notifications
                        <span className="fs-8 opacity-75 ps-3">24 reports</span>
                      </h3>
                      {/*end::Title*/}
                      {/*begin::Tabs*/}
                      <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-semibold px-9">
                        <li className="nav-item">
                          <a
                            className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                            data-bs-toggle="tab"
                            href="#kt_topbar_notifications_1"
                          >
                            Alerts
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                            data-bs-toggle="tab"
                            href="#kt_topbar_notifications_2"
                          >
                            Updates
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                            data-bs-toggle="tab"
                            href="#kt_topbar_notifications_3"
                          >
                            Logs
                          </a>
                        </li>
                      </ul>
                      {/*end::Tabs*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Tab content*/}
                    <div className="tab-content">
                      {/*begin::Tab panel*/}
                      <div
                        className="tab-pane fade"
                        id="kt_topbar_notifications_1"
                        role="tabpanel"
                      >
                        {/*begin::Items*/}
                        <div className="scroll-y mh-325px my-5 px-8">
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-primary">
                                  <i className="ki-duotone ki-abstract-28 fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  Project Alice
                                </a>
                                <div className="text-gray-500 fs-7">
                                  Phase 1 development
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">1 hr</span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-danger">
                                  <i className="ki-duotone ki-information fs-2 text-danger">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  HR Confidential
                                </a>
                                <div className="text-gray-500 fs-7">
                                  Confidential staff documents
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              2 hrs
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-warning">
                                  <i className="ki-duotone ki-briefcase fs-2 text-warning">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  Company HR
                                </a>
                                <div className="text-gray-500 fs-7">
                                  Corporeate staff profiles
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              5 hrs
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-success">
                                  <i className="ki-duotone ki-abstract-12 fs-2 text-success">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  Project Redux
                                </a>
                                <div className="text-gray-500 fs-7">
                                  New frontend admin theme
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              2 days
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-primary">
                                  <i className="ki-duotone ki-colors-square fs-2 text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  Project Breafing
                                </a>
                                <div className="text-gray-500 fs-7">
                                  Product launch status update
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              21 Jan
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-info">
                                  <i className="ki-duotone ki-picture fs-2 text-info" />
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  Banner Assets
                                </a>
                                <div className="text-gray-500 fs-7">
                                  Collection of banner images
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              21 Jan
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center">
                              {/*begin::Symbol*/}
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-warning">
                                  <i className="ki-duotone ki-color-swatch fs-2 text-warning">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                    <span className="path4" />
                                    <span className="path5" />
                                    <span className="path6" />
                                    <span className="path7" />
                                    <span className="path8" />
                                    <span className="path9" />
                                    <span className="path10" />
                                    <span className="path11" />
                                    <span className="path12" />
                                    <span className="path13" />
                                    <span className="path14" />
                                    <span className="path15" />
                                    <span className="path16" />
                                    <span className="path17" />
                                    <span className="path18" />
                                    <span className="path19" />
                                    <span className="path20" />
                                    <span className="path21" />
                                  </i>
                                </span>
                              </div>
                              {/*end::Symbol*/}
                              {/*begin::Title*/}
                              <div className="mb-0 me-2">
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  Icon Assets
                                </a>
                                <div className="text-gray-500 fs-7">
                                  Collection of SVG icons
                                </div>
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              20 March
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                        {/*end::Items*/}
                        {/*begin::View more*/}
                        <div className="py-3 text-center border-top">
                          <a
                            href="pages/user-profile/activity.html"
                            className="btn btn-color-gray-600 btn-active-color-primary"
                          >
                            View All
                            <i className="ki-duotone ki-arrow-right fs-5">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </a>
                        </div>
                        {/*end::View more*/}
                      </div>
                      {/*end::Tab panel*/}
                      {/*begin::Tab panel*/}
                      <div
                        className="tab-pane fade show active"
                        id="kt_topbar_notifications_2"
                        role="tabpanel"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex flex-column px-9">
                          {/*begin::Section*/}
                          <div className="pt-10 pb-0">
                            {/*begin::Title*/}
                            <h3 className="text-gray-900 text-center fw-bold">
                              Get Pro Access
                            </h3>
                            {/*end::Title*/}
                            {/*begin::Text*/}
                            <div className="text-center text-gray-600 fw-semibold pt-1">
                              Outlines keep you honest. They stoping you from
                              amazing poorly about drive
                            </div>
                            {/*end::Text*/}
                            {/*begin::Action*/}
                            <div className="text-center mt-5 mb-9">
                              <a
                                href="#"
                                className="btn btn-sm btn-primary px-6"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_upgrade_plan"
                              >
                                Upgrade
                              </a>
                            </div>
                            {/*end::Action*/}
                          </div>
                          {/*end::Section*/}
                          {/*begin::Illustration*/}
                          <div className="text-center px-4">
                            <img
                              className="mw-100 mh-200px"
                              alt="image"
                              src="assets/media/illustrations/sigma-1/1.png"
                            />
                          </div>
                          {/*end::Illustration*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tab panel*/}
                      {/*begin::Tab panel*/}
                      <div
                        className="tab-pane fade"
                        id="kt_topbar_notifications_3"
                        role="tabpanel"
                      >
                        {/*begin::Items*/}
                        <div className="scroll-y mh-325px my-5 px-8">
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-success me-4">
                                200 OK
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                New order
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Just now
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-danger me-4">
                                500 ERR
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                New customer
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              2 hrs
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-success me-4">
                                200 OK
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Payment process
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              5 hrs
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-warning me-4">
                                300 WRN
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Search query
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              2 days
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-success me-4">
                                200 OK
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                API connection
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              1 week
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-success me-4">
                                200 OK
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Database restore
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Mar 5
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-warning me-4">
                                300 WRN
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                System update
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              May 15
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-warning me-4">
                                300 WRN
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Server OS update
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Apr 3
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-warning me-4">
                                300 WRN
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                API rollback
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Jun 30
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-danger me-4">
                                500 ERR
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Refund process
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Jul 10
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-danger me-4">
                                500 ERR
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Withdrawal process
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Sep 10
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-stack py-4">
                            {/*begin::Section*/}
                            <div className="d-flex align-items-center me-2">
                              {/*begin::Code*/}
                              <span className="w-70px badge badge-light-danger me-4">
                                500 ERR
                              </span>
                              {/*end::Code*/}
                              {/*begin::Title*/}
                              <a
                                href="#"
                                className="text-gray-800 text-hover-primary fw-semibold"
                              >
                                Mail tasks
                              </a>
                              {/*end::Title*/}
                            </div>
                            {/*end::Section*/}
                            {/*begin::Label*/}
                            <span className="badge badge-light fs-8">
                              Dec 10
                            </span>
                            {/*end::Label*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                        {/*end::Items*/}
                        {/*begin::View more*/}
                        <div className="py-3 text-center border-top">
                          <a
                            href="pages/user-profile/activity.html"
                            className="btn btn-color-gray-600 btn-active-color-primary"
                          >
                            View All
                            <i className="ki-duotone ki-arrow-right fs-5">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </a>
                        </div>
                        {/*end::View more*/}
                      </div>
                      {/*end::Tab panel*/}
                    </div>
                    {/*end::Tab content*/}
                  </div>
                  {/*end::Menu*/}
                  {/*end::Menu wrapper*/}
                </div>
                {/*end::Notifications*/}
                {/*begin::Chat*/}
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  {/*begin::Menu wrapper*/}
                  <div
                    className="position-relative btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                    id="kt_drawer_chat_toggle"
                  >
                    <i className="ki-duotone ki-message-text-2 fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                    <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
                  </div>
                  {/*end::Menu wrapper*/}
                </div>
                {/*end::Chat*/}
                {/*begin::Quick links*/}
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  {/*begin::Menu wrapper*/}
                  <div
                    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i className="ki-duotone ki-element-11 fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                    </i>
                  </div>
                  {/*begin::Menu*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px"
                    data-kt-menu="true"
                  >
                    {/*begin::Heading*/}
                    <div
                      className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10"
                      style={{
                        backgroundImage:
                          'url("assets/media/misc/menu-header-bg.jpg")'
                      }}
                    >
                      {/*begin::Title*/}
                      <h3 className="text-white fw-semibold mb-3">
                        Quick Links
                      </h3>
                      {/*end::Title*/}
                      {/*begin::Status*/}
                      <span className="badge bg-primary text-inverse-primary py-2 px-3">
                        25 pending tasks
                      </span>
                      {/*end::Status*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin:Nav*/}
                    <div className="row g-0">
                      {/*begin:Item*/}
                      <div className="col-6">
                        <a
                          href="apps/projects/budget.html"
                          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
                        >
                          <i className="ki-duotone ki-dollar fs-3x text-primary mb-2">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                          <span className="fs-5 fw-semibold text-gray-800 mb-0">
                            Accounting
                          </span>
                          <span className="fs-7 text-gray-500">eCommerce</span>
                        </a>
                      </div>
                      {/*end:Item*/}
                      {/*begin:Item*/}
                      <div className="col-6">
                        <a
                          href="apps/projects/settings.html"
                          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
                        >
                          <i className="ki-duotone ki-sms fs-3x text-primary mb-2">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          <span className="fs-5 fw-semibold text-gray-800 mb-0">
                            Administration
                          </span>
                          <span className="fs-7 text-gray-500">Console</span>
                        </a>
                      </div>
                      {/*end:Item*/}
                      {/*begin:Item*/}
                      <div className="col-6">
                        <a
                          href="apps/projects/list.html"
                          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end"
                        >
                          <i className="ki-duotone ki-abstract-41 fs-3x text-primary mb-2">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          <span className="fs-5 fw-semibold text-gray-800 mb-0">
                            Projects
                          </span>
                          <span className="fs-7 text-gray-500">
                            Pending Tasks
                          </span>
                        </a>
                      </div>
                      {/*end:Item*/}
                      {/*begin:Item*/}
                      <div className="col-6">
                        <a
                          href="apps/projects/users.html"
                          className="d-flex flex-column flex-center h-100 p-6 bg-hover-light"
                        >
                          <i className="ki-duotone ki-briefcase fs-3x text-primary mb-2">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          <span className="fs-5 fw-semibold text-gray-800 mb-0">
                            Customers
                          </span>
                          <span className="fs-7 text-gray-500">
                            Latest cases
                          </span>
                        </a>
                      </div>
                      {/*end:Item*/}
                    </div>
                    {/*end:Nav*/}
                    {/*begin::View more*/}
                    <div className="py-2 text-center border-top">
                      <a
                        href="pages/user-profile/activity.html"
                        className="btn btn-color-gray-600 btn-active-color-primary"
                      >
                        View All
                        <i className="ki-duotone ki-arrow-right fs-5">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </a>
                    </div>
                    {/*end::View more*/}
                  </div>
                  {/*end::Menu*/}
                  {/*end::Menu wrapper*/}
                </div>
                {/*end::Quick links*/}
                {/*begin::Theme mode*/}
                <div className="d-flex align-items-center ms-1 ms-lg-3">
                  {/*begin::Menu toggle*/}
                  <a
                    href="#"
                    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                    data-kt-menu-trigger="{default:'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <i className="ki-duotone ki-night-day theme-light-show fs-1">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                      <span className="path7" />
                      <span className="path8" />
                      <span className="path9" />
                      <span className="path10" />
                    </i>
                    <i className="ki-duotone ki-moon theme-dark-show fs-1">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                  </a>
                  {/*begin::Menu toggle*/}
                  {/*begin::Menu*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
                    data-kt-menu="true"
                    data-kt-element="theme-mode-menu"
                  >
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-defaultvalue="light"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-night-day fs-2">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                            <span className="path7" />
                            <span className="path8" />
                            <span className="path9" />
                            <span className="path10" />
                          </i>
                        </span>
                        <span className="menu-title">Light</span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-defaultvalue="dark"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-moon fs-2">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </span>
                        <span className="menu-title">Dark</span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3 my-0">
                      <a
                        href="#"
                        className="menu-link px-3 py-2"
                        data-kt-element="mode"
                        data-kt-defaultvalue="system"
                      >
                        <span className="menu-icon" data-kt-element="icon">
                          <i className="ki-duotone ki-screen fs-2">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </i>
                        </span>
                        <span className="menu-title">System</span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu*/}
                </div>
                {/*end::Theme mode*/}
                {/*begin::User*/}
                <div
                  className="d-flex align-items-center me-lg-n2 ms-1 ms-lg-3"
                  id="kt_header_user_menu_toggle"
                >
                  {/*begin::Menu wrapper*/}
                  <div
                    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                  >
                    <img
                      className="h-30px w-30px rounded"
                      src="assets/media/avatars/300-2.jpg"
                      alt=""
                    />
                  </div>
                  {/*begin::User account menu*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                    data-kt-menu="true"
                  >
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <div className="menu-content d-flex align-items-center px-3">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-50px me-5">
                          <img
                            alt="Logo"
                            src="assets/media/avatars/300-2.jpg"
                          />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Username*/}
                        <div className="d-flex flex-column">
                          <div className="fw-bold d-flex align-items-center fs-5">
                            Max Smith
                            <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                              Pro
                            </span>
                          </div>
                          <a
                            href="#"
                            className="fw-semibold text-muted text-hover-primary fs-7"
                          >
                            max@kt.com
                          </a>
                        </div>
                        {/*end::Username*/}
                      </div>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu separator*/}
                    <div className="separator my-2" />
                    {/*end::Menu separator*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-5">
                      <a
                        href="account/overview.html"
                        className="menu-link px-5"
                      >
                        My Profile
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-5">
                      <a
                        href="apps/projects/list.html"
                        className="menu-link px-5"
                      >
                        <span className="menu-text">My Projects</span>
                        <span className="menu-badge">
                          <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                            3
                          </span>
                        </span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div
                      className="menu-item px-5"
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="left-start"
                      data-kt-menu-offset="-15px, 0"
                    >
                      <a href="#" className="menu-link px-5">
                        <span className="menu-title">My Subscription</span>
                        <span className="menu-arrow" />
                      </a>
                      {/*begin::Menu sub*/}
                      <div className="menu-sub menu-sub-dropdown w-175px py-4">
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/referrals.html"
                            className="menu-link px-5"
                          >
                            Referrals
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/billing.html"
                            className="menu-link px-5"
                          >
                            Billing
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/statements.html"
                            className="menu-link px-5"
                          >
                            Payments
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/statements.html"
                            className="menu-link d-flex flex-stack px-5"
                          >
                            Statements
                            <span
                              className="ms-2 lh-0"
                              data-bs-toggle="tooltip"
                              title="View your statements"
                            >
                              <i className="ki-duotone ki-information-5 fs-5">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </i>
                            </span>
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator my-2" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content px-3">
                            <label className="form-check form-switch form-check-custom form-check-solid">
                              <input
                                className="form-check-input w-30px h-20px"
                                type="checkbox"
                                defaultValue={1}
                                defaultChecked="checked"
                                name="notifications"
                              />
                              <span className="form-check-label text-muted fs-7">
                                Notifications
                              </span>
                            </label>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu sub*/}
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-5">
                      <a
                        href="account/statements.html"
                        className="menu-link px-5"
                      >
                        My Statements
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu separator*/}
                    <div className="separator my-2" />
                    {/*end::Menu separator*/}
                    {/*begin::Menu item*/}
                    <div
                      className="menu-item px-5"
                      data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                      data-kt-menu-placement="left-start"
                      data-kt-menu-offset="-15px, 0"
                    >
                      <a href="#" className="menu-link px-5">
                        <span className="menu-title position-relative">
                          Language
                          <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                            English
                            <img
                              className="w-15px h-15px rounded-1 ms-2"
                              src="assets/media/flags/united-states.svg"
                              alt=""
                            />
                          </span>
                        </span>
                      </a>
                      {/*begin::Menu sub*/}
                      <div className="menu-sub menu-sub-dropdown w-175px py-4">
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/settings.html"
                            className="menu-link d-flex px-5 active"
                          >
                            <span className="symbol symbol-20px me-4">
                              <img
                                className="rounded-1"
                                src="assets/media/flags/united-states.svg"
                                alt=""
                              />
                            </span>
                            English
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/settings.html"
                            className="menu-link d-flex px-5"
                          >
                            <span className="symbol symbol-20px me-4">
                              <img
                                className="rounded-1"
                                src="assets/media/flags/spain.svg"
                                alt=""
                              />
                            </span>
                            Spanish
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/settings.html"
                            className="menu-link d-flex px-5"
                          >
                            <span className="symbol symbol-20px me-4">
                              <img
                                className="rounded-1"
                                src="assets/media/flags/germany.svg"
                                alt=""
                              />
                            </span>
                            German
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/settings.html"
                            className="menu-link d-flex px-5"
                          >
                            <span className="symbol symbol-20px me-4">
                              <img
                                className="rounded-1"
                                src="assets/media/flags/japan.svg"
                                alt=""
                              />
                            </span>
                            Japanese
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a
                            href="account/settings.html"
                            className="menu-link d-flex px-5"
                          >
                            <span className="symbol symbol-20px me-4">
                              <img
                                className="rounded-1"
                                src="assets/media/flags/france.svg"
                                alt=""
                              />
                            </span>
                            French
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu sub*/}
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-5 my-1">
                      <a
                        href="account/settings.html"
                        className="menu-link px-5"
                      >
                        Account Settings
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-5">
                      <a
                        href="authentication/layouts/corporate/sign-in.html"
                        className="menu-link px-5"
                      >
                        Sign Out
                      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::User account menu*/}
                  {/*end::Menu wrapper*/}
                </div>
                {/*end::User */}
                {/*begin::Aside mobile toggle*/}
                {/*end::Aside mobile toggle*/}
              </div>
              {/*end::Toolbar wrapper*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Header*/}
        {/*begin::Toolbar*/}
        <div className="toolbar py-5 pb-lg-15" id="kt_toolbar">
          {/*begin::Container*/}
          <div
            id="kt_toolbar_container"
            className="container-xxl d-flex flex-stack flex-wrap"
          >
            {/*begin::Page title*/}
            <div className="page-title d-flex flex-column me-3">
              {/*begin::Title*/}
              <h1 className="d-flex text-white fw-bold my-1 fs-3">
                Projects Dashboard
              </h1>
              {/*end::Title*/}
              {/*begin::Breadcrumb*/}
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                  <a
                    href="/"
                    className="text-white text-hover-primary"
                  >
                    Home
                  </a>
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                  <span className="bullet bg-white opacity-75 w-5px h-2px" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                  Dashboards
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item">
                  <span className="bullet bg-white opacity-75 w-5px h-2px" />
                </li>
                {/*end::Item*/}
                {/*begin::Item*/}
                <li className="breadcrumb-item text-white opacity-75">
                  Projects
                </li>
                {/*end::Item*/}
              </ul>
              {/*end::Breadcrumb*/}
            </div>
            {/*end::Page title*/}
            {/*begin::Actions*/}
            <div className="d-flex align-items-center py-3 py-md-1">
              {/*begin::Wrapper*/}
              <div className="me-4">
                {/*begin::Menu*/}
                <a
                  href="#"
                  className="btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-white"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i className="ki-duotone ki-filter fs-5 me-1">
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                  Filter
                </a>
                {/*begin::Menu 1*/}
                <div
                  className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_660638a17636a"
                >
                  {/*begin::Header*/}
                  <div className="px-7 py-5">
                    <div className="fs-5 text-gray-900 fw-bold">
                      Filter Options
                    </div>
                  </div>
                  {/*end::Header*/}
                  {/*begin::Menu separator*/}
                  <div className="separator border-gray-200" />
                  {/*end::Menu separator*/}
                  {/*begin::Form*/}
                  <div className="px-7 py-5">
                    {/*begin::Input group*/}
                    <div className="mb-10">
                      {/*begin::Label*/}
                      <label className="form-label fw-semibold">Status:</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <div>
                        <select
                          className="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_660638a17636a"
                          data-allow-clear="true"
                        >
                          <option />
                          <option defaultValue={1}>Approved</option>
                          <option defaultValue={2}>Pending</option>
                          <option defaultValue={2}>In Process</option>
                          <option defaultValue={2}>Rejected</option>
                        </select>
                      </div>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-10">
                      {/*begin::Label*/}
                      <label className="form-label fw-semibold">
                        Member Type:
                      </label>
                      {/*end::Label*/}
                      {/*begin::Options*/}
                      <div className="d-flex">
                        {/*begin::Options*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                          />
                          <span className="form-check-label">Author</span>
                        </label>
                        {/*end::Options*/}
                        {/*begin::Options*/}
                        <label className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={2}
                            defaultChecked="checked"
                          />
                          <span className="form-check-label">Customer</span>
                        </label>
                        {/*end::Options*/}
                      </div>
                      {/*end::Options*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-10">
                      {/*begin::Label*/}
                      <label className="form-label fw-semibold">
                        Notifications:
                      </label>
                      {/*end::Label*/}
                      {/*begin::Switch*/}
                      <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          name="notifications"
                          defaultChecked="checked"
                        />
                        <label className="form-check-label">Enabled</label>
                      </div>
                      {/*end::Switch*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex justify-content-end">
                      <button
                        type="reset"
                        className="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Form*/}
                </div>
                {/*end::Menu 1*/}
                {/*end::Menu*/}
              </div>
              {/*end::Wrapper*/}
              {/*begin::Button*/}
              <a
                href="#"
                data-bs-theme="light"
                className="btn bg-body btn-active-color-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_app"
                id="kt_toolbar_primary_button"
              >
                Create
              </a>
              {/*end::Button*/}
            </div>
            {/*end::Actions*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Container*/}
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          {/*begin::Post*/}
          <div className="content flex-row-fluid" id="kt_content">
            {/*begin::Row*/}
            <div className="row gx-5 gx-xl-10 mb-xl-10">
              {/*begin::Col*/}
              <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-10">
                {/*begin::Card widget 16*/}
                <div
                  className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-center border-0 h-md-50 mb-5 mb-xl-10"
                  style={{ backgroundColor: "#080655" }}
                >
                  {/*begin::Header*/}
                  <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <div className="card-title d-flex flex-column">
                      {/*begin::Amount*/}
                      <span className="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">
                        69
                      </span>
                      {/*end::Amount*/}
                      {/*begin::Subtitle*/}
                      <span className="text-white opacity-50 pt-1 fw-semibold fs-6">
                        Active Projects
                      </span>
                      {/*end::Subtitle*/}
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body d-flex align-items-end pt-0">
                    {/*begin::Progress*/}
                    <div className="d-flex align-items-center flex-column mt-3 w-100">
                      <div className="d-flex justify-content-between fw-bold fs-6 text-white opacity-50 w-100 mt-auto mb-2">
                        <span>43 Pending</span>
                        <span>72%</span>
                      </div>
                      <div className="h-8px mx-3 w-100 bg-light-danger rounded">
                        <div
                          // className="bg-danger rounded h-8px"
                          // role="progressbar"
                          // style={{ width: "72%" }}
                          // aria-defaultValuenow={50}
                          // aria-defaultValuemin={0}
                          // aria-defaultValuemax={100}
                        />
                      </div>
                    </div>
                    {/*end::Progress*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card widget 16*/}
                {/*begin::Card widget 7*/}
                <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                  {/*begin::Header*/}
                  <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <div className="card-title d-flex flex-column">
                      {/*begin::Amount*/}
                      <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
                        357
                      </span>
                      {/*end::Amount*/}
                      {/*begin::Subtitle*/}
                      <span className="text-gray-500 pt-1 fw-semibold fs-6">
                        Professionals
                      </span>
                      {/*end::Subtitle*/}
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body d-flex flex-column justify-content-end pe-0">
                    {/*begin::Title*/}
                    <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">
                      Today’s Heroes
                    </span>
                    {/*end::Title*/}
                    {/*begin::Users group*/}
                    <div className="symbol-group symbol-hover flex-nowrap">
                      <div
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="tooltip"
                        title="Alan Warden"
                      >
                        <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                          A
                        </span>
                      </div>
                      <div
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="tooltip"
                        title="Michael Eberon"
                      >
                        <img alt="Pic" src="assets/media/avatars/300-11.jpg" />
                      </div>
                      <div
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="tooltip"
                        title="Susan Redwood"
                      >
                        <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                          S
                        </span>
                      </div>
                      <div
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="tooltip"
                        title="Melody Macy"
                      >
                        <img alt="Pic" src="assets/media/avatars/300-2.jpg" />
                      </div>
                      <div
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="tooltip"
                        title="Perry Matthew"
                      >
                        <span className="symbol-label bg-danger text-inverse-danger fw-bold">
                          P
                        </span>
                      </div>
                      <div
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="tooltip"
                        title="Barry Walter"
                      >
                        <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                      </div>
                      <a
                        href="#"
                        className="symbol symbol-35px symbol-circle"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_view_users"
                      >
                        <span className="symbol-label bg-dark text-gray-300 fs-8 fw-bold">
                          +42
                        </span>
                      </a>
                    </div>
                    {/*end::Users group*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card widget 7*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-10">
                {/*begin::Card widget 17*/}
                <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                  {/*begin::Header*/}
                  <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <div className="card-title d-flex flex-column">
                      {/*begin::Info*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Currency*/}
                        <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">
                          $
                        </span>
                        {/*end::Currency*/}
                        {/*begin::Amount*/}
                        <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">
                          69,700
                        </span>
                        {/*end::Amount*/}
                        {/*begin::Badge*/}
                        <span className="badge badge-light-success fs-base">
                          <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          2.2%
                        </span>
                        {/*end::Badge*/}
                      </div>
                      {/*end::Info*/}
                      {/*begin::Subtitle*/}
                      <span className="text-gray-500 pt-1 fw-semibold fs-6">
                        Projects Earnings in April
                      </span>
                      {/*end::Subtitle*/}
                    </div>
                    {/*end::Title*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Card body*/}
                  <div className="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
                    {/*begin::Chart*/}
                    <div className="d-flex flex-center me-5 pt-2">
                      <div
                        id="kt_card_widget_17_chart"
                        style={{ minWidth: 70, minHeight: 70 }}
                        data-kt-size={70}
                        data-kt-line={11}
                      />
                    </div>
                    {/*end::Chart*/}
                    {/*begin::Labels*/}
                    <div className="d-flex flex-column content-justify-center flex-row-fluid">
                      {/*begin::Label*/}
                      <div className="d-flex fw-semibold align-items-center">
                        {/*begin::Bullet*/}
                        <div className="bullet w-8px h-3px rounded-2 bg-success me-3" />
                        {/*end::Bullet*/}
                        {/*begin::Label*/}
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Leaf CRM
                        </div>
                        {/*end::Label*/}
                        {/*begin::Stats*/}
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $7,660
                        </div>
                        {/*end::Stats*/}
                      </div>
                      {/*end::Label*/}
                      {/*begin::Label*/}
                      <div className="d-flex fw-semibold align-items-center my-3">
                        {/*begin::Bullet*/}
                        <div className="bullet w-8px h-3px rounded-2 bg-primary me-3" />
                        {/*end::Bullet*/}
                        {/*begin::Label*/}
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Mivy App
                        </div>
                        {/*end::Label*/}
                        {/*begin::Stats*/}
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $2,820
                        </div>
                        {/*end::Stats*/}
                      </div>
                      {/*end::Label*/}
                      {/*begin::Label*/}
                      <div className="d-flex fw-semibold align-items-center">
                        {/*begin::Bullet*/}
                        <div
                          className="bullet w-8px h-3px rounded-2 me-3"
                          style={{ backgroundColor: "#E4E6EF" }}
                        />
                        {/*end::Bullet*/}
                        {/*begin::Label*/}
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Others
                        </div>
                        {/*end::Label*/}
                        {/*begin::Stats*/}
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $45,257
                        </div>
                        {/*end::Stats*/}
                      </div>
                      {/*end::Label*/}
                    </div>
                    {/*end::Labels*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Card widget 17*/}
                {/*begin::List widget 25*/}
                <div className="card card-flush h-lg-50">
                  {/*begin::Header*/}
                  <div className="card-header pt-5">
                    {/*begin::Title*/}
                    <h3 className="card-title text-gray-800">Highlights</h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar d-none">
                      {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                      <div
                        data-kt-daterangepicker="true"
                        data-kt-daterangepicker-opens="left"
                        className="btn btn-sm btn-light d-flex align-items-center px-4"
                      >
                        {/*begin::Display range*/}
                        <div className="text-gray-600 fw-bold">
                          Loading date range...
                        </div>
                        {/*end::Display range*/}
                        <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                          <span className="path6" />
                        </i>
                      </div>
                      {/*end::Daterangepicker*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-5">
                    {/*begin::Item*/}
                    <div className="d-flex flex-stack">
                      {/*begin::Section*/}
                      <div className="text-gray-700 fw-semibold fs-6 me-2">
                        Avg. Client Rating
                      </div>
                      {/*end::Section*/}
                      {/*begin::Statistics*/}
                      <div className="d-flex align-items-senter">
                        <i className="ki-duotone ki-arrow-up-right fs-2 text-success me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        {/*begin::Number*/}
                        <span className="text-gray-900 fw-bolder fs-6">
                          7.8
                        </span>
                        {/*end::Number*/}
                        <span className="text-gray-500 fw-bold fs-6">/10</span>
                      </div>
                      {/*end::Statistics*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Separator*/}
                    <div className="separator separator-dashed my-3" />
                    {/*end::Separator*/}
                    {/*begin::Item*/}
                    <div className="d-flex flex-stack">
                      {/*begin::Section*/}
                      <div className="text-gray-700 fw-semibold fs-6 me-2">
                        Avg. Quotes
                      </div>
                      {/*end::Section*/}
                      {/*begin::Statistics*/}
                      <div className="d-flex align-items-senter">
                        <i className="ki-duotone ki-arrow-down-right fs-2 text-danger me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        {/*begin::Number*/}
                        <span className="text-gray-900 fw-bolder fs-6">
                          730
                        </span>
                        {/*end::Number*/}
                      </div>
                      {/*end::Statistics*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Separator*/}
                    <div className="separator separator-dashed my-3" />
                    {/*end::Separator*/}
                    {/*begin::Item*/}
                    <div className="d-flex flex-stack">
                      {/*begin::Section*/}
                      <div className="text-gray-700 fw-semibold fs-6 me-2">
                        Avg. Agent Earnings
                      </div>
                      {/*end::Section*/}
                      {/*begin::Statistics*/}
                      <div className="d-flex align-items-senter">
                        <i className="ki-duotone ki-arrow-up-right fs-2 text-success me-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        {/*begin::Number*/}
                        <span className="text-gray-900 fw-bolder fs-6">
                          $2,309
                        </span>
                        {/*end::Number*/}
                      </div>
                      {/*end::Statistics*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::LIst widget 25*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-12 col-xl-12 col-xxl-6 mb-10 mb-xl-0">
                {/*begin::Timeline widget 3*/}
                <div className="card h-md-100">
                  {/*begin::Header*/}
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-900">
                        What’s up Today
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Total 424,567 deliveries
                      </span>
                    </h3>
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      <a href="#" className="btn btn-sm btn-light">
                        Report Cecnter
                      </a>
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-7 px-0">
                    {/*begin::Nav*/}
                    <ul className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5">
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_1"
                        >
                          <span className="fs-7 fw-semibold">Fr</span>
                          <span className="fs-6 fw-bold">20</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_2"
                        >
                          <span className="fs-7 fw-semibold">Sa</span>
                          <span className="fs-6 fw-bold">21</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_3"
                        >
                          <span className="fs-7 fw-semibold">Su</span>
                          <span className="fs-6 fw-bold">22</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger active"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_4"
                        >
                          <span className="fs-7 fw-semibold">Tu</span>
                          <span className="fs-6 fw-bold">23</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_5"
                        >
                          <span className="fs-7 fw-semibold">Tu</span>
                          <span className="fs-6 fw-bold">24</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_6"
                        >
                          <span className="fs-7 fw-semibold">We</span>
                          <span className="fs-6 fw-bold">25</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_7"
                        >
                          <span className="fs-7 fw-semibold">Th</span>
                          <span className="fs-6 fw-bold">26</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_8"
                        >
                          <span className="fs-7 fw-semibold">Fri</span>
                          <span className="fs-6 fw-bold">27</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_9"
                        >
                          <span className="fs-7 fw-semibold">Sa</span>
                          <span className="fs-6 fw-bold">28</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_10"
                        >
                          <span className="fs-7 fw-semibold">Su</span>
                          <span className="fs-6 fw-bold">29</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                      {/*begin::Nav item*/}
                      <li className="nav-item p-0 ms-0">
                        {/*begin::Date*/}
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_timeline_widget_3_tab_content_11"
                        >
                          <span className="fs-7 fw-semibold">Mo</span>
                          <span className="fs-6 fw-bold">30</span>
                        </a>
                        {/*end::Date*/}
                      </li>
                      {/*end::Nav item*/}
                    </ul>
                    {/*end::Nav*/}
                    {/*begin::Tab Content (ishlamayabdi)*/}
                    <div className="tab-content mb-2 px-9">
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_1"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_2"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_3"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade show active"
                        id="kt_timeline_widget_3_tab_content_4"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_5"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_6"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_7"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_8"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_9"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_10"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_timeline_widget_3_tab_content_11"
                      >
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              16:30 - 17:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                PM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Dashboard UI/UX Design Review
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Mark Morris
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              10:20 - 11:00
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              Marketing Campaign Discussion
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Peter Marcus
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Wrapper*/}
                        <div className="d-flex align-items-center mb-6">
                          {/*begin::Bullet*/}
                          <span
                            data-kt-element="bullet"
                            className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                          />
                          {/*end::Bullet*/}
                          {/*begin::Info*/}
                          <div className="flex-grow-1 me-5">
                            {/*begin::Time*/}
                            <div className="text-gray-800 fw-semibold fs-2">
                              12:00 - 13:40
                              <span className="text-gray-500 fw-semibold fs-7">
                                AM
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Description*/}
                            <div className="text-gray-700 fw-semibold fs-6">
                              9 Degree Project Estimation Meeting
                            </div>
                            {/*end::Description*/}
                            {/*begin::Link*/}
                            <div className="text-gray-500 fw-semibold fs-7">
                              Lead by
                              {/*begin::Name*/}
                              <a
                                href="#"
                                className="text-primary opacity-75-hover fw-semibold"
                              >
                                Lead by Bob
                              </a>
                              {/*end::Name*/}
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-sm btn-light"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_project"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Tap pane*/}
                    </div>
                    {/*end::Tab Content*/}
                    {/*begin::Action*/}
                    <div className="float-end d-none">
                      <a
                        href="#"
                        className="btn btn-sm btn-light me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_create_project"
                      >
                        Add Lesson
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_create_app"
                      >
                        Call Sick for Today
                      </a>
                    </div>
                    {/*end::Action*/}
                  </div>
                  {/*end: Card Body*/}
                </div>
                {/*end::Timeline widget 3*/}
                {/*begin::Timeline widget 3*/}
                <div className="card card-flush d-none h-md-100">
                  {/*begin::Card header*/}
                  <div className="card-header mt-6">
                    {/*begin::Card title*/}
                    <div className="card-title flex-column">
                      <h3 className="fw-bold mb-1">What's on the road?</h3>
                      <div className="fs-6 text-gray-500">
                        Total 482 participants
                      </div>
                    </div>
                    {/*end::Card title*/}
                    {/*begin::Card toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Select*/}
                      <select
                        name="status"
                        data-control="select2"
                        data-hide-search="true"
                        className="form-select form-select-solid form-select-sm fw-bold w-100px"
                      >
                        <option defaultValue={1} selected="selected">
                          Options
                        </option>
                        <option defaultValue={2}>Option 1</option>
                        <option defaultValue={3}>Option 2</option>
                        <option defaultValue={4}>Option 3</option>
                      </select>
                      {/*end::Select*/}
                    </div>
                    {/*end::Card toolbar*/}
                  </div>
                  {/*end::Card header*/}
                  {/*begin::Card body*/}
                  <div className="card-body p-0">
                    {/*begin::Dates*/}
                    <ul className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2 ms-4">
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_0"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Fr
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">20</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_1"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Sa
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">21</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_2"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Su
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">22</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger active"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_3"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Mo
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">23</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_4"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Tu
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">24</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_5"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            We
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">25</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_6"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Th
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">26</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_7"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Fr
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">27</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_8"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Sa
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">28</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_9"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Su
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">29</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_10"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Mo
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">30</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                      {/*begin::Date*/}
                      <li className="nav-item me-1">
                        <a
                          className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-color-active-white btn-active-danger"
                          data-bs-toggle="tab"
                          href="#kt_schedule_day_11"
                        >
                          <span className="text-gray-500 fs-7 fw-semibold">
                            Tu
                          </span>
                          <span className="fs-6 text-gray-800 fw-bold">31</span>
                        </a>
                      </li>
                      {/*end::Date*/}
                    </ul>
                    {/*end::Dates*/}
                    {/*begin::Tab Content*/}
                    <div className="tab-content px-9">
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_0"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              14:30 - 15:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              9 Degree Project Estimation Meeting
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Caleb Donaldson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              9 Degree Project Estimation Meeting
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Walter White</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              14:30 - 15:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Team Backlog Grooming Session
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Mark Randall</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_1"
                        className="tab-pane fade show active"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              14:30 - 15:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Weekly Team Stand-Up
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Terry Robins</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              16:30 - 17:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Lunch &amp; Learn Catch Up
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Walter White</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Project Review &amp; Testing
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Sean Bean</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_2"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              16:30 - 17:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Development Team Capacity Review
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Michael Walters</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Marketing Campaign Discussion
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Michael Walters</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Lunch &amp; Learn Catch Up
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Peter Marcus</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_3"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Committee Review Approvals
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Sean Bean</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Committee Review Approvals
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Yannis Gloverson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              10:00 - 11:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Project Review &amp; Testing
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Terry Robins</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_4"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              13:00 - 14:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Sales Pitch Proposal
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Karina Clarke</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              9:00 - 10:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Weekly Team Stand-Up
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Mark Randall</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              13:00 - 14:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              9 Degree Project Estimation Meeting
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Sean Bean</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_5"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              16:30 - 17:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Marketing Campaign Discussion
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Walter White</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              13:00 - 14:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Marketing Campaign Discussion
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Michael Walters</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              10:00 - 11:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Creative Content Initiative
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Yannis Gloverson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_6"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Marketing Campaign Discussion
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Naomi Hayabusa</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Project Review &amp; Testing
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Terry Robins</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Team Backlog Grooming Session
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Walter White</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_7"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              9:00 - 10:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Lunch &amp; Learn Catch Up
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Naomi Hayabusa</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              14:30 - 15:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Committee Review Approvals
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Yannis Gloverson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Sales Pitch Proposal
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Terry Robins</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_8"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              14:30 - 15:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Lunch &amp; Learn Catch Up
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Terry Robins</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              13:00 - 14:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Project Review &amp; Testing
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">David Stevenson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Development Team Capacity Review
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Naomi Hayabusa</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_9"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Dashboard UI/UX Design Review
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Peter Marcus</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              16:30 - 17:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Dashboard UI/UX Design Review
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Walter White</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              9 Degree Project Estimation Meeting
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Caleb Donaldson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_10"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              12:00 - 13:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Project Review &amp; Testing
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Peter Marcus</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              13:00 - 14:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Development Team Capacity Review
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Peter Marcus</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              14:30 - 15:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Team Backlog Grooming Session
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Bob Harris</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                      {/*begin::Day*/}
                      <div
                        id="kt_schedule_day_11"
                        className="tab-pane fade show"
                      >
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              16:30 - 17:30
                              <span className="fs-7 text-gray-500 text-uppercase">
                                pm
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Sales Pitch Proposal
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Yannis Gloverson</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              11:00 - 11:45
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              Marketing Campaign Discussion
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Mark Randall</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                        {/*begin::Time*/}
                        <div className="d-flex flex-stack position-relative mt-8">
                          {/*begin::Bar*/}
                          <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                          {/*end::Bar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold ms-5 text-gray-600">
                            {/*begin::Time*/}
                            <div className="fs-5">
                              9:00 - 10:00
                              <span className="fs-7 text-gray-500 text-uppercase">
                                am
                              </span>
                            </div>
                            {/*end::Time*/}
                            {/*begin::Title*/}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-800 text-hover-primary mb-2"
                            >
                              9 Degree Project Estimation Meeting
                            </a>
                            {/*end::Title*/}
                            {/*begin::User*/}
                            <div className="text-gray-500">
                              Lead by
                              <a href="#">Karina Clarke</a>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Info*/}
                          {/*begin::Action*/}
                          <a
                            href="#"
                            className="btn btn-bg-light btn-active-color-primary btn-sm"
                          >
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Time*/}
                      </div>
                      {/*end::Day*/}
                    </div>
                    {/*end::Tab Content*/}
                  </div>
                  {/*end::Card body*/}
                </div>
                {/*end::Timeline widget-3*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
              {/*begin::Col*/}
              <div className="col-xxl-6">
                {/*begin::Card widget 18*/}
                <div className="card card-flush h-md-100">
                  {/*begin::Body*/}
                  <div className="card-body py-9">
                    {/*begin::Row*/}
                    <div className="row gx-9 h-100">
                      {/*begin::Col*/}
                      <div className="col-sm-6 mb-10 mb-sm-0">
                        {/*begin::Image*/}
                        <div
                          className="bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-400px min-h-sm-100 h-100"
                          style={{
                            backgroundSize: "100% 100%",
                            backgroundImage:
                              'url("assets/media/stock/600x600/img-33.jpg")'
                          }}
                        />
                        {/*end::Image*/}
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-sm-6">
                        {/*begin::Wrapper*/}
                        <div className="d-flex flex-column h-100">
                          {/*begin::Header*/}
                          <div className="mb-7">
                            {/*begin::Headin*/}
                            <div className="d-flex flex-stack mb-6">
                              {/*begin::Title*/}
                              <div className="flex-shrink-0 me-5">
                                <span className="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 pb-1">
                                  Featured
                                </span>
                                <span className="text-gray-800 fs-1 fw-bold">
                                  9 Degree
                                </span>
                              </div>
                              {/*end::Title*/}
                              <span className="badge badge-light-primary flex-shrink-0 align-self-center py-3 px-4 fs-7">
                                In Process
                              </span>
                            </div>
                            {/*end::Heading*/}
                            {/*begin::Items*/}
                            <div className="d-flex align-items-center flex-wrap d-grid gap-2">
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center me-5 me-xl-13">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-30px symbol-circle me-3">
                                  <img
                                    src="assets/media/avatars/300-3.jpg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Info*/}
                                <div className="m-0">
                                  <span className="fw-semibold text-gray-500 d-block fs-8">
                                    Manager
                                  </span>
                                  <a
                                    href="pages/user-profile/overview.html"
                                    className="fw-bold text-gray-800 text-hover-primary fs-7"
                                  >
                                    Robert Fox
                                  </a>
                                </div>
                                {/*end::Info*/}
                              </div>
                              {/*end::Item*/}
                              {/*begin::Item*/}
                              <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-30px symbol-circle me-3">
                                  <span className="symbol-label bg-success">
                                    <i className="ki-duotone ki-abstract-41 fs-5 text-white">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Info*/}
                                <div className="m-0">
                                  <span className="fw-semibold text-gray-500 d-block fs-8">
                                    Budget
                                  </span>
                                  <span className="fw-bold text-gray-800 fs-7">
                                    $64.800
                                  </span>
                                </div>
                                {/*end::Info*/}
                              </div>
                              {/*end::Item*/}
                            </div>
                            {/*end::Items*/}
                          </div>
                          {/*end::Header*/}
                          {/*begin::Body*/}
                          <div className="mb-6">
                            {/*begin::Text*/}
                            <span className="fw-semibold text-gray-600 fs-6 mb-8 d-block">
                              Flat cartoony illustrations with vivid unblended
                              colors and asymmetrical beautiful purple hair lady
                            </span>
                            {/*end::Text*/}
                            {/*begin::Stats*/}
                            <div className="d-flex">
                              {/*begin::Stat*/}
                              <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 me-6 mb-3">
                                {/*begin::Date*/}
                                <span className="fs-6 text-gray-700 fw-bold">
                                  Feb 6, 2021
                                </span>
                                {/*end::Date*/}
                                {/*begin::Label*/}
                                <div className="fw-semibold text-gray-500">
                                  Due Date
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Stat*/}
                              {/*begin::Stat*/}
                              <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-2 px-4 mb-3">
                                {/*begin::Number*/}
                                <span className="fs-6 text-gray-700 fw-bold">
                                  $
                                  <span
                                    className="ms-n1"
                                    data-kt-countup="true"
                                    data-kt-countup-defaultvalue="284,900.00"
                                  >
                                    0
                                  </span>
                                </span>
                                {/*end::Number*/}
                                {/*begin::Label*/}
                                <div className="fw-semibold text-gray-500">
                                  Budget
                                </div>
                                {/*end::Label*/}
                              </div>
                              {/*end::Stat*/}
                            </div>
                            {/*end::Stats*/}
                          </div>
                          {/*end::Body*/}
                          {/*begin::Footer*/}
                          <div className="d-flex flex-stack mt-auto bd-highlight">
                            {/*begin::Users group*/}
                            <div className="symbol-group symbol-hover flex-nowrap">
                              <div
                                className="symbol symbol-35px symbol-circle"
                                data-bs-toggle="tooltip"
                                title="Melody Macy"
                              >
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-2.jpg"
                                />
                              </div>
                              <div
                                className="symbol symbol-35px symbol-circle"
                                data-bs-toggle="tooltip"
                                title="Michael Eberon"
                              >
                                <img
                                  alt="Pic"
                                  src="assets/media/avatars/300-3.jpg"
                                />
                              </div>
                              <div
                                className="symbol symbol-35px symbol-circle"
                                data-bs-toggle="tooltip"
                                title="Susan Redwood"
                              >
                                <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                                  S
                                </span>
                              </div>
                            </div>
                            {/*end::Users group*/}
                            {/*begin::Actions*/}
                            <a
                              href="apps/projects/project.html"
                              className="d-flex align-items-center text-primary opacity-75-hover fs-6 fw-semibold"
                            >
                              View Project
                              <i className="ki-duotone ki-exit-right-corner fs-4 ms-1">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </a>
                            {/*end::Actions*/}
                          </div>
                          {/*end::Footer*/}
                        </div>
                        {/*end::Wrapper*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Card widget 18*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xxl-6">
                {/*begin::Engage widget 8*/}
                <div
                  className="card border-0 h-md-100"
                  data-bs-theme="light"
                  style={{
                    background:
                      "linear-gradient(112.14deg, #00D2FF 0%, #3A7BD5 100%)"
                  }}
                >
                  {/*begin::Body*/}
                  <div className="card-body">
                    {/*begin::Row*/}
                    <div className="row align-items-center h-100">
                      {/*begin::Col*/}
                      <div className="col-7 ps-xl-13">
                        {/*begin::Title*/}
                        <div className="text-white mb-6 pt-6">
                          <span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">
                            Get best offer
                          </span>
                          <span className="fs-2qx fw-bold">
                            Upgrade Your Plan
                          </span>
                        </div>
                        {/*end::Title*/}
                        {/*begin::Text*/}
                        <span className="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                          Flat cartoony and illustrations with vivid unblended
                          purple hair lady
                        </span>
                        {/*end::Text*/}
                        {/*begin::Items*/}
                        <div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-10 mb-xl-20">
                          {/*begin::Item*/}
                          <div className="d-flex align-items-center me-5 me-xl-13">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-30px symbol-circle me-3">
                              <span
                                className="symbol-label"
                                style={{ background: "#35C7FF" }}
                              >
                                <i className="ki-duotone ki-abstract-41 fs-5 text-white">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Info*/}
                            <div className="text-white">
                              <span className="fw-semibold d-block fs-8 opacity-75">
                                Projects
                              </span>
                              <span className="fw-bold fs-7">Up to 500</span>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-30px symbol-circle me-3">
                              <span
                                className="symbol-label"
                                style={{ background: "#35C7FF" }}
                              >
                                <i className="ki-duotone ki-abstract-26 fs-5 text-white">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Info*/}
                            <div className="text-white">
                              <span className="fw-semibold opacity-75 d-block fs-8">
                                Tasks
                              </span>
                              <span className="fw-bold fs-7">Unlimited</span>
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                        {/*end::Items*/}
                        {/*begin::Action*/}
                        <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                          <a
                            href="#"
                            className="btn btn-success flex-shrink-0 me-lg-2"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_upgrade_plan"
                          >
                            Upgrade Plan
                          </a>
                          <a
                            href="#"
                            className="btn btn-primary flex-shrink-0"
                            style={{ background: "rgba(255, 255, 255, 0.2)" }}
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_create_app"
                          >
                            Read Guides
                          </a>
                        </div>
                        {/*end::Action*/}
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-5 pt-10">
                        {/*begin::Illustration*/}
                        <div
                          className="bgi-no-repeat bgi-size-contain bgi-position-x-end h-225px"
                          style={{
                            backgroundImage:
                              'url("assets/media/svg/illustrations/easy/5.svg'
                          }}
                        />
                        {/*end::Illustration*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Row*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Engage widget 8*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
            {/*begin::Row*/}
            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
              {/*begin::Col*/}
              <div className="col-xl-4">
                {/*begin::Chart Widget 35*/}
                <div className="card card-flush h-md-100">
                  {/*begin::Header*/}
                  <div className="card-header pt-5 mb-6">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      {/*begin::Statistics*/}
                      <div className="d-flex align-items-center mb-2">
                        {/*begin::Currency*/}
                        <span className="fs-3 fw-semibold text-gray-500 align-self-start me-1">
                          $
                        </span>
                        {/*end::Currency*/}
                        {/*begin::Value*/}
                        <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
                          3,274.94
                        </span>
                        {/*end::Value*/}
                        {/*begin::Label*/}
                        <span className="badge badge-light-success fs-base">
                          <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          9.2%
                        </span>
                        {/*end::Label*/}
                      </div>
                      {/*end::Statistics*/}
                      {/*begin::Description*/}
                      <span className="fs-6 fw-semibold text-gray-500">
                        Avg. Agent Earnings
                      </span>
                      {/*end::Description*/}
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      {/*begin::Menu*/}
                      <button
                        className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-overflow="true"
                      >
                        <i className="ki-duotone ki-dots-square fs-1 text-gray-500 me-n1">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                        </i>
                      </button>
                      {/*begin::Menu 2*/}
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                        data-kt-menu="true"
                      >
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">
                            Quick Actions
                          </div>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mb-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            New Ticket
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            New Customer
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div
                          className="menu-item px-3"
                          data-kt-menu-trigger="hover"
                          data-kt-menu-placement="right-start"
                        >
                          {/*begin::Menu item*/}
                          <a href="#" className="menu-link px-3">
                            <span className="menu-title">New Group</span>
                            <span className="menu-arrow" />
                          </a>
                          {/*end::Menu item*/}
                          {/*begin::Menu sub*/}
                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                Admin Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                Staff Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            <div className="menu-item px-3">
                              <a href="#" className="menu-link px-3">
                                Member Group
                              </a>
                            </div>
                            {/*end::Menu item*/}
                          </div>
                          {/*end::Menu sub*/}
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3">
                            New Contact
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu separator*/}
                        <div className="separator mt-3 opacity-75" />
                        {/*end::Menu separator*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <div className="menu-content px-3 py-3">
                            <a className="btn btn-primary btn-sm px-4" href="#">
                              Generate Reports
                            </a>
                          </div>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu 2*/}
                      {/*end::Menu*/}
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body py-0 px-0">
                    {/*begin::Nav*/}
                    <ul className="nav d-flex justify-content-between mb-3 mx-9">
                      {/*begin::Item*/}
                      <li className="nav-item mb-3">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 w-45px h-35px active"
                          data-bs-toggle="tab"
                          id="kt_charts_widget_35_tab_1"
                          href="#kt_charts_widget_35_tab_content_1"
                        >
                          1d
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item mb-3">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 w-45px h-35px"
                          data-bs-toggle="tab"
                          id="kt_charts_widget_35_tab_2"
                          href="#kt_charts_widget_35_tab_content_2"
                        >
                          5d
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item mb-3">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 w-45px h-35px"
                          data-bs-toggle="tab"
                          id="kt_charts_widget_35_tab_3"
                          href="#kt_charts_widget_35_tab_content_3"
                        >
                          1m
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item mb-3">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 w-45px h-35px"
                          data-bs-toggle="tab"
                          id="kt_charts_widget_35_tab_4"
                          href="#kt_charts_widget_35_tab_content_4"
                        >
                          6m
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="nav-item mb-3">
                        {/*begin::Link*/}
                        <a
                          className="nav-link btn btn-flex flex-center btn-active-danger btn-color-gray-600 btn-active-color-white rounded-2 w-45px h-35px"
                          data-bs-toggle="tab"
                          id="kt_charts_widget_35_tab_5"
                          href="#kt_charts_widget_35_tab_content_5"
                        >
                          1y
                        </a>
                        {/*end::Link*/}
                      </li>
                      {/*end::Item*/}
                    </ul>
                    {/*end::Nav*/}
                    {/*begin::Tab Content*/}
                    <div className="tab-content mt-n6">
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade active show"
                        id="kt_charts_widget_35_tab_content_1"
                      >
                        {/*begin::Chart*/}
                        <div
                          id="kt_charts_widget_35_chart_1"
                          data-kt-chart-color="primary"
                          className="min-h-auto h-200px ps-3 pe-6"
                        />
                        {/*end::Chart*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive mx-9 mt-n6">
                          {/*begin::Table*/}
                          <table className="table align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                              <tr>
                                <th className="min-w-100px" />
                                <th className="min-w-100px text-end pe-0" />
                                <th className="text-end min-w-50px" />
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    2:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $2,756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-danger">
                                    -139.34
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    3:10 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $3,207.03
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-success">
                                    +576.24
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    3:55 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $3,274.94
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-success">
                                    +124.03
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_charts_widget_35_tab_content_2"
                      >
                        {/*begin::Chart*/}
                        <div
                          id="kt_charts_widget_35_chart_2"
                          data-kt-chart-color="primary"
                          className="min-h-auto h-200px ps-3 pe-6"
                        />
                        {/*end::Chart*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive mx-9 mt-n6">
                          {/*begin::Table*/}
                          <table className="table align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                              <tr>
                                <th className="min-w-100px" />
                                <th className="min-w-100px text-end pe-0" />
                                <th className="text-end min-w-50px" />
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    4:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $2,345.45
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-success">
                                    +134.02
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    11:35 AM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-primary">
                                    -124.03
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    3:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $1,756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-danger">
                                    +144.04
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_charts_widget_35_tab_content_3"
                      >
                        {/*begin::Chart*/}
                        <div
                          id="kt_charts_widget_35_chart_3"
                          data-kt-chart-color="primary"
                          className="min-h-auto h-200px ps-3 pe-6"
                        />
                        {/*end::Chart*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive mx-9 mt-n6">
                          {/*begin::Table*/}
                          <table className="table align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                              <tr>
                                <th className="min-w-100px" />
                                <th className="min-w-100px text-end pe-0" />
                                <th className="text-end min-w-50px" />
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    3:20 AM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $3,756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-primary">
                                    +185.03
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    12:30 AM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $2,756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-danger">
                                    +124.03
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    4:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-success">
                                    -154.03
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_charts_widget_35_tab_content_4"
                      >
                        {/*begin::Chart*/}
                        <div
                          id="kt_charts_widget_35_chart_4"
                          data-kt-chart-color="primary"
                          className="min-h-auto h-200px ps-3 pe-6"
                        />
                        {/*end::Chart*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive mx-9 mt-n6">
                          {/*begin::Table*/}
                          <table className="table align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                              <tr>
                                <th className="min-w-100px" />
                                <th className="min-w-100px text-end pe-0" />
                                <th className="text-end min-w-50px" />
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    2:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $2,756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-warning">
                                    +124.03
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    5:30 AM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $1,756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-info">
                                    +144.65
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    4:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $2,085.25
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-primary">
                                    +154.06
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                      </div>
                      {/*end::Tap pane*/}
                      {/*begin::Tap pane*/}
                      <div
                        className="tab-pane fade"
                        id="kt_charts_widget_35_tab_content_5"
                      >
                        {/*begin::Chart*/}
                        <div
                          id="kt_charts_widget_35_chart_5"
                          data-kt-chart-color="primary"
                          className="min-h-auto h-200px ps-3 pe-6"
                        />
                        {/*end::Chart*/}
                        {/*begin::Table container*/}
                        <div className="table-responsive mx-9 mt-n6">
                          {/*begin::Table*/}
                          <table className="table align-middle gs-0 gy-4">
                            {/*begin::Table head*/}
                            <thead>
                              <tr>
                                <th className="min-w-100px" />
                                <th className="min-w-100px text-end pe-0" />
                                <th className="text-end min-w-50px" />
                              </tr>
                            </thead>
                            {/*end::Table head*/}
                            {/*begin::Table body*/}
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    2:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $2,045.04
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-warning">
                                    +114.03
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    3:30 AM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-primary">
                                    -124.03
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <a
                                    href="#"
                                    className="text-gray-600 fw-bold fs-6"
                                  >
                                    10:30 PM
                                  </a>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="text-gray-800 fw-bold fs-6 me-1">
                                    $1.756.26
                                  </span>
                                </td>
                                <td className="pe-0 text-end">
                                  <span className="fw-bold fs-6 text-info">
                                    +165.86
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                            {/*end::Table body*/}
                          </table>
                          {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                      </div>
                      {/*end::Tap pane*/}
                    </div>
                    {/*end::Tab Content*/}
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Chart Widget 35*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-xl-8">
                {/*begin::Table widget 14*/}
                <div className="card card-flush h-md-100">
                  {/*begin::Header*/}
                  <div className="card-header pt-7">
                    {/*begin::Title*/}
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Projects Stats
                      </span>
                      <span className="text-gray-500 mt-1 fw-semibold fs-6">
                        Updated 37 minutes ago
                      </span>
                    </h3>
                    {/*end::Title*/}
                    {/*begin::Toolbar*/}
                    <div className="card-toolbar">
                      <a
                        href="apps/ecommerce/catalog/add-product.html"
                        className="btn btn-sm btn-light"
                      >
                        History
                      </a>
                    </div>
                    {/*end::Toolbar*/}
                  </div>
                  {/*end::Header*/}
                  {/*begin::Body*/}
                  <div className="card-body pt-6">
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                        {/*begin::Table head*/}
                        <thead>
                          <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                            <th className="p-0 pb-3 min-w-175px text-start">
                              ITEM
                            </th>
                            <th className="p-0 pb-3 min-w-100px text-end">
                              BUDGET
                            </th>
                            <th className="p-0 pb-3 min-w-100px text-end">
                              PROGRESS
                            </th>
                            <th className="p-0 pb-3 min-w-175px text-end pe-12">
                              STATUS
                            </th>
                            <th className="p-0 pb-3 w-125px text-end pe-7">
                              CHART
                            </th>
                            <th className="p-0 pb-3 w-50px text-end">VIEW</th>
                          </tr>
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}
                        <tbody>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-3">
                                  <img
                                    src="assets/media/stock/600x600/img-49.jpg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <a
                                    href="#"
                                    className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"
                                  >
                                    Mivy App
                                  </a>
                                  <span className="text-gray-500 fw-semibold d-block fs-7">
                                    Jane Cooper
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-end pe-0">
                              <span className="text-gray-600 fw-bold fs-6">
                                $32,400
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                9.2%
                              </span>
                              {/*end::Label*/}
                            </td>
                            <td className="text-end pe-12">
                              <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                In Process
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              <div
                                id="kt_table_widget_14_chart_1"
                                className="h-50px mt-n8 pe-7"
                                data-kt-chart-color="success"
                              />
                            </td>
                            <td className="text-end">
                              <a
                                href="#"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                              >
                                <i className="ki-duotone ki-black-right fs-2 text-gray-500" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-3">
                                  <img
                                    src="assets/media/stock/600x600/img-40.jpg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <a
                                    href="#"
                                    className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"
                                  >
                                    Avionica
                                  </a>
                                  <span className="text-gray-500 fw-semibold d-block fs-7">
                                    Esther Howard
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-end pe-0">
                              <span className="text-gray-600 fw-bold fs-6">
                                $256,910
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-danger fs-base">
                                <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                0.4%
                              </span>
                              {/*end::Label*/}
                            </td>
                            <td className="text-end pe-12">
                              <span className="badge py-3 px-4 fs-7 badge-light-warning">
                                On Hold
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              <div
                                id="kt_table_widget_14_chart_2"
                                className="h-50px mt-n8 pe-7"
                                data-kt-chart-color="danger"
                              />
                            </td>
                            <td className="text-end">
                              <a
                                href="#"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                              >
                                <i className="ki-duotone ki-black-right fs-2 text-gray-500" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-3">
                                  <img
                                    src="assets/media/stock/600x600/img-39.jpg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <a
                                    href="#"
                                    className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"
                                  >
                                    Charto CRM
                                  </a>
                                  <span className="text-gray-500 fw-semibold d-block fs-7">
                                    Jenny Wilson
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-end pe-0">
                              <span className="text-gray-600 fw-bold fs-6">
                                $8,220
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                9.2%
                              </span>
                              {/*end::Label*/}
                            </td>
                            <td className="text-end pe-12">
                              <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                In Process
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              <div
                                id="kt_table_widget_14_chart_3"
                                className="h-50px mt-n8 pe-7"
                                data-kt-chart-color="success"
                              />
                            </td>
                            <td className="text-end">
                              <a
                                href="#"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                              >
                                <i className="ki-duotone ki-black-right fs-2 text-gray-500" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-3">
                                  <img
                                    src="assets/media/stock/600x600/img-47.jpg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <a
                                    href="#"
                                    className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"
                                  >
                                    Tower Hill
                                  </a>
                                  <span className="text-gray-500 fw-semibold d-block fs-7">
                                    Cody Fisher
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-end pe-0">
                              <span className="text-gray-600 fw-bold fs-6">
                                $74,000
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-success fs-base">
                                <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                9.2%
                              </span>
                              {/*end::Label*/}
                            </td>
                            <td className="text-end pe-12">
                              <span className="badge py-3 px-4 fs-7 badge-light-success">
                                Complated
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              <div
                                id="kt_table_widget_14_chart_4"
                                className="h-50px mt-n8 pe-7"
                                data-kt-chart-color="success"
                              />
                            </td>
                            <td className="text-end">
                              <a
                                href="#"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                              >
                                <i className="ki-duotone ki-black-right fs-2 text-gray-500" />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px me-3">
                                  <img
                                    src="assets/media/stock/600x600/img-48.jpg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <a
                                    href="#"
                                    className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"
                                  >
                                    9 Degree
                                  </a>
                                  <span className="text-gray-500 fw-semibold d-block fs-7">
                                    Savannah Nguyen
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-end pe-0">
                              <span className="text-gray-600 fw-bold fs-6">
                                $183,300
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              {/*begin::Label*/}
                              <span className="badge badge-light-danger fs-base">
                                <i className="ki-duotone ki-arrow-down fs-5 text-danger ms-n1">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                0.4%
                              </span>
                              {/*end::Label*/}
                            </td>
                            <td className="text-end pe-12">
                              <span className="badge py-3 px-4 fs-7 badge-light-primary">
                                In Process
                              </span>
                            </td>
                            <td className="text-end pe-0">
                              <div
                                id="kt_table_widget_14_chart_5"
                                className="h-50px mt-n8 pe-7"
                                data-kt-chart-color="danger"
                              />
                            </td>
                            <td className="text-end">
                              <a
                                href="#"
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px"
                              >
                                <i className="ki-duotone ki-black-right fs-2 text-gray-500" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        {/*end::Table body*/}
                      </table>
                    </div>
                    {/*end::Table*/}
                  </div>
                  {/*end: Card Body*/}
                </div>
                {/*end::Table widget 14*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          
          </div>
          {/*end::Post*/}
        </div>
        {/*end::Container*/}
        {/*begin::Footer*/}
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
          {/*begin::Container*/}
          <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
            {/*begin::Copyright*/}
            <div className="text-gray-900 order-2 order-md-1">
              <span className="text-muted fw-semibold me-1">2024©</span>
              <a
                href="https://keenthemes.com"
                target="_blank"
                className="text-gray-800 text-hover-primary"
              >
                Keenthemes
              </a>
            </div>
            {/*end::Copyright*/}
            {/*begin::Menu*/}
            <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
              <li className="menu-item">
                <a
                  href="https://keenthemes.com"
                  target="_blank"
                  className="menu-link px-2"
                >
                  About
                </a>
              </li>
              <li className="menu-item">
                <a
                  href="https://devs.keenthemes.com"
                  target="_blank"
                  className="menu-link px-2"
                >
                  Support
                </a>
              </li>
              <li className="menu-item">
                <a
                  href="https://1.envato.market/EA4JP"
                  target="_blank"
                  className="menu-link px-2"
                >
                  Purchase
                </a>
              </li>
            </ul>
            {/*end::Menu*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Footer*/}
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*end::Page*/}
  </div>
  {/*end::Root*/}
  {/*begin::Drawers*/}
  {/*begin::Activities drawer*/}
  <div
    id="kt_activities"
    className="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="activities"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_activities_toggle"
    data-kt-drawer-close="#kt_activities_close"
  >
    <div className="card shadow-none border-0 rounded-0">
      {/*begin::Header*/}
      <div className="card-header" id="kt_activities_header">
        <h3 className="card-title fw-bold text-gray-900">Activity Logs</h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-active-light-primary me-n5"
            id="kt_activities_close"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </button>
        </div>
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className="card-body position-relative" id="kt_activities_body">
        {/*begin::Content*/}
        <div
          id="kt_activities_scroll"
          className="position-relative scroll-y me-n5 pe-5"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_activities_body"
          data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
          data-kt-scroll-offset="5px"
        >
          {/*begin::Timeline items*/}
          <div className="timeline timeline-border-dashed">
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-message-text-2 fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n1">
                {/*begin::Timeline heading*/}
                <div className="pe-3 mb-5">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    There are 2 new tasks for you in “AirPlus Mobile App”
                    project:
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Added at 4:23 PM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <div
                      className="symbol symbol-circle symbol-25px"
                      data-bs-toggle="tooltip"
                      data-bs-boundary="window"
                      data-bs-placement="top"
                      title="Nina Nilson"
                    >
                      <img src="assets/media/avatars/300-14.jpg" alt="img" />
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
                {/*begin::Timeline details*/}
                <div className="overflow-auto pb-5">
                  {/*begin::Record*/}
                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                    {/*begin::Title*/}
                    <a
                      href="apps/projects/project.html"
                      className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px"
                    >
                      Meeting with customer
                    </a>
                    {/*end::Title*/}
                    {/*begin::Label*/}
                    <div className="min-w-175px pe-2">
                      <span className="badge badge-light text-muted">
                        Application Design
                      </span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Users*/}
                    <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                      {/*begin::User*/}
                      <div className="symbol symbol-circle symbol-25px">
                        <img src="assets/media/avatars/300-2.jpg" alt="img" />
                      </div>
                      {/*end::User*/}
                      {/*begin::User*/}
                      <div className="symbol symbol-circle symbol-25px">
                        <img src="assets/media/avatars/300-14.jpg" alt="img" />
                      </div>
                      {/*end::User*/}
                      {/*begin::User*/}
                      <div className="symbol symbol-circle symbol-25px">
                        <div className="symbol-label fs-8 fw-semibold bg-primary text-inverse-primary">
                          A
                        </div>
                      </div>
                      {/*end::User*/}
                    </div>
                    {/*end::Users*/}
                    {/*begin::Progress*/}
                    <div className="min-w-125px pe-2">
                      <span className="badge badge-light-primary">
                        In Progress
                      </span>
                    </div>
                    {/*end::Progress*/}
                    {/*begin::Action*/}
                    <a
                      href="apps/projects/project.html"
                      className="btn btn-sm btn-light btn-active-light-primary"
                    >
                      View
                    </a>
                    {/*end::Action*/}
                  </div>
                  {/*end::Record*/}
                  {/*begin::Record*/}
                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">
                    {/*begin::Title*/}
                    <a
                      href="apps/projects/project.html"
                      className="fs-5 text-gray-900 text-hover-primary fw-semibold w-375px min-w-200px"
                    >
                      Project Delivery Preparation
                    </a>
                    {/*end::Title*/}
                    {/*begin::Label*/}
                    <div className="min-w-175px">
                      <span className="badge badge-light text-muted">
                        CRM System Development
                      </span>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Users*/}
                    <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">
                      {/*begin::User*/}
                      <div className="symbol symbol-circle symbol-25px">
                        <img src="assets/media/avatars/300-20.jpg" alt="img" />
                      </div>
                      {/*end::User*/}
                      {/*begin::User*/}
                      <div className="symbol symbol-circle symbol-25px">
                        <div className="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">
                          B
                        </div>
                      </div>
                      {/*end::User*/}
                    </div>
                    {/*end::Users*/}
                    {/*begin::Progress*/}
                    <div className="min-w-125px">
                      <span className="badge badge-light-success">
                        Completed
                      </span>
                    </div>
                    {/*end::Progress*/}
                    {/*begin::Action*/}
                    <a
                      href="apps/projects/project.html"
                      className="btn btn-sm btn-light btn-active-light-primary"
                    >
                      View
                    </a>
                    {/*end::Action*/}
                  </div>
                  {/*end::Record*/}
                </div>
                {/*end::Timeline details*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon me-4">
                <i className="ki-duotone ki-flag fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n2">
                {/*begin::Timeline heading*/}
                <div className="overflow-auto pe-3">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    Invitation for crafting engaging designs that speak human
                    workshop
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Sent at 4:23 PM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <div
                      className="symbol symbol-circle symbol-25px"
                      data-bs-toggle="tooltip"
                      data-bs-boundary="window"
                      data-bs-placement="top"
                      title="Alan Nilson"
                    >
                      <img src="assets/media/avatars/300-1.jpg" alt="img" />
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-disconnect fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                  <span className="path5" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n1">
                {/*begin::Timeline heading*/}
                <div className="mb-5 pe-3">
                  {/*begin::Title*/}
                  <a
                    href="#"
                    className="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2"
                  >
                    3 New Incoming Project Files:
                  </a>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Sent at 10:30 PM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <div
                      className="symbol symbol-circle symbol-25px"
                      data-bs-toggle="tooltip"
                      data-bs-boundary="window"
                      data-bs-placement="top"
                      title="Jan Hummer"
                    >
                      <img src="assets/media/avatars/300-23.jpg" alt="img" />
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
                {/*begin::Timeline details*/}
                <div className="overflow-auto pb-5">
                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                    {/*begin::Item*/}
                    <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                      {/*begin::Icon*/}
                      <img
                        alt=""
                        className="w-30px me-3"
                        src="assets/media/svg/files/pdf.svg"
                      />
                      {/*end::Icon*/}
                      {/*begin::Info*/}
                      <div className="ms-1 fw-semibold">
                        {/*begin::Desc*/}
                        <a
                          href="apps/projects/project.html"
                          className="fs-6 text-hover-primary fw-bold"
                        >
                          Finance KPI App Guidelines
                        </a>
                        {/*end::Desc*/}
                        {/*begin::Number*/}
                        <div className="text-gray-500">1.9mb</div>
                        {/*end::Number*/}
                      </div>
                      {/*begin::Info*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                      {/*begin::Icon*/}
                      <img
                        alt="apps/projects/project.html"
                        className="w-30px me-3"
                        src="assets/media/svg/files/doc.svg"
                      />
                      {/*end::Icon*/}
                      {/*begin::Info*/}
                      <div className="ms-1 fw-semibold">
                        {/*begin::Desc*/}
                        <a href="#" className="fs-6 text-hover-primary fw-bold">
                          Client UAT Testing Results
                        </a>
                        {/*end::Desc*/}
                        {/*begin::Number*/}
                        <div className="text-gray-500">18kb</div>
                        {/*end::Number*/}
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex flex-aligns-center">
                      {/*begin::Icon*/}
                      <img
                        alt="apps/projects/project.html"
                        className="w-30px me-3"
                        src="assets/media/svg/files/css.svg"
                      />
                      {/*end::Icon*/}
                      {/*begin::Info*/}
                      <div className="ms-1 fw-semibold">
                        {/*begin::Desc*/}
                        <a href="#" className="fs-6 text-hover-primary fw-bold">
                          Finance Reports
                        </a>
                        {/*end::Desc*/}
                        {/*begin::Number*/}
                        <div className="text-gray-500">20mb</div>
                        {/*end::Number*/}
                      </div>
                      {/*end::Icon*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                </div>
                {/*end::Timeline details*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-abstract-26 fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n1">
                {/*begin::Timeline heading*/}
                <div className="pe-3 mb-5">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    Task
                    <a href="#" className="text-primary fw-bold me-1">
                      #45890
                    </a>
                    merged with
                    <a href="#" className="text-primary fw-bold me-1">
                      #45890
                    </a>
                    in “Ads Pro Admin Dashboard project:
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Initiated at 4:23 PM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <div
                      className="symbol symbol-circle symbol-25px"
                      data-bs-toggle="tooltip"
                      data-bs-boundary="window"
                      data-bs-placement="top"
                      title="Nina Nilson"
                    >
                      <img src="assets/media/avatars/300-14.jpg" alt="img" />
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-pencil fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n1">
                {/*begin::Timeline heading*/}
                <div className="pe-3 mb-5">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    3 new application design concepts added:
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Created at 4:23 PM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <div
                      className="symbol symbol-circle symbol-25px"
                      data-bs-toggle="tooltip"
                      data-bs-boundary="window"
                      data-bs-placement="top"
                      title="Marcus Dotson"
                    >
                      <img src="assets/media/avatars/300-2.jpg" alt="img" />
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
                {/*begin::Timeline details*/}
                <div className="overflow-auto pb-5">
                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                    {/*begin::Item*/}
                    <div className="overlay me-10">
                      {/*begin::Image*/}
                      <div className="overlay-wrapper">
                        <img
                          alt="img"
                          className="rounded w-150px"
                          src="assets/media/stock/600x400/img-29.jpg"
                        />
                      </div>
                      {/*end::Image*/}
                      {/*begin::Link*/}
                      <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary btn-shadow"
                        >
                          Explore
                        </a>
                      </div>
                      {/*end::Link*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="overlay me-10">
                      {/*begin::Image*/}
                      <div className="overlay-wrapper">
                        <img
                          alt="img"
                          className="rounded w-150px"
                          src="assets/media/stock/600x400/img-31.jpg"
                        />
                      </div>
                      {/*end::Image*/}
                      {/*begin::Link*/}
                      <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary btn-shadow"
                        >
                          Explore
                        </a>
                      </div>
                      {/*end::Link*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="overlay">
                      {/*begin::Image*/}
                      <div className="overlay-wrapper">
                        <img
                          alt="img"
                          className="rounded w-150px"
                          src="assets/media/stock/600x400/img-40.jpg"
                        />
                      </div>
                      {/*end::Image*/}
                      {/*begin::Link*/}
                      <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary btn-shadow"
                        >
                          Explore
                        </a>
                      </div>
                      {/*end::Link*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                </div>
                {/*end::Timeline details*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-sms fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n1">
                {/*begin::Timeline heading*/}
                <div className="pe-3 mb-5">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    New case
                    <a href="#" className="text-primary fw-bold me-1">
                      #67890
                    </a>
                    is assigned to you in Multi-platform Database Design project
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="overflow-auto pb-5">
                    {/*begin::Wrapper*/}
                    <div className="d-flex align-items-center mt-1 fs-6">
                      {/*begin::Info*/}
                      <div className="text-muted me-2 fs-7">
                        Added at 4:23 PM by
                      </div>
                      {/*end::Info*/}
                      {/*begin::User*/}
                      <a href="#" className="text-primary fw-bold me-1">
                        Alice Tan
                      </a>
                      {/*end::User*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-pencil fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mb-10 mt-n1">
                {/*begin::Timeline heading*/}
                <div className="pe-3 mb-5">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    You have received a new order:
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Placed at 5:05 AM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <div
                      className="symbol symbol-circle symbol-25px"
                      data-bs-toggle="tooltip"
                      data-bs-boundary="window"
                      data-bs-placement="top"
                      title="Robert Rich"
                    >
                      <img src="assets/media/avatars/300-4.jpg" alt="img" />
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
                {/*begin::Timeline details*/}
                <div className="overflow-auto pb-5">
                  {/*begin::Notice*/}
                  <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                    {/*begin::Icon*/}
                    <i className="ki-duotone ki-devices-2 fs-2tx text-primary me-4">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                    {/*end::Icon*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                      {/*begin::Content*/}
                      <div className="mb-3 mb-md-0 fw-semibold">
                        <h4 className="text-gray-900 fw-bold">
                          Database Backup Process Completed!
                        </h4>
                        <div className="fs-6 text-gray-700 pe-7">
                          Login into Admin Dashboard to make sure the data
                          integrity is OK
                        </div>
                      </div>
                      {/*end::Content*/}
                      {/*begin::Action*/}
                      <a
                        href="#"
                        className="btn btn-primary px-6 align-self-center text-nowrap"
                      >
                        Proceed
                      </a>
                      {/*end::Action*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>
                  {/*end::Notice*/}
                </div>
                {/*end::Timeline details*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
            {/*begin::Timeline item*/}
            <div className="timeline-item">
              {/*begin::Timeline line*/}
              <div className="timeline-line" />
              {/*end::Timeline line*/}
              {/*begin::Timeline icon*/}
              <div className="timeline-icon">
                <i className="ki-duotone ki-basket fs-2 text-gray-500">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                  <span className="path4" />
                </i>
              </div>
              {/*end::Timeline icon*/}
              {/*begin::Timeline content*/}
              <div className="timeline-content mt-n1">
                {/*begin::Timeline heading*/}
                <div className="pe-3 mb-5">
                  {/*begin::Title*/}
                  <div className="fs-5 fw-semibold mb-2">
                    New order
                    <a href="#" className="text-primary fw-bold me-1">
                      #67890
                    </a>
                    is placed for Workshow Planning &amp; Budget Estimation
                  </div>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="d-flex align-items-center mt-1 fs-6">
                    {/*begin::Info*/}
                    <div className="text-muted me-2 fs-7">
                      Placed at 4:23 PM by
                    </div>
                    {/*end::Info*/}
                    {/*begin::User*/}
                    <a href="#" className="text-primary fw-bold me-1">
                      Jimmy Bold
                    </a>
                    {/*end::User*/}
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Timeline heading*/}
              </div>
              {/*end::Timeline content*/}
            </div>
            {/*end::Timeline item*/}
          </div>
          {/*end::Timeline items*/}
        </div>
        {/*end::Content*/}
      </div>
      {/*end::Body*/}
      {/*begin::Footer*/}
      <div className="card-footer py-5 text-center" id="kt_activities_footer">
        <a
          href="pages/user-profile/activity.html"
          className="btn btn-bg-body text-primary"
        >
          View All Activities
          <i className="ki-duotone ki-arrow-right fs-3 text-primary">
            <span className="path1" />
            <span className="path2" />
          </i>
        </a>
      </div>
      {/*end::Footer*/}
    </div>
  </div>
  {/*end::Activities drawer*/}
  {/*begin::Chat drawer*/}
  <div
    id="kt_drawer_chat"
    className="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="chat"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_drawer_chat_toggle"
    data-kt-drawer-close="#kt_drawer_chat_close"
  >
    {/*begin::Messenger*/}
    <div
      className="card w-100 border-0 rounded-0"
      id="kt_drawer_chat_messenger"
    >
      {/*begin::Card header*/}
      <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
        {/*begin::Title*/}
        <div className="card-title">
          {/*begin::User*/}
          <div className="d-flex justify-content-center flex-column me-3">
            <a
              href="#"
              className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
            >
              Brian Cox
            </a>
            {/*begin::Info*/}
            <div className="mb-0 lh-1">
              <span className="badge badge-success badge-circle w-10px h-10px me-1" />
              <span className="fs-7 fw-semibold text-muted">Active</span>
            </div>
            {/*end::Info*/}
          </div>
          {/*end::User*/}
        </div>
        {/*end::Title*/}
        {/*begin::Card toolbar*/}
        <div className="card-toolbar">
          {/*begin::Menu*/}
          <div className="me-0">
            <button
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              <i className="ki-duotone ki-dots-square fs-2">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
              </i>
            </button>
            {/*begin::Menu 3*/}
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
              data-kt-menu="true"
            >
              {/*begin::Heading*/}
              <div className="menu-item px-3">
                <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                  Contacts
                </div>
              </div>
              {/*end::Heading*/}
              {/*begin::Menu item*/}
              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_users_search"
                >
                  Add Contact
                </a>
              </div>
              {/*end::Menu item*/}
              {/*begin::Menu item*/}
              <div className="menu-item px-3">
                <a
                  href="#"
                  className="menu-link flex-stack px-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_invite_friends"
                >
                  Invite Contacts
                  <span
                    className="ms-2"
                    data-bs-toggle="tooltip"
                    title="Specify a contact email to send an invitation"
                  >
                    <i className="ki-duotone ki-information fs-7">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </span>
                </a>
              </div>
              {/*end::Menu item*/}
              {/*begin::Menu item*/}
              <div
                className="menu-item px-3"
                data-kt-menu-trigger="hover"
                data-kt-menu-placement="right-start"
              >
                <a href="#" className="menu-link px-3">
                  <span className="menu-title">Groups</span>
                  <span className="menu-arrow" />
                </a>
                {/*begin::Menu sub*/}
                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a
                      href="#"
                      className="menu-link px-3"
                      data-bs-toggle="tooltip"
                      title="Coming soon"
                    >
                      Create Group
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a
                      href="#"
                      className="menu-link px-3"
                      data-bs-toggle="tooltip"
                      title="Coming soon"
                    >
                      Invite Members
                    </a>
                  </div>
                  {/*end::Menu item*/}
                  {/*begin::Menu item*/}
                  <div className="menu-item px-3">
                    <a
                      href="#"
                      className="menu-link px-3"
                      data-bs-toggle="tooltip"
                      title="Coming soon"
                    >
                      Settings
                    </a>
                  </div>
                  {/*end::Menu item*/}
                </div>
                {/*end::Menu sub*/}
              </div>
              {/*end::Menu item*/}
              {/*begin::Menu item*/}
              <div className="menu-item px-3 my-1">
                <a
                  href="#"
                  className="menu-link px-3"
                  data-bs-toggle="tooltip"
                  title="Coming soon"
                >
                  Settings
                </a>
              </div>
              {/*end::Menu item*/}
            </div>
            {/*end::Menu 3*/}
          </div>
          {/*end::Menu*/}
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            id="kt_drawer_chat_close"
          >
            <i className="ki-duotone ki-cross-square fs-2">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Card toolbar*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body" id="kt_drawer_chat_messenger_body">
        {/*begin::Messages*/}
        <div
          className="scroll-y me-n5 pe-5"
          data-kt-element="messages"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
          data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
          data-kt-scroll-offset="0px"
        >
          {/*begin::Message(in)*/}
          <div className="d-flex justify-content-start mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-start">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                </div>
                {/*end::Avatar*/}
                {/*begin::Details*/}
                <div className="ms-3">
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >
                    Brian Cox
                  </a>
                  <span className="text-muted fs-7 mb-1">2 mins</span>
                </div>
                {/*end::Details*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                How likely are you to recommend our company to your friends and
                family ?
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(in)*/}
          {/*begin::Message(out)*/}
          <div className="d-flex justify-content-end mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-end">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Details*/}
                <div className="me-3">
                  <span className="text-muted fs-7 mb-1">5 mins</span>
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                  >
                    You
                  </a>
                </div>
                {/*end::Details*/}
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                </div>
                {/*end::Avatar*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
                data-kt-element="message-text"
              >
                Hey there, we’re just writing to let you know that you’ve been
                subscribed to a repository on GitHub.
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(out)*/}
          {/*begin::Message(in)*/}
          <div className="d-flex justify-content-start mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-start">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                </div>
                {/*end::Avatar*/}
                {/*begin::Details*/}
                <div className="ms-3">
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >
                    Brian Cox
                  </a>
                  <span className="text-muted fs-7 mb-1">1 Hour</span>
                </div>
                {/*end::Details*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                Ok, Understood!
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(in)*/}
          {/*begin::Message(out)*/}
          <div className="d-flex justify-content-end mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-end">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Details*/}
                <div className="me-3">
                  <span className="text-muted fs-7 mb-1">2 Hours</span>
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                  >
                    You
                  </a>
                </div>
                {/*end::Details*/}
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                </div>
                {/*end::Avatar*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
                data-kt-element="message-text"
              >
                You’ll receive notifications for all issues, pull requests!
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(out)*/}
          {/*begin::Message(in)*/}
          <div className="d-flex justify-content-start mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-start">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                </div>
                {/*end::Avatar*/}
                {/*begin::Details*/}
                <div className="ms-3">
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >
                    Brian Cox
                  </a>
                  <span className="text-muted fs-7 mb-1">3 Hours</span>
                </div>
                {/*end::Details*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                You can unwatch this repository immediately by clicking here:
                <a href="https://keenthemes.com">Keenthemes.com</a>
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(in)*/}
          {/*begin::Message(out)*/}
          <div className="d-flex justify-content-end mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-end">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Details*/}
                <div className="me-3">
                  <span className="text-muted fs-7 mb-1">4 Hours</span>
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                  >
                    You
                  </a>
                </div>
                {/*end::Details*/}
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                </div>
                {/*end::Avatar*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
                data-kt-element="message-text"
              >
                Most purchased Business courses during this sale!
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(out)*/}
          {/*begin::Message(in)*/}
          <div className="d-flex justify-content-start mb-10">
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-start">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                </div>
                {/*end::Avatar*/}
                {/*begin::Details*/}
                <div className="ms-3">
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >
                    Brian Cox
                  </a>
                  <span className="text-muted fs-7 mb-1">5 Hours</span>
                </div>
                {/*end::Details*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                Company BBQ to celebrate the last quater achievements and goals.
                Food and drinks provided
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(in)*/}
          {/*begin::Message(template for out)*/}
          <div
            className="d-flex justify-content-end mb-10 d-none"
            data-kt-element="template-out"
          >
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-end">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Details*/}
                <div className="me-3">
                  <span className="text-muted fs-7 mb-1">Just now</span>
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                  >
                    You
                  </a>
                </div>
                {/*end::Details*/}
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                </div>
                {/*end::Avatar*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-primary text-gray-900 fw-semibold mw-lg-400px text-end"
                data-kt-element="message-text"
              />
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(template for out)*/}
          {/*begin::Message(template for in)*/}
          <div
            className="d-flex justify-content-start mb-10 d-none"
            data-kt-element="template-in"
          >
            {/*begin::Wrapper*/}
            <div className="d-flex flex-column align-items-start">
              {/*begin::User*/}
              <div className="d-flex align-items-center mb-2">
                {/*begin::Avatar*/}
                <div className="symbol symbol-35px symbol-circle">
                  <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                </div>
                {/*end::Avatar*/}
                {/*begin::Details*/}
                <div className="ms-3">
                  <a
                    href="#"
                    className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                  >
                    Brian Cox
                  </a>
                  <span className="text-muted fs-7 mb-1">Just now</span>
                </div>
                {/*end::Details*/}
              </div>
              {/*end::User*/}
              {/*begin::Text*/}
              <div
                className="p-5 rounded bg-light-info text-gray-900 fw-semibold mw-lg-400px text-start"
                data-kt-element="message-text"
              >
                Right before vacation season we have the next Big Deal for you.
              </div>
              {/*end::Text*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Message(template for in)*/}
        </div>
        {/*end::Messages*/}
      </div>
      {/*end::Card body*/}
      {/*begin::Card footer*/}
      <div className="card-footer pt-4" id="kt_drawer_chat_messenger_footer">
        {/*begin::Input*/}
        <textarea
          className="form-control form-control-flush mb-3"
          rows={1}
          data-kt-element="input"
          placeholder="Type a message"
          defaultValue={""}
        />
        {/*end::Input*/}
        {/*begin:Toolbar*/}
        <div className="d-flex flex-stack">
          {/*begin::Actions*/}
          <div className="d-flex align-items-center me-2">
            <button
              className="btn btn-sm btn-icon btn-active-light-primary me-1"
              type="button"
              data-bs-toggle="tooltip"
              title="Coming soon"
            >
              <i className="ki-duotone ki-paper-clip fs-3" />
            </button>
            <button
              className="btn btn-sm btn-icon btn-active-light-primary me-1"
              type="button"
              data-bs-toggle="tooltip"
              title="Coming soon"
            >
              <i className="ki-duotone ki-cloud-add fs-3">
                <span className="path1" />
                <span className="path2" />
              </i>
            </button>
          </div>
          {/*end::Actions*/}
          {/*begin::Send*/}
          <button
            className="btn btn-primary"
            type="button"
            data-kt-element="send"
          >
            Send
          </button>
          {/*end::Send*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Card footer*/}
    </div>
    {/*end::Messenger*/}
  </div>
  {/*end::Chat drawer*/}
  {/*begin::Chat drawer*/}
  <div
    id="kt_shopping_cart"
    className="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="cart"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_drawer_shopping_cart_toggle"
    data-kt-drawer-close="#kt_drawer_shopping_cart_close"
  >
    {/*begin::Messenger*/}
    <div className="card card-flush w-100 rounded-0">
      {/*begin::Card header*/}
      <div className="card-header">
        {/*begin::Title*/}
        <h3 className="card-title text-gray-900 fw-bold">Shopping Cart</h3>
        {/*end::Title*/}
        {/*begin::Card toolbar*/}
        <div className="card-toolbar">
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-light-primary"
            id="kt_drawer_shopping_cart_close"
          >
            <i className="ki-duotone ki-cross fs-2">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Card toolbar*/}
      </div>
      {/*end::Card header*/}
      {/*begin::Card body*/}
      <div className="card-body hover-scroll-overlay-y h-400px pt-5">
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                Iblender
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                The best kitchen gadget in 2022
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 350</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">5</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-1.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
        {/*begin::Separator*/}
        <div className="separator separator-dashed my-6" />
        {/*end::Separator*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                SmartCleaner
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                Smart tool for cooking
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 650</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">4</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-3.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
        {/*begin::Separator*/}
        <div className="separator separator-dashed my-6" />
        {/*end::Separator*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                CameraMaxr
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                Professional camera for edge
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 150</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">3</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-8.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
        {/*begin::Separator*/}
        <div className="separator separator-dashed my-6" />
        {/*end::Separator*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                $D Printer
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                Manfactoring unique objekts
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 1450</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">7</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-26.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
        {/*begin::Separator*/}
        <div className="separator separator-dashed my-6" />
        {/*end::Separator*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                MotionWire
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                Perfect animation tool
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 650</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">7</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-21.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
        {/*begin::Separator*/}
        <div className="separator separator-dashed my-6" />
        {/*end::Separator*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                Samsung
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                Profile info,Timeline etc
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 720</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">6</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-34.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
        {/*begin::Separator*/}
        <div className="separator separator-dashed my-6" />
        {/*end::Separator*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          {/*begin::Wrapper*/}
          <div className="d-flex flex-column me-3">
            {/*begin::Section*/}
            <div className="mb-3">
              <a
                href="apps/ecommerce/sales/details.html"
                className="text-gray-800 text-hover-primary fs-4 fw-bold"
              >
                $D Printer
              </a>
              <span className="text-gray-500 fw-semibold d-block">
                Manfactoring unique objekts
              </span>
            </div>
            {/*end::Section*/}
            {/*begin::Section*/}
            <div className="d-flex align-items-center">
              <span className="fw-bold text-gray-800 fs-5">$ 430</span>
              <span className="text-muted mx-2">for</span>
              <span className="fw-bold text-gray-800 fs-5 me-3">8</span>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2"
              >
                <i className="ki-duotone ki-minus fs-4" />
              </a>
              <a
                href="#"
                className="btn btn-sm btn-light-success btn-icon w-25px h-25px"
              >
                <i className="ki-duotone ki-plus fs-4" />
              </a>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Wrapper*/}
          {/*begin::Pic*/}
          <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
            <img src="assets/media/stock/600x400/img-27.jpg" alt="" />
          </div>
          {/*end::Pic*/}
        </div>
        {/*end::Item*/}
      </div>
      {/*end::Card body*/}
      {/*begin::Card footer*/}
      <div className="card-footer">
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          <span className="fw-bold text-gray-600">Total</span>
          <span className="text-gray-800 fw-bolder fs-5">$ 1840.00</span>
        </div>
        {/*end::Item*/}
        {/*begin::Item*/}
        <div className="d-flex flex-stack">
          <span className="fw-bold text-gray-600">Sub total</span>
          <span className="text-primary fw-bolder fs-5">$ 246.35</span>
        </div>
        {/*end::Item*/}
        {/*end::Action*/}
        <div className="d-flex justify-content-end mt-9">
          <a href="#" className="btn btn-primary d-flex justify-content-end">
            Pleace Order
          </a>
        </div>
        {/*end::Action*/}
      </div>
      {/*end::Card footer*/}
    </div>
    {/*end::Messenger*/}
  </div>
  {/*end::Chat drawer*/}
  {/*end::Drawers*/}
  {/*end::Main*/}
  {/*begin::Scrolltop*/}
  <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
    <i className="ki-duotone ki-arrow-up">
      <span className="path1" />
      <span className="path2" />
    </i>
  </div>
  {/*end::Scrolltop*/}
  {/*begin::Modals*/}
  {/*begin::Modal - Upgrade plan*/}
  <div
    className="modal fade"
    id="kt_modal_upgrade_plan"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog modal-xl">
      {/*begin::Modal content*/}
      <div className="modal-content rounded">
        {/*begin::Modal header*/}
        <div className="modal-header justify-content-end border-0 pb-0">
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Modal header*/}
        {/*begin::Modal body*/}
        <div className="modal-body pt-0 pb-15 px-5 px-xl-20">
          {/*begin::Heading*/}
          <div className="mb-13 text-center">
            <h1 className="mb-3">Upgrade a Plan</h1>
            <div className="text-muted fw-semibold fs-5">
              If you need more info, please check
              <a href="#" className="link-primary fw-bold">
                Pricing Guidelines
              </a>
              .
            </div>
          </div>
          {/*end::Heading*/}
          {/*begin::Plans*/}
          <div className="d-flex flex-column">
            {/*begin::Nav group*/}
            <div
              className="nav-group nav-group-outline mx-auto"
              data-kt-buttons="true"
            >
              <button
                className="btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3 me-2 active"
                data-kt-plan="month"
              >
                Monthly
              </button>
              <button
                className="btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3"
                data-kt-plan="annual"
              >
                Annual
              </button>
            </div>
            {/*end::Nav group*/}
            {/*begin::Row*/}
            <div className="row mt-10">
              {/*begin::Col*/}
              <div className="col-lg-6 mb-10 mb-lg-0">
                {/*begin::Tabs*/}
                <div className="nav flex-column">
                  {/*begin::Tab link*/}
                  <label
                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 active mb-6"
                    data-bs-toggle="tab"
                    data-bs-target="#kt_upgrade_plan_startup"
                  >
                    {/*end::Description*/}
                    <div className="d-flex align-items-center me-2">
                      {/*begin::Radio*/}
                      <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan"
                          defaultChecked="checked"
                          defaultValue="startup"
                        />
                      </div>
                      {/*end::Radio*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                          Startup
                        </div>
                        <div className="fw-semibold opacity-75">
                          Best for startups
                        </div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Description*/}
                    {/*begin::Price*/}
                    <div className="ms-5">
                      <span className="mb-2">$</span>
                      <span
                        className="fs-3x fw-bold"
                        data-kt-plan-price-month={39}
                        data-kt-plan-price-annual={399}
                      >
                        39
                      </span>
                      <span className="fs-7 opacity-50">
                        /<span data-kt-element="period">Mon</span>
                      </span>
                    </div>
                    {/*end::Price*/}
                  </label>
                  {/*end::Tab link*/}
                  {/*begin::Tab link*/}
                  <label
                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                    data-bs-toggle="tab"
                    data-bs-target="#kt_upgrade_plan_advanced"
                  >
                    {/*end::Description*/}
                    <div className="d-flex align-items-center me-2">
                      {/*begin::Radio*/}
                      <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan"
                          defaultValue="advanced"
                        />
                      </div>
                      {/*end::Radio*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                          Advanced
                        </div>
                        <div className="fw-semibold opacity-75">
                          Best for 100+ team size
                        </div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Description*/}
                    {/*begin::Price*/}
                    <div className="ms-5">
                      <span className="mb-2">$</span>
                      <span
                        className="fs-3x fw-bold"
                        data-kt-plan-price-month={339}
                        data-kt-plan-price-annual={3399}
                      >
                        339
                      </span>
                      <span className="fs-7 opacity-50">
                        /<span data-kt-element="period">Mon</span>
                      </span>
                    </div>
                    {/*end::Price*/}
                  </label>
                  {/*end::Tab link*/}
                  {/*begin::Tab link*/}
                  <label
                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                    data-bs-toggle="tab"
                    data-bs-target="#kt_upgrade_plan_enterprise"
                  >
                    {/*end::Description*/}
                    <div className="d-flex align-items-center me-2">
                      {/*begin::Radio*/}
                      <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan"
                          defaultValue="enterprise"
                        />
                      </div>
                      {/*end::Radio*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                          Enterprise
                          <span className="badge badge-light-success ms-2 py-2 px-3 fs-7">
                            Popular
                          </span>
                        </div>
                        <div className="fw-semibold opacity-75">
                          Best defaultValue for 1000+ team
                        </div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Description*/}
                    {/*begin::Price*/}
                    <div className="ms-5">
                      <span className="mb-2">$</span>
                      <span
                        className="fs-3x fw-bold"
                        data-kt-plan-price-month={999}
                        data-kt-plan-price-annual={9999}
                      >
                        999
                      </span>
                      <span className="fs-7 opacity-50">
                        /<span data-kt-element="period">Mon</span>
                      </span>
                    </div>
                    {/*end::Price*/}
                  </label>
                  {/*end::Tab link*/}
                  {/*begin::Tab link*/}
                  <label
                    className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6"
                    data-bs-toggle="tab"
                    data-bs-target="#kt_upgrade_plan_custom"
                  >
                    {/*end::Description*/}
                    <div className="d-flex align-items-center me-2">
                      {/*begin::Radio*/}
                      <div className="form-check form-check-custom form-check-solid form-check-success flex-shrink-0 me-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="plan"
                          defaultValue="custom"
                        />
                      </div>
                      {/*end::Radio*/}
                      {/*begin::Info*/}
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
                          Custom
                        </div>
                        <div className="fw-semibold opacity-75">
                          Requet a custom license
                        </div>
                      </div>
                      {/*end::Info*/}
                    </div>
                    {/*end::Description*/}
                    {/*begin::Price*/}
                    <div className="ms-5">
                      <a href="#" className="btn btn-sm btn-success">
                        Contact Us
                      </a>
                    </div>
                    {/*end::Price*/}
                  </label>
                  {/*end::Tab link*/}
                </div>
                {/*end::Tabs*/}
              </div>
              {/*end::Col*/}
              {/*begin::Col*/}
              <div className="col-lg-6">
                {/*begin::Tab content*/}
                <div className="tab-content rounded h-100 bg-light p-10">
                  {/*begin::Tab Pane*/}
                  <div
                    className="tab-pane fade show active"
                    id="kt_upgrade_plan_startup"
                  >
                    {/*begin::Heading*/}
                    <div className="pb-5">
                      <h2 className="fw-bold text-gray-900">
                        What’s in Startup Plan?
                      </h2>
                      <div className="text-muted fw-semibold">
                        Optimal for 10+ team size and new startup
                      </div>
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Body*/}
                    <div className="pt-1">
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Up to 10 Active Users
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Up to 30 Project Integrations
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Analytics Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-muted flex-grow-1">
                          Finance Module
                        </span>
                        <i className="ki-duotone ki-cross-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-muted flex-grow-1">
                          Accounting Module
                        </span>
                        <i className="ki-duotone ki-cross-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-muted flex-grow-1">
                          Network Platform
                        </span>
                        <i className="ki-duotone ki-cross-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        <span className="fw-semibold fs-5 text-muted flex-grow-1">
                          Unlimited Cloud Space
                        </span>
                        <i className="ki-duotone ki-cross-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Tab Pane*/}
                  {/*begin::Tab Pane*/}
                  <div className="tab-pane fade" id="kt_upgrade_plan_advanced">
                    {/*begin::Heading*/}
                    <div className="pb-5">
                      <h2 className="fw-bold text-gray-900">
                        What’s in Startup Plan?
                      </h2>
                      <div className="text-muted fw-semibold">
                        Optimal for 100+ team size and grown company
                      </div>
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Body*/}
                    <div className="pt-1">
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Up to 10 Active Users
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Up to 30 Project Integrations
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Analytics Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Finance Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Accounting Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-muted flex-grow-1">
                          Network Platform
                        </span>
                        <i className="ki-duotone ki-cross-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        <span className="fw-semibold fs-5 text-muted flex-grow-1">
                          Unlimited Cloud Space
                        </span>
                        <i className="ki-duotone ki-cross-circle fs-1">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Tab Pane*/}
                  {/*begin::Tab Pane*/}
                  <div
                    className="tab-pane fade"
                    id="kt_upgrade_plan_enterprise"
                  >
                    {/*begin::Heading*/}
                    <div className="pb-5">
                      <h2 className="fw-bold text-gray-900">
                        What’s in Startup Plan?
                      </h2>
                      <div className="text-muted fw-semibold">
                        Optimal for 1000+ team and enterpise
                      </div>
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Body*/}
                    <div className="pt-1">
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Up to 10 Active Users
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Up to 30 Project Integrations
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Analytics Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Finance Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Accounting Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Network Platform
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Unlimited Cloud Space
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Tab Pane*/}
                  {/*begin::Tab Pane*/}
                  <div className="tab-pane fade" id="kt_upgrade_plan_custom">
                    {/*begin::Heading*/}
                    <div className="pb-5">
                      <h2 className="fw-bold text-gray-900">
                        What’s in Startup Plan?
                      </h2>
                      <div className="text-muted fw-semibold">
                        Optimal for corporations
                      </div>
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Body*/}
                    <div className="pt-1">
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Unlimited Users
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Unlimited Project Integrations
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Analytics Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Finance Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Accounting Module
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center mb-7">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Network Platform
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <div className="d-flex align-items-center">
                        <span className="fw-semibold fs-5 text-gray-700 flex-grow-1">
                          Unlimited Cloud Space
                        </span>
                        <i className="ki-duotone ki-check-circle fs-1 text-success">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </div>
                      {/*end::Item*/}
                    </div>
                    {/*end::Body*/}
                  </div>
                  {/*end::Tab Pane*/}
                </div>
                {/*end::Tab content*/}
              </div>
              {/*end::Col*/}
            </div>
            {/*end::Row*/}
          </div>
          {/*end::Plans*/}
          {/*begin::Actions*/}
          <div className="d-flex flex-center flex-row-fluid pt-12">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              id="kt_modal_upgrade_plan_btn"
            >
              {/*begin::Indicator label*/}
              <span className="indicator-label">Upgrade Plan</span>
              {/*end::Indicator label*/}
              {/*begin::Indicator progress*/}
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
              {/*end::Indicator progress*/}
            </button>
          </div>
          {/*end::Actions*/}
        </div>
        {/*end::Modal body*/}
      </div>
      {/*end::Modal content*/}
    </div>
    {/*end::Modal dialog*/}
  </div>
  {/*end::Modal - Upgrade plan*/}
  {/*begin::Modal - Create App*/}
  <div
    className="modal fade"
    id="kt_modal_create_app"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog modal-dialog-centered mw-900px">
      {/*begin::Modal content*/}
      <div className="modal-content">
        {/*begin::Modal header*/}
        <div className="modal-header">
          {/*begin::Modal title*/}
          <h2>Create App</h2>
          {/*end::Modal title*/}
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Modal header*/}
        {/*begin::Modal body*/}
        <div className="modal-body py-lg-10 px-lg-10">
          {/*begin::Stepper*/}
          <div
            className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
            id="kt_modal_create_app_stepper"
          >
            {/*begin::Aside*/}
            <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
              {/*begin::Nav*/}
              <div className="stepper-nav ps-lg-10">
                {/*begin::Step 1*/}
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  {/*begin::Wrapper*/}
                  <div className="stepper-wrapper">
                    {/*begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="ki-duotone ki-check stepper-check fs-2" />
                      <span className="stepper-number">1</span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Label*/}
                    <div className="stepper-label">
                      <h3 className="stepper-title">Details</h3>
                      <div className="stepper-desc">Name your App</div>
                    </div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Line*/}
                  <div className="stepper-line h-40px" />
                  {/*end::Line*/}
                </div>
                {/*end::Step 1*/}
                {/*begin::Step 2*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  {/*begin::Wrapper*/}
                  <div className="stepper-wrapper">
                    {/*begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="ki-duotone ki-check stepper-check fs-2" />
                      <span className="stepper-number">2</span>
                    </div>
                    {/*begin::Icon*/}
                    {/*begin::Label*/}
                    <div className="stepper-label">
                      <h3 className="stepper-title">Frameworks</h3>
                      <div className="stepper-desc">
                        Define your app framework
                      </div>
                    </div>
                    {/*begin::Label*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Line*/}
                  <div className="stepper-line h-40px" />
                  {/*end::Line*/}
                </div>
                {/*end::Step 2*/}
                {/*begin::Step 3*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  {/*begin::Wrapper*/}
                  <div className="stepper-wrapper">
                    {/*begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="ki-duotone ki-check stepper-check fs-2" />
                      <span className="stepper-number">3</span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Label*/}
                    <div className="stepper-label">
                      <h3 className="stepper-title">Database</h3>
                      <div className="stepper-desc">
                        Select the app database type
                      </div>
                    </div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Line*/}
                  <div className="stepper-line h-40px" />
                  {/*end::Line*/}
                </div>
                {/*end::Step 3*/}
                {/*begin::Step 4*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  {/*begin::Wrapper*/}
                  <div className="stepper-wrapper">
                    {/*begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="ki-duotone ki-check stepper-check fs-2" />
                      <span className="stepper-number">4</span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Label*/}
                    <div className="stepper-label">
                      <h3 className="stepper-title">Billing</h3>
                      <div className="stepper-desc">
                        Provide payment details
                      </div>
                    </div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Line*/}
                  <div className="stepper-line h-40px" />
                  {/*end::Line*/}
                </div>
                {/*end::Step 4*/}
                {/*begin::Step 5*/}
                <div
                  className="stepper-item mark-completed"
                  data-kt-stepper-element="nav"
                >
                  {/*begin::Wrapper*/}
                  <div className="stepper-wrapper">
                    {/*begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="ki-duotone ki-check stepper-check fs-2" />
                      <span className="stepper-number">5</span>
                    </div>
                    {/*end::Icon*/}
                    {/*begin::Label*/}
                    <div className="stepper-label">
                      <h3 className="stepper-title">Completed</h3>
                      <div className="stepper-desc">Review and Submit</div>
                    </div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Step 5*/}
              </div>
              {/*end::Nav*/}
            </div>
            {/*begin::Aside*/}
            {/*begin::Content*/}
            <div className="flex-row-fluid py-lg-5 px-lg-15">
              {/*begin::Form*/}
              <form
                className="form"
                noValidate="novalidate"
                id="kt_modal_create_app_form"
              >
                {/*begin::Step 1*/}
                <div className="current" data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Input group*/}
                    <div className="fv-row mb-10">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                        <span className="required">App Name</span>
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Specify your unique app name"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="name"
                        placeholder=""
                        defaultValue=""
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                        <span className="required">Category</span>
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Select your app category"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin:Options*/}
                      <div className="fv-row">
                        {/*begin:Option*/}
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          {/*begin:Label*/}
                          <span className="d-flex align-items-center me-2">
                            {/*begin:Icon*/}
                            <span className="symbol symbol-50px me-6">
                              <span className="symbol-label bg-light-primary">
                                <i className="ki-duotone ki-compass fs-1 text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </span>
                            </span>
                            {/*end:Icon*/}
                            {/*begin:Info*/}
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Quick Online Courses
                              </span>
                              <span className="fs-7 text-muted">
                                Creating a clear text structure is just one SEO
                              </span>
                            </span>
                            {/*end:Info*/}
                          </span>
                          {/*end:Label*/}
                          {/*begin:Input*/}
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="category"
                              defaultValue={1}
                            />
                          </span>
                          {/*end:Input*/}
                        </label>
                        {/*end::Option*/}
                        {/*begin:Option*/}
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
                          {/*begin:Label*/}
                          <span className="d-flex align-items-center me-2">
                            {/*begin:Icon*/}
                            <span className="symbol symbol-50px me-6">
                              <span className="symbol-label bg-light-danger">
                                <i className="ki-duotone ki-element-11 fs-1 text-danger">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                </i>
                              </span>
                            </span>
                            {/*end:Icon*/}
                            {/*begin:Info*/}
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Face to Face Discussions
                              </span>
                              <span className="fs-7 text-muted">
                                Creating a clear text structure is just one
                                aspect
                              </span>
                            </span>
                            {/*end:Info*/}
                          </span>
                          {/*end:Label*/}
                          {/*begin:Input*/}
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="category"
                              defaultValue={2}
                            />
                          </span>
                          {/*end:Input*/}
                        </label>
                        {/*end::Option*/}
                        {/*begin:Option*/}
                        <label className="d-flex flex-stack cursor-pointer">
                          {/*begin:Label*/}
                          <span className="d-flex align-items-center me-2">
                            {/*begin:Icon*/}
                            <span className="symbol symbol-50px me-6">
                              <span className="symbol-label bg-light-success">
                                <i className="ki-duotone ki-timer fs-1 text-success">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                </i>
                              </span>
                            </span>
                            {/*end:Icon*/}
                            {/*begin:Info*/}
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Full Intro Training
                              </span>
                              <span className="fs-7 text-muted">
                                Creating a clear text structure copywriting
                              </span>
                            </span>
                            {/*end:Info*/}
                          </span>
                          {/*end:Label*/}
                          {/*begin:Input*/}
                          <span className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="category"
                              defaultValue={3}
                            />
                          </span>
                          {/*end:Input*/}
                        </label>
                        {/*end::Option*/}
                      </div>
                      {/*end:Options*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
                {/*end::Step 1*/}
                {/*begin::Step 2*/}
                <div data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Input group*/}
                    <div className="fv-row">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                        <span className="required">Select Framework</span>
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Specify your apps framework"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        {/*begin:Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin:Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-warning">
                              <i className="ki-duotone ki-html fs-2x text-warning">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                          </span>
                          {/*end:Icon*/}
                          {/*begin:Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">HTML5</span>
                            <span className="fs-7 text-muted">
                              Base Web Projec
                            </span>
                          </span>
                          {/*end:Info*/}
                        </span>
                        {/*end:Label*/}
                        {/*begin:Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            defaultChecked="checked"
                            name="framework"
                            defaultValue={1}
                          />
                        </span>
                        {/*end:Input*/}
                      </label>
                      {/*end::Option*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        {/*begin:Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin:Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-success">
                              <i className="ki-duotone ki-react fs-2x text-success">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                          </span>
                          {/*end:Icon*/}
                          {/*begin:Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">ReactJS</span>
                            <span className="fs-7 text-muted">
                              Robust and flexible app framework
                            </span>
                          </span>
                          {/*end:Info*/}
                        </span>
                        {/*end:Label*/}
                        {/*begin:Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="framework"
                            defaultValue={2}
                          />
                        </span>
                        {/*end:Input*/}
                      </label>
                      {/*end::Option*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        {/*begin:Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin:Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-danger">
                              <i className="ki-duotone ki-angular fs-2x text-danger">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                              </i>
                            </span>
                          </span>
                          {/*end:Icon*/}
                          {/*begin:Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">Angular</span>
                            <span className="fs-7 text-muted">
                              Powerful data mangement
                            </span>
                          </span>
                          {/*end:Info*/}
                        </span>
                        {/*end:Label*/}
                        {/*begin:Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="framework"
                            defaultValue={3}
                          />
                        </span>
                        {/*end:Input*/}
                      </label>
                      {/*end::Option*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer">
                        {/*begin:Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin:Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-primary">
                              <i className="ki-duotone ki-vue fs-2x text-primary">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                          </span>
                          {/*end:Icon*/}
                          {/*begin:Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">Vue</span>
                            <span className="fs-7 text-muted">
                              Lightweight and responsive framework
                            </span>
                          </span>
                          {/*end:Info*/}
                        </span>
                        {/*end:Label*/}
                        {/*begin:Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="framework"
                            defaultValue={4}
                          />
                        </span>
                        {/*end:Input*/}
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
                {/*end::Step 2*/}
                {/*begin::Step 3*/}
                <div data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Input group*/}
                    <div className="fv-row mb-10">
                      {/*begin::Label*/}
                      <label className="required fs-5 fw-semibold mb-2">
                        Database Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        name="dbname"
                        placeholder=""
                        defaultValue="master_db"
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
                        <span className="required">Select Database Engine</span>
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Select your app database engine"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        {/*begin::Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin::Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-success">
                              <i className="ki-duotone ki-note text-success fs-2x">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                          </span>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">MySQL</span>
                            <span className="fs-7 text-muted">
                              Basic MySQL database
                            </span>
                          </span>
                          {/*end::Info*/}
                        </span>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="dbengine"
                            defaultChecked="checked"
                            defaultValue={1}
                          />
                        </span>
                        {/*end::Input*/}
                      </label>
                      {/*end::Option*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer mb-5">
                        {/*begin::Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin::Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-danger">
                              <i className="ki-duotone ki-google text-danger fs-2x">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </span>
                          </span>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">Firebase</span>
                            <span className="fs-7 text-muted">
                              Google based app data management
                            </span>
                          </span>
                          {/*end::Info*/}
                        </span>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="dbengine"
                            defaultValue={2}
                          />
                        </span>
                        {/*end::Input*/}
                      </label>
                      {/*end::Option*/}
                      {/*begin:Option*/}
                      <label className="d-flex flex-stack cursor-pointer">
                        {/*begin::Label*/}
                        <span className="d-flex align-items-center me-2">
                          {/*begin::Icon*/}
                          <span className="symbol symbol-50px me-6">
                            <span className="symbol-label bg-light-warning">
                              <i className="ki-duotone ki-microsoft text-warning fs-2x">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                              </i>
                            </span>
                          </span>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <span className="d-flex flex-column">
                            <span className="fw-bold fs-6">DynamoDB</span>
                            <span className="fs-7 text-muted">
                              Microsoft Fast NoSQL Database
                            </span>
                          </span>
                          {/*end::Info*/}
                        </span>
                        {/*end::Label*/}
                        {/*begin::Input*/}
                        <span className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="dbengine"
                            defaultValue={3}
                          />
                        </span>
                        {/*end::Input*/}
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
                {/*end::Step 3*/}
                {/*begin::Step 4*/}
                <div data-kt-stepper-element="content">
                  <div className="w-100">
                    {/*begin::Input group*/}
                    <div className="d-flex flex-column mb-7 fv-row">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                        <span className="required">Name On Card</span>
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Specify a card holder's name"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        defaultValue="Max Doe"
                      />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="d-flex flex-column mb-7 fv-row">
                      {/*begin::Label*/}
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Card Number
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input wrapper*/}
                      <div className="position-relative">
                        {/*begin::Input*/}
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Enter card number"
                          name="card_number"
                          defaultValue="4111 1111 1111 1111"
                        />
                        {/*end::Input*/}
                        {/*begin::Card logos*/}
                        <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                          <img
                            src="assets/media/svg/card-logos/visa.svg"
                            alt=""
                            className="h-25px"
                          />
                          <img
                            src="assets/media/svg/card-logos/mastercard.svg"
                            alt=""
                            className="h-25px"
                          />
                          <img
                            src="assets/media/svg/card-logos/american-express.svg"
                            alt=""
                            className="h-25px"
                          />
                        </div>
                        {/*end::Card logos*/}
                      </div>
                      {/*end::Input wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row mb-10">
                      {/*begin::Col*/}
                      <div className="col-md-8 fv-row">
                        {/*begin::Label*/}
                        <label className="required fs-6 fw-semibold form-label mb-2">
                          Expiration Date
                        </label>
                        {/*end::Label*/}
                        {/*begin::Row*/}
                        <div className="row fv-row">
                          {/*begin::Col*/}
                          <div className="col-6">
                            <select
                              name="card_expiry_month"
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Month"
                            >
                              <option />
                              <option defaultValue={1}>1</option>
                              <option defaultValue={2}>2</option>
                              <option defaultValue={3}>3</option>
                              <option defaultValue={4}>4</option>
                              <option defaultValue={5}>5</option>
                              <option defaultValue={6}>6</option>
                              <option defaultValue={7}>7</option>
                              <option defaultValue={8}>8</option>
                              <option defaultValue={9}>9</option>
                              <option defaultValue={10}>10</option>
                              <option defaultValue={11}>11</option>
                              <option defaultValue={12}>12</option>
                            </select>
                          </div>
                          {/*end::Col*/}
                          {/*begin::Col*/}
                          <div className="col-6">
                            <select
                              name="card_expiry_year"
                              className="form-select form-select-solid"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Year"
                            >
                              <option />
                              <option defaultValue={2024}>2024</option>
                              <option defaultValue={2025}>2025</option>
                              <option defaultValue={2026}>2026</option>
                              <option defaultValue={2027}>2027</option>
                              <option defaultValue={2028}>2028</option>
                              <option defaultValue={2029}>2029</option>
                              <option defaultValue={2030}>2030</option>
                              <option defaultValue={2031}>2031</option>
                              <option defaultValue={2032}>2032</option>
                              <option defaultValue={2033}>2033</option>
                              <option defaultValue={2034}>2034</option>
                            </select>
                          </div>
                          {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-md-4 fv-row">
                        {/*begin::Label*/}
                        <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                          <span className="required">CVV</span>
                          <span
                            className="ms-1"
                            data-bs-toggle="tooltip"
                            title="Enter a card CVV code"
                          >
                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                              <span className="path1" />
                              <span className="path2" />
                              <span className="path3" />
                            </i>
                          </span>
                        </label>
                        {/*end::Label*/}
                        {/*begin::Input wrapper*/}
                        <div className="position-relative">
                          {/*begin::Input*/}
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            minLength={3}
                            maxLength={4}
                            placeholder="CVV"
                            name="card_cvv"
                          />
                          {/*end::Input*/}
                          {/*begin::CVV icon*/}
                          <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                            <i className="ki-duotone ki-credit-cart fs-2hx">
                              <span className="path1" />
                              <span className="path2" />
                            </i>
                          </div>
                          {/*end::CVV icon*/}
                        </div>
                        {/*end::Input wrapper*/}
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="d-flex flex-stack">
                      {/*begin::Label*/}
                      <div className="me-5">
                        <label className="fs-6 fw-semibold form-label">
                          Save Card for further billing?
                        </label>
                        <div className="fs-7 fw-semibold text-muted">
                          If you need more info, please check budget planning
                        </div>
                      </div>
                      {/*end::Label*/}
                      {/*begin::Switch*/}
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue={1}
                          defaultChecked="checked"
                        />
                        <span className="form-check-label fw-semibold text-muted">
                          Save Card
                        </span>
                      </label>
                      {/*end::Switch*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                </div>
                {/*end::Step 4*/}
                {/*begin::Step 5*/}
                <div data-kt-stepper-element="content">
                  <div className="w-100 text-center">
                    {/*begin::Heading*/}
                    <h1 className="fw-bold text-gray-900 mb-3">Release!</h1>
                    {/*end::Heading*/}
                    {/*begin::Description*/}
                    <div className="text-muted fw-semibold fs-3">
                      Submit your app to kickstart your project.
                    </div>
                    {/*end::Description*/}
                    {/*begin::Illustration*/}
                    <div className="text-center px-4 py-15">
                      <img
                        src="assets/media/illustrations/sigma-1/9.png"
                        alt=""
                        className="mw-100 mh-300px"
                      />
                    </div>
                    {/*end::Illustration*/}
                  </div>
                </div>
                {/*end::Step 5*/}
                {/*begin::Actions*/}
                <div className="d-flex flex-stack pt-10">
                  {/*begin::Wrapper*/}
                  <div className="me-2">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <i className="ki-duotone ki-arrow-left fs-3 me-1">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      Back
                    </button>
                  </div>
                  {/*end::Wrapper*/}
                  {/*begin::Wrapper*/}
                  <div>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      data-kt-stepper-action="submit"
                    >
                      <span className="indicator-label">
                        Submit
                        <i className="ki-duotone ki-arrow-right fs-3 ms-2 me-0">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                      </span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      data-kt-stepper-action="next"
                    >
                      Continue
                      <i className="ki-duotone ki-arrow-right fs-3 ms-1 me-0">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                    </button>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Actions*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Stepper*/}
        </div>
        {/*end::Modal body*/}
      </div>
      {/*end::Modal content*/}
    </div>
    {/*end::Modal dialog*/}
  </div>
  {/*end::Modal - Create App*/}
  {/*begin::Modal - View Users*/}
  <div
    className="modal fade"
    id="kt_modal_view_users"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog mw-650px">
      {/*begin::Modal content*/}
      <div className="modal-content">
        {/*begin::Modal header*/}
        <div className="modal-header pb-0 border-0 justify-content-end">
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*begin::Modal header*/}
        {/*begin::Modal body*/}
        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          {/*begin::Heading*/}
          <div className="text-center mb-13">
            {/*begin::Title*/}
            <h1 className="mb-3">Browse Users</h1>
            {/*end::Title*/}
            {/*begin::Description*/}
            <div className="text-muted fw-semibold fs-5">
              If you need more info, please check out our
              <a href="#" className="link-primary fw-bold">
                Users Directory
              </a>
              .
            </div>
            {/*end::Description*/}
          </div>
          {/*end::Heading*/}
          {/*begin::Users*/}
          <div className="mb-15">
            {/*begin::List*/}
            <div className="mh-375px scroll-y me-n7 pe-7">
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Emma Smith
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Art Director
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">smith@kpmg.com</div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$23,000</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-danger text-danger fw-semibold">
                      M
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Melody Macy
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Marketing Analytic
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      melody@altbox.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$50,500</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Max Smith
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Software Enginer
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">max@kt.com</div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$75,900</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Sean Bean
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Web Developer
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      sean@dellito.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$10,500</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Brian Cox
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        UI/UX Designer
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      brian@exchange.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$20,000</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-warning text-warning fw-semibold">
                      C
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Mikaela Collins
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Head Of Marketing
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">mik@pex.com</div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$9,300</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Francis Mitcham
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Software Arcitect
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">f.mit@kpmg.com</div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$15,000</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-danger text-danger fw-semibold">
                      O
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Olivia Wild
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        System Admin
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      olivia@corpmail.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$23,000</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-primary text-primary fw-semibold">
                      N
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Neil Owen
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Account Manager
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      owen.neil@gmail.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$45,800</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Dan Wilson
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Web Desinger
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      dam@consilting.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$90,500</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-danger text-danger fw-semibold">
                      E
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Emma Bold
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Corporate Finance
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      emma@intenso.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$5,000</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Ana Crown
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Customer Relationship
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      ana.cf@limtel.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$70,000</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-5">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-info text-info fw-semibold">
                      A
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-6">
                    {/*begin::Name*/}
                    <a
                      href="#"
                      className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary"
                    >
                      Robert Doe
                      <span className="badge badge-light fs-8 fw-semibold ms-2">
                        Marketing Executive
                      </span>
                    </a>
                    {/*end::Name*/}
                    {/*begin::Email*/}
                    <div className="fw-semibold text-muted">
                      robert@benko.com
                    </div>
                    {/*end::Email*/}
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Stats*/}
                <div className="d-flex">
                  {/*begin::Sales*/}
                  <div className="text-end">
                    <div className="fs-5 fw-bold text-gray-900">$45,500</div>
                    <div className="fs-7 text-muted">Sales</div>
                  </div>
                  {/*end::Sales*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::User*/}
            </div>
            {/*end::List*/}
          </div>
          {/*end::Users*/}
          {/*begin::Notice*/}
          <div className="d-flex justify-content-between">
            {/*begin::Label*/}
            <div className="fw-semibold">
              <label className="fs-6">Adding Users by Team Members</label>
              <div className="fs-7 text-muted">
                If you need more info, please check budget planning
              </div>
            </div>
            {/*end::Label*/}
            {/*begin::Switch*/}
            <label className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue=""
                defaultChecked="checked"
              />
              <span className="form-check-label fw-semibold text-muted">
                Allowed
              </span>
            </label>
            {/*end::Switch*/}
          </div>
          {/*end::Notice*/}
        </div>
        {/*end::Modal body*/}
      </div>
      {/*end::Modal content*/}
    </div>
    {/*end::Modal dialog*/}
  </div>
  {/*end::Modal - View Users*/}
  {/*begin::Modal - Create Project*/}
  <div
    className="modal fade"
    id="kt_modal_create_project"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog modal-fullscreen p-9">
      {/*begin::Modal content*/}
      <div className="modal-content modal-rounded">
        {/*begin::Modal header*/}
        <div className="modal-header">
          {/*begin::Modal title*/}
          <h2>Create Project</h2>
          {/*end::Modal title*/}
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Modal header*/}
        {/*begin::Modal body*/}
        <div className="modal-body scroll-y m-5">
          {/*begin::Stepper*/}
          <div
            className="stepper stepper-links d-flex flex-column"
            id="kt_modal_create_project_stepper"
          >
            {/*begin::Container*/}
            <div className="container">
              {/*begin::Nav*/}
              <div className="stepper-nav justify-content-center py-2">
                {/*begin::Step 1*/}
                <div
                  className="stepper-item me-5 me-md-15 current"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Project Type</h3>
                </div>
                {/*end::Step 1*/}
                {/*begin::Step 2*/}
                <div
                  className="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Project Settings</h3>
                </div>
                {/*end::Step 2*/}
                {/*begin::Step 3*/}
                <div
                  className="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Budget</h3>
                </div>
                {/*end::Step 3*/}
                {/*begin::Step 4*/}
                <div
                  className="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Build A Team</h3>
                </div>
                {/*end::Step 4*/}
                {/*begin::Step 5*/}
                <div
                  className="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Set First Target</h3>
                </div>
                {/*end::Step 5*/}
                {/*begin::Step 6*/}
                <div
                  className="stepper-item me-5 me-md-15"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">Upload Files</h3>
                </div>
                {/*end::Step 6*/}
                {/*begin::Step 7*/}
                <div className="stepper-item" data-kt-stepper-element="nav">
                  <h3 className="stepper-title">Completed</h3>
                </div>
                {/*end::Step 7*/}
              </div>
              {/*end::Nav*/}
              {/*begin::Form*/}
              <form
                className="mx-auto w-100 mw-600px pt-15 pb-10"
                noValidate="novalidate"
                id="kt_modal_create_project_form"
                method="post"
              >
                {/*begin::Type*/}
                <div className="current" data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-7 pb-lg-12">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">Project Type</h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check out
                        <a href="#" className="link-primary fw-bold">
                          FAQ Page
                        </a>
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-15" data-kt-buttons="true">
                      {/*begin::Option*/}
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6 mb-6 active">
                        {/*begin::Input*/}
                        <input
                          className="btn-check"
                          type="radio"
                          defaultChecked="checked"
                          name="project_type"
                          defaultValue={1}
                        />
                        {/*end::Input*/}
                        {/*begin::Label*/}
                        <span className="d-flex">
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-profile-circle fs-3hx">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <span className="ms-4">
                            <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                              Personal Project
                            </span>
                            <span className="fw-semibold fs-4 text-muted">
                              If you need more info, please check it out
                            </span>
                          </span>
                          {/*end::Info*/}
                        </span>
                        {/*end::Label*/}
                      </label>
                      {/*end::Option*/}
                      {/*begin::Option*/}
                      <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6">
                        {/*begin::Input*/}
                        <input
                          className="btn-check"
                          type="radio"
                          name="project_type"
                          defaultValue={2}
                        />
                        {/*end::Input*/}
                        {/*begin::Label*/}
                        <span className="d-flex">
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-rocket fs-3hx">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <span className="ms-4">
                            <span className="fs-3 fw-bold text-gray-900 mb-2 d-block">
                              Team Project
                            </span>
                            <span className="fw-semibold fs-4 text-muted">
                              Create corporate account to manage users
                            </span>
                          </span>
                          {/*end::Info*/}
                        </span>
                        {/*end::Label*/}
                      </label>
                      {/*end::Option*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-kt-element="type-next"
                      >
                        <span className="indicator-label">
                          Project Settings
                        </span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Type*/}
                {/*begin::Settings*/}
                <div data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-12">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">
                        Project Settings
                      </h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check
                        <a href="#" className="link-primary">
                          Project Guidelines
                        </a>
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Dropzone*/}
                      <div
                        className="dropzone"
                        id="kt_modal_create_project_settings_logo"
                      >
                        {/*begin::Message*/}
                        <div className="dz-message needsclick">
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-file-up fs-3hx text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <div className="ms-4">
                            <h3 className="dfs-3 fw-bold text-gray-900 mb-1">
                              Drop files here or click to upload.
                            </h3>
                            <span className="fw-semibold fs-4 text-muted">
                              Upload up to 10 files
                            </span>
                          </div>
                          {/*end::Info*/}
                        </div>
                      </div>
                      {/*end::Dropzone*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Label*/}
                      <label className="required fs-6 fw-semibold mb-2">
                        Customer
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <select
                        className="form-select form-select-solid"
                        data-control="select2"
                        data-hide-search="true"
                        data-placeholder="Select..."
                        name="settings_customer"
                      >
                        <option />
                        <option defaultValue={1}>Keenthemes</option>
                        <option defaultValue={2}>CRM App</option>
                      </select>
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                        <span className="required">Project Name</span>
                        <span
                          className="ms-1"
                          data-bs-toggle="tooltip"
                          title="Specify project name"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Project Name"
                        defaultValue="StockPro Mobile App"
                        name="settings_name"
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Label*/}
                      <label className="required fs-6 fw-semibold mb-2">
                        Project Description
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <textarea
                        className="form-control form-control-solid"
                        rows={3}
                        placeholder="Enter Project Description"
                        name="settings_description"
                        defaultValue={
                          "Experience share market at your fingertips with TICK PRO stock investment mobile trading app"
                        }
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Label*/}
                      <label className="required fs-6 fw-semibold mb-2">
                        Release Date
                      </label>
                      {/*end::Label*/}
                      {/*begin::Wrapper*/}
                      <div className="position-relative d-flex align-items-center">
                        {/*begin::Icon*/}
                        <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                          <span className="path1" />
                          <span className="path2" />
                          <span className="path3" />
                          <span className="path4" />
                          <span className="path5" />
                          <span className="path6" />
                        </i>
                        {/*end::Icon*/}
                        {/*begin::Input*/}
                        <input
                          className="form-control form-control-solid ps-12"
                          placeholder="Pick date range"
                          name="settings_release_date"
                        />
                        {/*end::Input*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-15">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Label*/}
                        <div className="me-5">
                          <label className="required fs-6 fw-semibold">
                            Notifications
                          </label>
                          <div className="fs-7 fw-semibold text-muted">
                            Allow Notifications by Phone or Email
                          </div>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Checkboxes*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-10">
                            {/*begin::Input*/}
                            <input
                              className="form-check-input h-20px w-20px"
                              type="checkbox"
                              defaultValue="email"
                              name="settings_notifications[]"
                            />
                            {/*end::Input*/}
                            {/*begin::Label*/}
                            <span className="form-check-label fw-semibold">
                              Email
                            </span>
                            {/*end::Label*/}
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            {/*begin::Input*/}
                            <input
                              className="form-check-input h-20px w-20px"
                              type="checkbox"
                              defaultValue="phone"
                              defaultChecked="checked"
                              name="settings_notifications[]"
                            />
                            {/*end::Input*/}
                            {/*begin::Label*/}
                            <span className="form-check-label fw-semibold">
                              Phone
                            </span>
                            {/*end::Label*/}
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Checkboxes*/}
                      </div>
                      {/*begin::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        data-kt-element="settings-previous"
                      >
                        Project Type
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-kt-element="settings-next"
                      >
                        <span className="indicator-label">Budget</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Settings*/}
                {/*begin::Budget*/}
                <div data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-10 pb-lg-12">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">Budget</h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check
                        <a href="#" className="link-primary">
                          Project Guidelines
                        </a>
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Label*/}
                      <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                        <span className="required">Setup Budget</span>
                        <span
                          className="lh-1 ms-1"
                          data-bs-toggle="popover"
                          data-bs-trigger="hover"
                          data-bs-html="true"
                          data-bs-content="<div class='p-4 rounded bg-light'> <div class='d-flex flex-stack text-muted mb-4'> <i class=&quot;ki-duotone ki-bank fs-3 me-3&quot;><span class=&quot;path1&quot;></span><span class=&quot;path2&quot;></span></i> <div class='fw-bold'>INCBANK **** 1245 STATEMENT</div> </div> <div class='d-flex flex-stack fw-semibold text-gray-600'> <div>Amount</div> <div>Transaction</div> </div> <div class='separator separator-dashed my-2'></div> <div class='d-flex flex-stack text-gray-900 fw-bold mb-2'> <div>USD345.00</div> <div>KEENTHEMES*</div> </div> <div class='d-flex flex-stack text-muted mb-2'> <div>USD75.00</div> <div>Hosting fee</div> </div> <div class='d-flex flex-stack text-muted'> <div>USD3,950.00</div> <div>Payrol</div> </div> </div>"
                        >
                          <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </i>
                        </span>
                      </label>
                      {/*end::Label*/}
                      {/*begin::Dialer*/}
                      <div
                        className="position-relative w-lg-250px"
                        id="kt_modal_create_project_budget_setup"
                        data-kt-dialer="true"
                        data-kt-dialer-min={50}
                        data-kt-dialer-max={50000}
                        data-kt-dialer-step={100}
                        data-kt-dialer-prefix="$"
                        data-kt-dialer-decimals={2}
                      >
                        {/*begin::Decrease control*/}
                        <button
                          type="button"
                          className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                          data-kt-dialer-control="decrease"
                        >
                          <i className="ki-duotone ki-minus-circle fs-1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </button>
                        {/*end::Decrease control*/}
                        {/*begin::Input control*/}
                        <input
                          type="text"
                          className="form-control form-control-solid border-0 ps-12"
                          data-kt-dialer-control="input"
                          placeholder="Amount"
                          name="budget_setup"
                          readOnly="readonly"
                          defaultValue="$50"
                        />
                        {/*end::Input control*/}
                        {/*begin::Increase control*/}
                        <button
                          type="button"
                          className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                          data-kt-dialer-control="increase"
                        >
                          <i className="ki-duotone ki-plus-circle fs-1">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                        </button>
                        {/*end::Increase control*/}
                      </div>
                      {/*end::Dialer*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Label*/}
                      <label className="fs-6 fw-semibold mb-2">
                        Budget Usage
                      </label>
                      {/*end::Label*/}
                      {/*begin::Row*/}
                      <div
                        className="row g-9"
                        data-kt-buttons="true"
                        data-kt-buttons-target="[data-kt-button='true']"
                      >
                        {/*begin::Col*/}
                        <div className="col-md-6 col-lg-12 col-xxl-6">
                          {/*begin::Option*/}
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-6"
                            data-kt-button="true"
                          >
                            {/*begin::Radio*/}
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="budget_usage"
                                defaultValue={1}
                                defaultChecked="checked"
                              />
                            </span>
                            {/*end::Radio*/}
                            {/*begin::Info*/}
                            <span className="ms-5">
                              <span className="fs-4 fw-bold text-gray-800 mb-2 d-block">
                                Precise Usage
                              </span>
                              <span className="fw-semibold fs-7 text-gray-600">
                                Withdraw money to your bank account per
                                transaction under $50,000 budget
                              </span>
                            </span>
                            {/*end::Info*/}
                          </label>
                          {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-md-6 col-lg-12 col-xxl-6">
                          {/*begin::Option*/}
                          <label
                            className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6"
                            data-kt-button="true"
                          >
                            {/*begin::Radio*/}
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="budget_usage"
                                defaultValue={2}
                              />
                            </span>
                            {/*end::Radio*/}
                            {/*begin::Info*/}
                            <span className="ms-5">
                              <span className="fs-4 fw-bold text-gray-800 mb-2 d-block">
                                Extreme Usage
                              </span>
                              <span className="fw-semibold fs-7 text-gray-600">
                                Withdraw money to your bank account per
                                transaction under $50,000 budget
                              </span>
                            </span>
                            {/*end::Info*/}
                          </label>
                          {/*end::Option*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-15">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Label*/}
                        <div className="me-5">
                          <label className="fs-6 fw-semibold">
                            Allow Changes in Budget
                          </label>
                          <div className="fs-7 fw-semibold text-muted">
                            If you need more info, please check budget planning
                          </div>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Switch*/}
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                            name="budget_allow"
                            defaultChecked="checked"
                          />
                          <span className="form-check-label fw-semibold text-muted">
                            Allowed
                          </span>
                        </label>
                        {/*end::Switch*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        data-kt-element="budget-previous"
                      >
                        Project Settings
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-kt-element="budget-next"
                      >
                        <span className="indicator-label">Build Team</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Budget*/}
                {/*begin::Team*/}
                <div data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-12">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">Build a Team</h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check
                        <a href="#" className="link-primary">
                          Project Guidelines
                        </a>
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="mb-8">
                      {/*begin::Label*/}
                      <label className="fs-6 fw-semibold mb-2">
                        Invite Teammates
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Add project memnbers by name or email.."
                        name="invite_teammates"
                      />
                      {/*end::Input*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-8">
                      {/*begin::Label*/}
                      <div className="fs-6 fw-semibold mb-2">Team Members</div>
                      {/*end::Label*/}
                      {/*begin::Users*/}
                      <div className="mh-300px scroll-y me-n7 pe-7">
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-6.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Emma Smith
                              </a>
                              <div className="fw-semibold text-muted">
                                smith@kpmg.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2} selected="selected">
                                Owner
                              </option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-semibold">
                                M
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Melody Macy
                              </a>
                              <div className="fw-semibold text-muted">
                                melody@altbox.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1} selected="selected">
                                Guest
                              </option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-1.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Max Smith
                              </a>
                              <div className="fw-semibold text-muted">
                                max@kt.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-5.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Sean Bean
                              </a>
                              <div className="fw-semibold text-muted">
                                sean@dellito.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2} selected="selected">
                                Owner
                              </option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-25.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Brian Cox
                              </a>
                              <div className="fw-semibold text-muted">
                                brian@exchange.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-warning text-warning fw-semibold">
                                C
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Mikaela Collins
                              </a>
                              <div className="fw-semibold text-muted">
                                mik@pex.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2} selected="selected">
                                Owner
                              </option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-9.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Francis Mitcham
                              </a>
                              <div className="fw-semibold text-muted">
                                f.mit@kpmg.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-semibold">
                                O
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Olivia Wild
                              </a>
                              <div className="fw-semibold text-muted">
                                olivia@corpmail.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2} selected="selected">
                                Owner
                              </option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-primary text-primary fw-semibold">
                                N
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Neil Owen
                              </a>
                              <div className="fw-semibold text-muted">
                                owen.neil@gmail.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1} selected="selected">
                                Guest
                              </option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-23.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Dan Wilson
                              </a>
                              <div className="fw-semibold text-muted">
                                dam@consilting.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-semibold">
                                E
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Emma Bold
                              </a>
                              <div className="fw-semibold text-muted">
                                emma@intenso.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2} selected="selected">
                                Owner
                              </option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-12.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Ana Crown
                              </a>
                              <div className="fw-semibold text-muted">
                                ana.cf@limtel.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1} selected="selected">
                                Guest
                              </option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-info text-info fw-semibold">
                                A
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Robert Doe
                              </a>
                              <div className="fw-semibold text-muted">
                                robert@benko.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-13.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                John Miller
                              </a>
                              <div className="fw-semibold text-muted">
                                miller@mapple.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-success text-success fw-semibold">
                                L
                              </span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Lucy Kunic
                              </a>
                              <div className="fw-semibold text-muted">
                                lucy.m@fentech.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2} selected="selected">
                                Owner
                              </option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-21.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Ethan Wilder
                              </a>
                              <div className="fw-semibold text-muted">
                                ethan@loop.com.au
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1} selected="selected">
                                Guest
                              </option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <div className="d-flex flex-stack py-4">
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img
                                alt="Pic"
                                src="assets/media/avatars/300-1.jpg"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Max Smith
                              </a>
                              <div className="fw-semibold text-muted">
                                max@kt.com
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                            >
                              <option defaultValue={1}>Guest</option>
                              <option defaultValue={2}>Owner</option>
                              <option defaultValue={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                      </div>
                      {/*end::Users*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Notice*/}
                    <div className="d-flex flex-stack mb-15">
                      {/*begin::Label*/}
                      <div className="me-5 fw-semibold">
                        <label className="fs-6">
                          Adding Users by Team Members
                        </label>
                        <div className="fs-7 text-muted">
                          If you need more info, please check budget planning
                        </div>
                      </div>
                      {/*end::Label*/}
                      {/*begin::Switch*/}
                      <label className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          defaultChecked="checked"
                        />
                      </label>
                      {/*end::Switch*/}
                    </div>
                    {/*end::Notice*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        data-kt-element="team-previous"
                      >
                        Budget
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-kt-element="team-next"
                      >
                        <span className="indicator-label">Set Target</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Team*/}
                {/*begin::Targets*/}
                <div data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-12">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">
                        Set First Target
                      </h1>
                      {/*end::Title*/}
                      {/*begin::Title*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check
                        <a href="#" className="link-primary">
                          Project Guidelines
                        </a>
                      </div>
                      {/*end::Title*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      <label className="fs-6 fw-semibold mb-2">
                        Target Title
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Enter Target Title"
                        name="Project Launch"
                      />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="row g-9 mb-8">
                      {/*begin::Col*/}
                      <div className="col-md-6 fv-row">
                        <label className="required fs-6 fw-semibold mb-2">
                          Assign
                        </label>
                        <select
                          className="form-select form-select-solid"
                          data-control="select2"
                          data-hide-search="true"
                          data-placeholder="Select a Team Member"
                          name="target_assign"
                        >
                          <option />
                          <option defaultValue={1}>Karina Clark</option>
                          <option defaultValue={2} selected="selected">
                            Robert Doe
                          </option>
                          <option defaultValue={3}>Niel Owen</option>
                          <option defaultValue={4}>Olivia Wild</option>
                          <option defaultValue={5}>Sean Bean</option>
                        </select>
                      </div>
                      {/*end::Col*/}
                      {/*begin::Col*/}
                      <div className="col-md-6 fv-row">
                        <label className="required fs-6 fw-semibold mb-2">
                          Due Date
                        </label>
                        <div className="position-relative d-flex align-items-center">
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                            <span className="path5" />
                            <span className="path6" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Datepicker*/}
                          <input
                            className="form-control form-control-solid ps-12"
                            placeholder="Pick date range"
                            name="target_due_date"
                          />
                          {/*end::Datepicker*/}
                        </div>
                      </div>
                      {/*end::Col*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      <label className="fs-6 fw-semibold mb-2">
                        Target Details
                      </label>
                      <textarea
                        className="form-control form-control-solid"
                        rows={2}
                        name="target_details"
                        placeholder="Type Target Details"
                        defaultValue={
                          "Experience share market at your fingertips with TICK PRO stock investment mobile trading app"
                        }
                      />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      <label className="required fs-6 fw-semibold mb-2">
                        Tags
                      </label>
                      <input
                        className="form-control form-control-solid"
                        defaultValue="Important, Urgent"
                        name="target_tags"
                      />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Label*/}
                        <div className="me-5">
                          <label className="fs-6 fw-semibold">
                            Allow Changes in Budget
                          </label>
                          <div className="fs-7 fw-semibold text-muted">
                            If you need more info, please check budget planning
                          </div>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Switch*/}
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                            name="target_allow"
                            defaultChecked="checked"
                          />
                          <span className="form-check-label fw-semibold text-muted">
                            Allowed
                          </span>
                        </label>
                        {/*end::Switch*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-15">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Label*/}
                        <div className="me-5">
                          <label className="fs-6 fw-semibold">
                            Notifications
                          </label>
                          <div className="fs-7 fw-semibold text-muted">
                            Allow Notifications by Phone or Email
                          </div>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Checkboxes*/}
                        <div className="d-flex">
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid me-10">
                            {/*begin::Input*/}
                            <input
                              className="form-check-input h-20px w-20px"
                              type="checkbox"
                              defaultValue="email"
                              name="target_notifications[]"
                            />
                            {/*end::Input*/}
                            {/*begin::Label*/}
                            <span className="form-check-label fw-semibold">
                              Email
                            </span>
                            {/*end::Label*/}
                          </label>
                          {/*end::Checkbox*/}
                          {/*begin::Checkbox*/}
                          <label className="form-check form-check-custom form-check-solid">
                            {/*begin::Input*/}
                            <input
                              className="form-check-input h-20px w-20px"
                              type="checkbox"
                              defaultValue="phone"
                              defaultChecked="checked"
                              name="target_notifications[]"
                            />
                            {/*end::Input*/}
                            {/*begin::Label*/}
                            <span className="form-check-label fw-semibold">
                              Phone
                            </span>
                            {/*end::Label*/}
                          </label>
                          {/*end::Checkbox*/}
                        </div>
                        {/*end::Checkboxes*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        data-kt-element="targets-previous"
                      >
                        Build a Team
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-kt-element="targets-next"
                      >
                        <span className="indicator-label">Upload Files</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Targets*/}
                {/*begin::Files*/}
                <div data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-10 pb-lg-12">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">Upload Files</h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check
                        <a href="#" className="link-primary">
                          Project Guidelines
                        </a>
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Dropzone*/}
                      <div
                        className="dropzone"
                        id="kt_modal_create_project_files_upload"
                      >
                        {/*begin::Message*/}
                        <div className="dz-message needsclick">
                          {/*begin::Icon*/}
                          <i className="ki-duotone ki-file-up fs-3hx text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          {/*end::Icon*/}
                          {/*begin::Info*/}
                          <div className="ms-4">
                            <h3 className="dfs-3 fw-bold text-gray-900 mb-1">
                              Drop files here or click to upload.
                            </h3>
                            <span className="fw-semibold fs-4 text-muted">
                              Upload up to 10 files
                            </span>
                          </div>
                          {/*end::Info*/}
                        </div>
                      </div>
                      {/*end::Dropzone*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-8">
                      {/*begin::Label*/}
                      <label className="fs-6 fw-semibold mb-2">
                        Uploaded File
                      </label>
                      {/*End::Label*/}
                      {/*begin::Files*/}
                      <div className="mh-300px scroll-y me-n7 pe-7">
                        {/*begin::File*/}
                        <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px">
                              <img
                                src="assets/media/svg/files/pdf.svg"
                                alt="icon"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-6">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Avionica Technical Requirements
                              </a>
                              <div className="fw-semibold text-muted">
                                230kb
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*begin::Menu*/}
                          <div className="min-w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option />
                              <option defaultValue={1}>Remove</option>
                              <option defaultValue={2}>Modify</option>
                              <option defaultValue={3}>Select</option>
                            </select>
                          </div>
                          {/*end::Menu*/}
                        </div>
                        {/*end::File*/}
                        {/*begin::File*/}
                        <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px">
                              <img
                                src="assets/media/svg/files/doc.svg"
                                alt="icon"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-6">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                9 Degree CURD draftplan
                              </a>
                              <div className="fw-semibold text-muted">
                                3.6mb
                              </div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*begin::Menu*/}
                          <div className="min-w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option />
                              <option defaultValue={1}>Remove</option>
                              <option defaultValue={2}>Modify</option>
                              <option defaultValue={3}>Select</option>
                            </select>
                          </div>
                          {/*end::Menu*/}
                        </div>
                        {/*end::File*/}
                        {/*begin::File*/}
                        <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px">
                              <img
                                src="assets/media/svg/files/css.svg"
                                alt="icon"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-6">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                User CRUD Styles
                              </a>
                              <div className="fw-semibold text-muted">85kb</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*begin::Menu*/}
                          <div className="min-w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option />
                              <option defaultValue={1}>Remove</option>
                              <option defaultValue={2}>Modify</option>
                              <option defaultValue={3}>Select</option>
                            </select>
                          </div>
                          {/*end::Menu*/}
                        </div>
                        {/*end::File*/}
                        {/*begin::File*/}
                        <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px">
                              <img
                                src="assets/media/svg/files/ai.svg"
                                alt="icon"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-6">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Design Initial Logo
                              </a>
                              <div className="fw-semibold text-muted">40kb</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*begin::Menu*/}
                          <div className="min-w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option />
                              <option defaultValue={1}>Remove</option>
                              <option defaultValue={2}>Modify</option>
                              <option defaultValue={3}>Select</option>
                            </select>
                          </div>
                          {/*end::Menu*/}
                        </div>
                        {/*end::File*/}
                        {/*begin::File*/}
                        <div className="d-flex flex-stack py-4">
                          <div className="d-flex align-items-center">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px">
                              <img
                                src="assets/media/svg/files/tif.svg"
                                alt="icon"
                              />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-6">
                              <a
                                href="#"
                                className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                              >
                                Tower Hill Bilboard
                              </a>
                              <div className="fw-semibold text-muted">27mb</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*begin::Menu*/}
                          <div className="min-w-100px">
                            <select
                              className="form-select form-select-solid form-select-sm"
                              data-control="select2"
                              data-hide-search="true"
                              data-placeholder="Edit"
                            >
                              <option />
                              <option defaultValue={1}>Remove</option>
                              <option defaultValue={2}>Modify</option>
                              <option defaultValue={3}>Select</option>
                            </select>
                          </div>
                          {/*end::Menu*/}
                        </div>
                        {/*end::File*/}
                      </div>
                      {/*end::Files*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-8">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-stack">
                        {/*begin::Label*/}
                        <div className="me-5">
                          <label className="fs-6 fw-semibold">
                            Allow Changes in Budget
                          </label>
                          <div className="fs-7 fw-semibold text-muted">
                            If you need more info, please check budget planning
                          </div>
                        </div>
                        {/*end::Label*/}
                        {/*begin::Switch*/}
                        <label className="form-check form-switch form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue={1}
                            name="target_allow"
                            defaultChecked="checked"
                          />
                          <span className="form-check-label fw-semibold text-muted">
                            Allowed
                          </span>
                        </label>
                        {/*end::Switch*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        data-kt-element="files-previous"
                      >
                        Set First Target
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-primary"
                        data-kt-element="files-next"
                      >
                        <span className="indicator-label">Complete</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Files*/}
                {/*begin::Complete*/}
                <div data-kt-stepper-element="content">
                  {/*begin::Wrapper*/}
                  <div className="w-100">
                    {/*begin::Heading*/}
                    <div className="pb-12 text-center">
                      {/*begin::Title*/}
                      <h1 className="fw-bold text-gray-900">
                        Project Created!
                      </h1>
                      {/*end::Title*/}
                      {/*begin::Description*/}
                      <div className="text-muted fw-semibold fs-4">
                        If you need more info, please check how to create
                        project
                      </div>
                      {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-center pb-20">
                      <button
                        type="button"
                        className="btn btn-lg btn-light me-3"
                        data-kt-element="complete-start"
                      >
                        Create New Project
                      </button>
                      <a
                        href=""
                        className="btn btn-lg btn-primary"
                        data-bs-toggle="tooltip"
                        title="Coming Soon"
                      >
                        View Project
                      </a>
                    </div>
                    {/*end::Actions*/}
                    {/*begin::Illustration*/}
                    <div className="text-center px-4">
                      <img
                        src="assets/media/illustrations/sigma-1/9.png"
                        alt=""
                        className="mww-100 mh-350px"
                      />
                    </div>
                    {/*end::Illustration*/}
                  </div>
                </div>
                {/*end::Complete*/}
              </form>
              {/*end::Form*/}
            </div>
            {/*begin::Container*/}
          </div>
          {/*end::Stepper*/}
        </div>
        {/*end::Modal body*/}
      </div>
      {/*end::Modal content*/}
    </div>
    {/*end::Modal dialog*/}
  </div>
  {/*end::Modal - Create Project*/}
  {/*begin::Modal - New Address*/}
  <div
    className="modal fade"
    id="kt_modal_new_address"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog modal-dialog-centered mw-650px">
      {/*begin::Modal content*/}
      <div className="modal-content">
        {/*begin::Form*/}
        <form className="form" action="#" id="kt_modal_new_address_form">
          {/*begin::Modal header*/}
          <div className="modal-header" id="kt_modal_new_address_header">
            {/*begin::Modal title*/}
            <h2>Add New Address</h2>
            {/*end::Modal title*/}
            {/*begin::Close*/}
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <i className="ki-duotone ki-cross fs-1">
                <span className="path1" />
                <span className="path2" />
              </i>
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Modal header*/}
          {/*begin::Modal body*/}
          <div className="modal-body py-10 px-lg-17">
            {/*begin::Scroll*/}
            <div
              className="scroll-y me-n7 pe-7"
              id="kt_modal_new_address_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_new_address_header"
              data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
              data-kt-scroll-offset="300px"
            >
              {/*begin::Notice*/}
              {/*begin::Notice*/}
              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                {/*begin::Icon*/}
                <i className="ki-duotone ki-information fs-2tx text-warning me-4">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </i>
                {/*end::Icon*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack flex-grow-1">
                  {/*begin::Content*/}
                  <div className="fw-semibold">
                    <h4 className="text-gray-900 fw-bold">Warning</h4>
                    <div className="fs-6 text-gray-700">
                      Updating address may affter to your
                      <a href="#">Tax Location</a>
                    </div>
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Wrapper*/}
              </div>
              {/*end::Notice*/}
              {/*end::Notice*/}
              {/*begin::Input group*/}
              <div className="row mb-5">
                {/*begin::Col*/}
                <div className="col-md-6 fv-row">
                  {/*begin::Label*/}
                  <label className="required fs-5 fw-semibold mb-2">
                    First name
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder=""
                    name="first-name"
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-md-6 fv-row">
                  {/*end::Label*/}
                  <label className="required fs-5 fw-semibold mb-2">
                    Last name
                  </label>
                  {/*end::Label*/}
                  {/*end::Input*/}
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder=""
                    name="last-name"
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="d-flex flex-column mb-5 fv-row">
                {/*begin::Label*/}
                <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                  <span className="required">Country</span>
                  <span
                    className="ms-1"
                    data-bs-toggle="tooltip"
                    title="Your payment statements may very based on selected country"
                  >
                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                    </i>
                  </span>
                </label>
                {/*end::Label*/}
                {/*begin::Select*/}
                <select
                  name="country"
                  data-control="select2"
                  data-dropdown-parent="#kt_modal_new_address"
                  data-placeholder="Select a Country..."
                  className="form-select form-select-solid"
                >
                  <option defaultValue="">Select a Country...</option>
                  <option defaultValue="AF">Afghanistan</option>
                  <option defaultValue="AX">Aland Islands</option>
                  <option defaultValue="AL">Albania</option>
                  <option defaultValue="DZ">Algeria</option>
                  <option defaultValue="AS">American Samoa</option>
                  <option defaultValue="AD">Andorra</option>
                  <option defaultValue="AO">Angola</option>
                  <option defaultValue="AI">Anguilla</option>
                  <option defaultValue="AG">Antigua and Barbuda</option>
                  <option defaultValue="AR">Argentina</option>
                  <option defaultValue="AM">Armenia</option>
                  <option defaultValue="AW">Aruba</option>
                  <option defaultValue="AU">Australia</option>
                  <option defaultValue="AT">Austria</option>
                  <option defaultValue="AZ">Azerbaijan</option>
                  <option defaultValue="BS">Bahamas</option>
                  <option defaultValue="BH">Bahrain</option>
                  <option defaultValue="BD">Bangladesh</option>
                  <option defaultValue="BB">Barbados</option>
                  <option defaultValue="BY">Belarus</option>
                  <option defaultValue="BE">Belgium</option>
                  <option defaultValue="BZ">Belize</option>
                  <option defaultValue="BJ">Benin</option>
                  <option defaultValue="BM">Bermuda</option>
                  <option defaultValue="BT">Bhutan</option>
                  <option defaultValue="BO">Bolivia, Plurinational State of</option>
                  <option defaultValue="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option defaultValue="BA">Bosnia and Herzegovina</option>
                  <option defaultValue="BW">Botswana</option>
                  <option defaultValue="BR">Brazil</option>
                  <option defaultValue="IO">British Indian Ocean Territory</option>
                  <option defaultValue="BN">Brunei Darussalam</option>
                  <option defaultValue="BG">Bulgaria</option>
                  <option defaultValue="BF">Burkina Faso</option>
                  <option defaultValue="BI">Burundi</option>
                  <option defaultValue="KH">Cambodia</option>
                  <option defaultValue="CM">Cameroon</option>
                  <option defaultValue="CA">Canada</option>
                  <option defaultValue="CV">Cape Verde</option>
                  <option defaultValue="KY">Cayman Islands</option>
                  <option defaultValue="CF">Central African Republic</option>
                  <option defaultValue="TD">Chad</option>
                  <option defaultValue="CL">Chile</option>
                  <option defaultValue="CN">China</option>
                  <option defaultValue="CX">Christmas Island</option>
                  <option defaultValue="CC">Cocos (Keeling) Islands</option>
                  <option defaultValue="CO">Colombia</option>
                  <option defaultValue="KM">Comoros</option>
                  <option defaultValue="CK">Cook Islands</option>
                  <option defaultValue="CR">Costa Rica</option>
                  <option defaultValue="CI">Côte d'Ivoire</option>
                  <option defaultValue="HR">Croatia</option>
                  <option defaultValue="CU">Cuba</option>
                  <option defaultValue="CW">Curaçao</option>
                  <option defaultValue="CZ">Czech Republic</option>
                  <option defaultValue="DK">Denmark</option>
                  <option defaultValue="DJ">Djibouti</option>
                  <option defaultValue="DM">Dominica</option>
                  <option defaultValue="DO">Dominican Republic</option>
                  <option defaultValue="EC">Ecuador</option>
                  <option defaultValue="EG">Egypt</option>
                  <option defaultValue="SV">El Salvador</option>
                  <option defaultValue="GQ">Equatorial Guinea</option>
                  <option defaultValue="ER">Eritrea</option>
                  <option defaultValue="EE">Estonia</option>
                  <option defaultValue="ET">Ethiopia</option>
                  <option defaultValue="FK">Falkland Islands (Malvinas)</option>
                  <option defaultValue="FJ">Fiji</option>
                  <option defaultValue="FI">Finland</option>
                  <option defaultValue="FR">France</option>
                  <option defaultValue="PF">French Polynesia</option>
                  <option defaultValue="GA">Gabon</option>
                  <option defaultValue="GM">Gambia</option>
                  <option defaultValue="GE">Georgia</option>
                  <option defaultValue="DE">Germany</option>
                  <option defaultValue="GH">Ghana</option>
                  <option defaultValue="GI">Gibraltar</option>
                  <option defaultValue="GR">Greece</option>
                  <option defaultValue="GL">Greenland</option>
                  <option defaultValue="GD">Grenada</option>
                  <option defaultValue="GU">Guam</option>
                  <option defaultValue="GT">Guatemala</option>
                  <option defaultValue="GG">Guernsey</option>
                  <option defaultValue="GN">Guinea</option>
                  <option defaultValue="GW">Guinea-Bissau</option>
                  <option defaultValue="HT">Haiti</option>
                  <option defaultValue="VA">Holy See (Vatican City State)</option>
                  <option defaultValue="HN">Honduras</option>
                  <option defaultValue="HK">Hong Kong</option>
                  <option defaultValue="HU">Hungary</option>
                  <option defaultValue="IS">Iceland</option>
                  <option defaultValue="IN">India</option>
                  <option defaultValue="ID">Indonesia</option>
                  <option defaultValue="IR">Iran, Islamic Republic of</option>
                  <option defaultValue="IQ">Iraq</option>
                  <option defaultValue="IE">Ireland</option>
                  <option defaultValue="IM">Isle of Man</option>
                  <option defaultValue="IL">Israel</option>
                  <option defaultValue="IT">Italy</option>
                  <option defaultValue="JM">Jamaica</option>
                  <option defaultValue="JP">Japan</option>
                  <option defaultValue="JE">Jersey</option>
                  <option defaultValue="JO">Jordan</option>
                  <option defaultValue="KZ">Kazakhstan</option>
                  <option defaultValue="KE">Kenya</option>
                  <option defaultValue="KI">Kiribati</option>
                  <option defaultValue="KP">
                    Korea, Democratic People's Republic of
                  </option>
                  <option defaultValue="KW">Kuwait</option>
                  <option defaultValue="KG">Kyrgyzstan</option>
                  <option defaultValue="LA">Lao People's Democratic Republic</option>
                  <option defaultValue="LV">Latvia</option>
                  <option defaultValue="LB">Lebanon</option>
                  <option defaultValue="LS">Lesotho</option>
                  <option defaultValue="LR">Liberia</option>
                  <option defaultValue="LY">Libya</option>
                  <option defaultValue="LI">Liechtenstein</option>
                  <option defaultValue="LT">Lithuania</option>
                  <option defaultValue="LU">Luxembourg</option>
                  <option defaultValue="MO">Macao</option>
                  <option defaultValue="MG">Madagascar</option>
                  <option defaultValue="MW">Malawi</option>
                  <option defaultValue="MY">Malaysia</option>
                  <option defaultValue="MV">Maldives</option>
                  <option defaultValue="ML">Mali</option>
                  <option defaultValue="MT">Malta</option>
                  <option defaultValue="MH">Marshall Islands</option>
                  <option defaultValue="MQ">Martinique</option>
                  <option defaultValue="MR">Mauritania</option>
                  <option defaultValue="MU">Mauritius</option>
                  <option defaultValue="MX">Mexico</option>
                  <option defaultValue="FM">Micronesia, Federated States of</option>
                  <option defaultValue="MD">Moldova, Republic of</option>
                  <option defaultValue="MC">Monaco</option>
                  <option defaultValue="MN">Mongolia</option>
                  <option defaultValue="ME">Montenegro</option>
                  <option defaultValue="MS">Montserrat</option>
                  <option defaultValue="MA">Morocco</option>
                  <option defaultValue="MZ">Mozambique</option>
                  <option defaultValue="MM">Myanmar</option>
                  <option defaultValue="NA">Namibia</option>
                  <option defaultValue="NR">Nauru</option>
                  <option defaultValue="NP">Nepal</option>
                  <option defaultValue="NL">Netherlands</option>
                  <option defaultValue="NZ">New Zealand</option>
                  <option defaultValue="NI">Nicaragua</option>
                  <option defaultValue="NE">Niger</option>
                  <option defaultValue="NG">Nigeria</option>
                  <option defaultValue="NU">Niue</option>
                  <option defaultValue="NF">Norfolk Island</option>
                  <option defaultValue="MP">Northern Mariana Islands</option>
                  <option defaultValue="NO">Norway</option>
                  <option defaultValue="OM">Oman</option>
                  <option defaultValue="PK">Pakistan</option>
                  <option defaultValue="PW">Palau</option>
                  <option defaultValue="PS">Palestinian Territory, Occupied</option>
                  <option defaultValue="PA">Panama</option>
                  <option defaultValue="PG">Papua New Guinea</option>
                  <option defaultValue="PY">Paraguay</option>
                  <option defaultValue="PE">Peru</option>
                  <option defaultValue="PH">Philippines</option>
                  <option defaultValue="PL">Poland</option>
                  <option defaultValue="PT">Portugal</option>
                  <option defaultValue="PR">Puerto Rico</option>
                  <option defaultValue="QA">Qatar</option>
                  <option defaultValue="RO">Romania</option>
                  <option defaultValue="RU">Russian Federation</option>
                  <option defaultValue="RW">Rwanda</option>
                  <option defaultValue="BL">Saint Barthélemy</option>
                  <option defaultValue="KN">Saint Kitts and Nevis</option>
                  <option defaultValue="LC">Saint Lucia</option>
                  <option defaultValue="MF">Saint Martin (French part)</option>
                  <option defaultValue="VC">Saint Vincent and the Grenadines</option>
                  <option defaultValue="WS">Samoa</option>
                  <option defaultValue="SM">San Marino</option>
                  <option defaultValue="ST">Sao Tome and Principe</option>
                  <option defaultValue="SA">Saudi Arabia</option>
                  <option defaultValue="SN">Senegal</option>
                  <option defaultValue="RS">Serbia</option>
                  <option defaultValue="SC">Seychelles</option>
                  <option defaultValue="SL">Sierra Leone</option>
                  <option defaultValue="SG">Singapore</option>
                  <option defaultValue="SX">Sint Maarten (Dutch part)</option>
                  <option defaultValue="SK">Slovakia</option>
                  <option defaultValue="SI">Slovenia</option>
                  <option defaultValue="SB">Solomon Islands</option>
                  <option defaultValue="SO">Somalia</option>
                  <option defaultValue="ZA">South Africa</option>
                  <option defaultValue="KR">South Korea</option>
                  <option defaultValue="SS">South Sudan</option>
                  <option defaultValue="ES">Spain</option>
                  <option defaultValue="LK">Sri Lanka</option>
                  <option defaultValue="SD">Sudan</option>
                  <option defaultValue="SR">Suriname</option>
                  <option defaultValue="SZ">Swaziland</option>
                  <option defaultValue="SE">Sweden</option>
                  <option defaultValue="CH">Switzerland</option>
                  <option defaultValue="SY">Syrian Arab Republic</option>
                  <option defaultValue="TW">Taiwan, Province of China</option>
                  <option defaultValue="TJ">Tajikistan</option>
                  <option defaultValue="TZ">Tanzania, United Republic of</option>
                  <option defaultValue="TH">Thailand</option>
                  <option defaultValue="TG">Togo</option>
                  <option defaultValue="TK">Tokelau</option>
                  <option defaultValue="TO">Tonga</option>
                  <option defaultValue="TT">Trinidad and Tobago</option>
                  <option defaultValue="TN">Tunisia</option>
                  <option defaultValue="TR">Turkey</option>
                  <option defaultValue="TM">Turkmenistan</option>
                  <option defaultValue="TC">Turks and Caicos Islands</option>
                  <option defaultValue="TV">Tuvalu</option>
                  <option defaultValue="UG">Uganda</option>
                  <option defaultValue="UA">Ukraine</option>
                  <option defaultValue="AE">United Arab Emirates</option>
                  <option defaultValue="GB">United Kingdom</option>
                  <option defaultValue="US">United States</option>
                  <option defaultValue="UY">Uruguay</option>
                  <option defaultValue="UZ">Uzbekistan</option>
                  <option defaultValue="VU">Vanuatu</option>
                  <option defaultValue="VE">Venezuela, Bolivarian Republic of</option>
                  <option defaultValue="VN">Vietnam</option>
                  <option defaultValue="VI">Virgin Islands</option>
                  <option defaultValue="YE">Yemen</option>
                  <option defaultValue="ZM">Zambia</option>
                  <option defaultValue="ZW">Zimbabwe</option>
                </select>
                {/*end::Select*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="d-flex flex-column mb-5 fv-row">
                {/*begin::Label*/}
                <label className="required fs-5 fw-semibold mb-2">
                  Address Line 1
                </label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <input
                  className="form-control form-control-solid"
                  placeholder=""
                  name="address1"
                />
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="d-flex flex-column mb-5 fv-row">
                {/*begin::Label*/}
                <label className="required fs-5 fw-semibold mb-2">
                  Address Line 2
                </label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <input
                  className="form-control form-control-solid"
                  placeholder=""
                  name="address2"
                />
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="d-flex flex-column mb-5 fv-row">
                {/*begin::Label*/}
                <label className="fs-5 fw-semibold mb-2">Town</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <input
                  className="form-control form-control-solid"
                  placeholder=""
                  name="city"
                />
                {/*end::Input*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="row g-9 mb-5">
                {/*begin::Col*/}
                <div className="col-md-6 fv-row">
                  {/*begin::Label*/}
                  <label className="fs-5 fw-semibold mb-2">
                    State / Province
                  </label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input
                    className="form-control form-control-solid"
                    placeholder=""
                    name="state"
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-md-6 fv-row">
                  {/*begin::Label*/}
                  <label className="fs-5 fw-semibold mb-2">Post Code</label>
                  {/*end::Label*/}
                  {/*begin::Input*/}
                  <input
                    className="form-control form-control-solid"
                    placeholder=""
                    name="postcode"
                  />
                  {/*end::Input*/}
                </div>
                {/*end::Col*/}
              </div>
              {/*end::Input group*/}
              {/*begin::Input group*/}
              <div className="fv-row mb-5">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-stack">
                  {/*begin::Label*/}
                  <div className="me-5">
                    {/*begin::Label*/}
                    <label className="fs-5 fw-semibold">
                      Use as a billing adderess?
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <div className="fs-7 fw-semibold text-muted">
                      If you need more info, please check budget planning
                    </div>
                    {/*end::Input*/}
                  </div>
                  {/*end::Label*/}
                  {/*begin::Switch*/}
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    {/*begin::Input*/}
                    <input
                      className="form-check-input"
                      name="billing"
                      type="checkbox"
                      defaultValue={1}
                      defaultChecked="checked"
                    />
                    {/*end::Input*/}
                    {/*begin::Label*/}
                    <span className="form-check-label fw-semibold text-muted">
                      Yes
                    </span>
                    {/*end::Label*/}
                  </label>
                  {/*end::Switch*/}
                </div>
                {/*begin::Wrapper*/}
              </div>
              {/*end::Input group*/}
            </div>
            {/*end::Scroll*/}
          </div>
          {/*end::Modal body*/}
          {/*begin::Modal footer*/}
          <div className="modal-footer flex-center">
            {/*begin::Button*/}
            <button
              type="reset"
              id="kt_modal_new_address_cancel"
              className="btn btn-light me-3"
            >
              Discard
            </button>
            {/*end::Button*/}
            {/*begin::Button*/}
            <button
              type="submit"
              id="kt_modal_new_address_submit"
              className="btn btn-primary"
            >
              <span className="indicator-label">Submit</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
            {/*end::Button*/}
          </div>
          {/*end::Modal footer*/}
        </form>
        {/*end::Form*/}
      </div>
    </div>
  </div>
  {/*end::Modal - New Address*/}
  {/*begin::Modal - Users Search*/}
  <div
    className="modal fade"
    id="kt_modal_users_search"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog modal-dialog-centered mw-650px">
      {/*begin::Modal content*/}
      <div className="modal-content">
        {/*begin::Modal header*/}
        <div className="modal-header pb-0 border-0 justify-content-end">
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*begin::Modal header*/}
        {/*begin::Modal body*/}
        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          {/*begin::Content*/}
          <div className="text-center mb-13">
            <h1 className="mb-3">Search Users</h1>
            <div className="text-muted fw-semibold fs-5">
              Invite Collaborators To Your Project
            </div>
          </div>
          {/*end::Content*/}
          {/*begin::Search*/}
          <div
            id="kt_modal_users_search_handler"
            data-kt-search-keypress="true"
            data-kt-search-min-length={2}
            data-kt-search-enter="enter"
            data-kt-search-layout="inline"
          >
            {/*begin::Form*/}
            <form
              data-kt-search-element="form"
              className="w-100 position-relative mb-5"
              autoComplete="off"
            >
              {/*begin::Hidden input(Added to disable form autocomplete)*/}
              <input type="hidden" />
              {/*end::Hidden input*/}
              {/*begin::Icon*/}
              <i className="ki-duotone ki-magnifier fs-2 fs-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y">
                <span className="path1" />
                <span className="path2" />
              </i>
              {/*end::Icon*/}
              {/*begin::Input*/}
              <input
                type="text"
                className="form-control form-control-lg form-control-solid px-15"
                name="search"
                defaultValue=""
                placeholder="Search by username, full name or email..."
                data-kt-search-element="input"
              />
              {/*end::Input*/}
              {/*begin::Spinner*/}
              <span
                className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                data-kt-search-element="spinner"
              >
                <span className="spinner-border h-15px w-15px align-middle text-muted" />
              </span>
              {/*end::Spinner*/}
              {/*begin::Reset*/}
              <span
                className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
                data-kt-search-element="clear"
              >
                <i className="ki-duotone ki-cross fs-2 fs-lg-1 me-0">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              </span>
              {/*end::Reset*/}
            </form>
            {/*end::Form*/}
            {/*begin::Wrapper*/}
            <div className="py-5">
              {/*begin::Suggestions*/}
              <div data-kt-search-element="suggestions">
                {/*begin::Heading*/}
                <h3 className="fw-semibold mb-5">Recently searched:</h3>
                {/*end::Heading*/}
                {/*begin::Users*/}
                <div className="mh-375px scroll-y me-n7 pe-7">
                  {/*begin::User*/}
                  <a
                    href="#"
                    className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Info*/}
                    <div className="fw-semibold">
                      <span className="fs-6 text-gray-800 me-2">
                        Emma Smith
                      </span>
                      <span className="badge badge-light">Art Director</span>
                    </div>
                    {/*end::Info*/}
                  </a>
                  {/*end::User*/}
                  {/*begin::User*/}
                  <a
                    href="#"
                    className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-35px symbol-circle me-5">
                      <span className="symbol-label bg-light-danger text-danger fw-semibold">
                        M
                      </span>
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Info*/}
                    <div className="fw-semibold">
                      <span className="fs-6 text-gray-800 me-2">
                        Melody Macy
                      </span>
                      <span className="badge badge-light">
                        Marketing Analytic
                      </span>
                    </div>
                    {/*end::Info*/}
                  </a>
                  {/*end::User*/}
                  {/*begin::User*/}
                  <a
                    href="#"
                    className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Info*/}
                    <div className="fw-semibold">
                      <span className="fs-6 text-gray-800 me-2">Max Smith</span>
                      <span className="badge badge-light">
                        Software Enginer
                      </span>
                    </div>
                    {/*end::Info*/}
                  </a>
                  {/*end::User*/}
                  {/*begin::User*/}
                  <a
                    href="#"
                    className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Info*/}
                    <div className="fw-semibold">
                      <span className="fs-6 text-gray-800 me-2">Sean Bean</span>
                      <span className="badge badge-light">Web Developer</span>
                    </div>
                    {/*end::Info*/}
                  </a>
                  {/*end::User*/}
                  {/*begin::User*/}
                  <a
                    href="#"
                    className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1"
                  >
                    {/*begin::Avatar*/}
                    <div className="symbol symbol-35px symbol-circle me-5">
                      <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                    </div>
                    {/*end::Avatar*/}
                    {/*begin::Info*/}
                    <div className="fw-semibold">
                      <span className="fs-6 text-gray-800 me-2">Brian Cox</span>
                      <span className="badge badge-light">UI/UX Designer</span>
                    </div>
                    {/*end::Info*/}
                  </a>
                  {/*end::User*/}
                </div>
                {/*end::Users*/}
              </div>
              {/*end::Suggestions*/}
              {/*begin::Results(add d-none to below element to hide the users list by default)*/}
              <div data-kt-search-element="results" className="d-none">
                {/*begin::Users*/}
                <div className="mh-375px scroll-y me-n7 pe-7">
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={0}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='0']"
                          defaultValue={0}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Emma Smith
                        </a>
                        <div className="fw-semibold text-muted">
                          smith@kpmg.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2} selected="selected">
                          Owner
                        </option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={1}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='1']"
                          defaultValue={1}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-danger text-danger fw-semibold">
                          M
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Melody Macy
                        </a>
                        <div className="fw-semibold text-muted">
                          melody@altbox.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1} selected="selected">
                          Guest
                        </option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={2}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='2']"
                          defaultValue={2}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Max Smith
                        </a>
                        <div className="fw-semibold text-muted">max@kt.com</div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={3}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='3']"
                          defaultValue={3}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Sean Bean
                        </a>
                        <div className="fw-semibold text-muted">
                          sean@dellito.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2} selected="selected">
                          Owner
                        </option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={4}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='4']"
                          defaultValue={4}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Brian Cox
                        </a>
                        <div className="fw-semibold text-muted">
                          brian@exchange.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={5}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='5']"
                          defaultValue={5}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-warning text-warning fw-semibold">
                          C
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Mikaela Collins
                        </a>
                        <div className="fw-semibold text-muted">
                          mik@pex.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2} selected="selected">
                          Owner
                        </option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={6}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='6']"
                          defaultValue={6}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Francis Mitcham
                        </a>
                        <div className="fw-semibold text-muted">
                          f.mit@kpmg.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={7}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='7']"
                          defaultValue={7}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-danger text-danger fw-semibold">
                          O
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Olivia Wild
                        </a>
                        <div className="fw-semibold text-muted">
                          olivia@corpmail.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2} selected="selected">
                          Owner
                        </option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={8}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='8']"
                          defaultValue={8}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-primary text-primary fw-semibold">
                          N
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Neil Owen
                        </a>
                        <div className="fw-semibold text-muted">
                          owen.neil@gmail.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1} selected="selected">
                          Guest
                        </option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={9}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='9']"
                          defaultValue={9}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Dan Wilson
                        </a>
                        <div className="fw-semibold text-muted">
                          dam@consilting.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={10}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='10']"
                          defaultValue={10}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-danger text-danger fw-semibold">
                          E
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Emma Bold
                        </a>
                        <div className="fw-semibold text-muted">
                          emma@intenso.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2} selected="selected">
                          Owner
                        </option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={11}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='11']"
                          defaultValue={11}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Ana Crown
                        </a>
                        <div className="fw-semibold text-muted">
                          ana.cf@limtel.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1} selected="selected">
                          Guest
                        </option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={12}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='12']"
                          defaultValue={12}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-info text-info fw-semibold">
                          A
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Robert Doe
                        </a>
                        <div className="fw-semibold text-muted">
                          robert@benko.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={13}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='13']"
                          defaultValue={13}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-13.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          John Miller
                        </a>
                        <div className="fw-semibold text-muted">
                          miller@mapple.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={14}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='14']"
                          defaultValue={14}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-success text-success fw-semibold">
                          L
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Lucy Kunic
                        </a>
                        <div className="fw-semibold text-muted">
                          lucy.m@fentech.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2} selected="selected">
                          Owner
                        </option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={15}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='15']"
                          defaultValue={15}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Ethan Wilder
                        </a>
                        <div className="fw-semibold text-muted">
                          ethan@loop.com.au
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1} selected="selected">
                          Guest
                        </option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3}>Can Edit</option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                  {/*begin::Separator*/}
                  <div className="border-bottom border-gray-300 border-bottom-dashed" />
                  {/*end::Separator*/}
                  {/*begin::User*/}
                  <div
                    className="rounded d-flex flex-stack bg-active-lighten p-4"
                    data-user-id={16}
                  >
                    {/*begin::Details*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Checkbox*/}
                      <label className="form-check form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="users"
                          data-kt-check="true"
                          data-kt-check-target="[data-user-id='16']"
                          defaultValue={16}
                        />
                      </label>
                      {/*end::Checkbox*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <span className="symbol-label bg-light-danger text-danger fw-semibold">
                          E
                        </span>
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-5">
                        <a
                          href="#"
                          className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                        >
                          Emma Bold
                        </a>
                        <div className="fw-semibold text-muted">
                          emma@intenso.com
                        </div>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::Details*/}
                    {/*begin::Access menu*/}
                    <div className="ms-2 w-100px">
                      <select
                        className="form-select form-select-solid form-select-sm"
                        data-control="select2"
                        data-hide-search="true"
                      >
                        <option defaultValue={1}>Guest</option>
                        <option defaultValue={2}>Owner</option>
                        <option defaultValue={3} selected="selected">
                          Can Edit
                        </option>
                      </select>
                    </div>
                    {/*end::Access menu*/}
                  </div>
                  {/*end::User*/}
                </div>
                {/*end::Users*/}
                {/*begin::Actions*/}
                <div className="d-flex flex-center mt-15">
                  <button
                    type="reset"
                    id="kt_modal_users_search_reset"
                    data-bs-dismiss="modal"
                    className="btn btn-active-light me-3"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="kt_modal_users_search_submit"
                    className="btn btn-primary"
                  >
                    Add Selected Users
                  </button>
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Results*/}
              {/*begin::Empty*/}
              <div
                data-kt-search-element="empty"
                className="text-center d-none"
              >
                {/*begin::Message*/}
                <div className="fw-semibold py-10">
                  <div className="text-gray-600 fs-3 mb-2">No users found</div>
                  <div className="text-muted fs-6">
                    Try to search by username, full name or email...
                  </div>
                </div>
                {/*end::Message*/}
                {/*begin::Illustration*/}
                <div className="text-center px-5">
                  <img
                    src="assets/media/illustrations/sigma-1/1.png"
                    alt=""
                    className="w-100 h-200px h-sm-325px"
                  />
                </div>
                {/*end::Illustration*/}
              </div>
              {/*end::Empty*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Search*/}
        </div>
        {/*end::Modal body*/}
      </div>
      {/*end::Modal content*/}
    </div>
    {/*end::Modal dialog*/}
  </div>
  {/*end::Modal - Users Search*/}
  {/*begin::Modal - Invite Friends*/}
  <div
    className="modal fade"
    id="kt_modal_invite_friends"
    tabIndex={-1}
    aria-hidden="true"
  >
    {/*begin::Modal dialog*/}
    <div className="modal-dialog mw-650px">
      {/*begin::Modal content*/}
      <div className="modal-content">
        {/*begin::Modal header*/}
        <div className="modal-header pb-0 border-0 justify-content-end">
          {/*begin::Close*/}
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i className="ki-duotone ki-cross fs-1">
              <span className="path1" />
              <span className="path2" />
            </i>
          </div>
          {/*end::Close*/}
        </div>
        {/*begin::Modal header*/}
        {/*begin::Modal body*/}
        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
          {/*begin::Heading*/}
          <div className="text-center mb-13">
            {/*begin::Title*/}
            <h1 className="mb-3">Invite a Friend</h1>
            {/*end::Title*/}
            {/*begin::Description*/}
            <div className="text-muted fw-semibold fs-5">
              If you need more info, please check out
              <a href="#" className="link-primary fw-bold">
                FAQ Page
              </a>
              .
            </div>
            {/*end::Description*/}
          </div>
          {/*end::Heading*/}
          {/*begin::Google Contacts Invite*/}
          <div className="btn btn-light-primary fw-bold w-100 mb-8">
            <img
              alt="Logo"
              src="assets/media/svg/brand-logos/google-icon.svg"
              className="h-20px me-3"
            />
            Invite Gmail Contacts
          </div>
          {/*end::Google Contacts Invite*/}
          {/*begin::Separator*/}
          <div className="separator d-flex flex-center mb-8">
            <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">
              or
            </span>
          </div>
          {/*end::Separator*/}
          {/*begin::Textarea*/}
          <textarea
            className="form-control form-control-solid mb-8"
            rows={3}
            placeholder="Type or paste emails here"
            defaultValue={""}
          />
          {/*end::Textarea*/}
          {/*begin::Users*/}
          <div className="mb-10">
            {/*begin::Heading*/}
            <div className="fs-6 fw-semibold mb-2">Your Invitations</div>
            {/*end::Heading*/}
            {/*begin::List*/}
            <div className="mh-300px scroll-y me-n7 pe-7">
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Emma Smith
                    </a>
                    <div className="fw-semibold text-muted">smith@kpmg.com</div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2} selected="selected">
                      Owner
                    </option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-danger text-danger fw-semibold">
                      M
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Melody Macy
                    </a>
                    <div className="fw-semibold text-muted">
                      melody@altbox.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1} selected="selected">
                      Guest
                    </option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Max Smith
                    </a>
                    <div className="fw-semibold text-muted">max@kt.com</div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Sean Bean
                    </a>
                    <div className="fw-semibold text-muted">
                      sean@dellito.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2} selected="selected">
                      Owner
                    </option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Brian Cox
                    </a>
                    <div className="fw-semibold text-muted">
                      brian@exchange.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-warning text-warning fw-semibold">
                      C
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Mikaela Collins
                    </a>
                    <div className="fw-semibold text-muted">mik@pex.com</div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2} selected="selected">
                      Owner
                    </option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Francis Mitcham
                    </a>
                    <div className="fw-semibold text-muted">f.mit@kpmg.com</div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-danger text-danger fw-semibold">
                      O
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Olivia Wild
                    </a>
                    <div className="fw-semibold text-muted">
                      olivia@corpmail.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2} selected="selected">
                      Owner
                    </option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-primary text-primary fw-semibold">
                      N
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Neil Owen
                    </a>
                    <div className="fw-semibold text-muted">
                      owen.neil@gmail.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1} selected="selected">
                      Guest
                    </option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Dan Wilson
                    </a>
                    <div className="fw-semibold text-muted">
                      dam@consilting.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-danger text-danger fw-semibold">
                      E
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Emma Bold
                    </a>
                    <div className="fw-semibold text-muted">
                      emma@intenso.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2} selected="selected">
                      Owner
                    </option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Ana Crown
                    </a>
                    <div className="fw-semibold text-muted">
                      ana.cf@limtel.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1} selected="selected">
                      Guest
                    </option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-info text-info fw-semibold">
                      A
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Robert Doe
                    </a>
                    <div className="fw-semibold text-muted">
                      robert@benko.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-13.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      John Miller
                    </a>
                    <div className="fw-semibold text-muted">
                      miller@mapple.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-success text-success fw-semibold">
                      L
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Lucy Kunic
                    </a>
                    <div className="fw-semibold text-muted">
                      lucy.m@fentech.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2} selected="selected">
                      Owner
                    </option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Ethan Wilder
                    </a>
                    <div className="fw-semibold text-muted">
                      ethan@loop.com.au
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1} selected="selected">
                      Guest
                    </option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3}>Can Edit</option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
              {/*begin::User*/}
              <div className="d-flex flex-stack py-4">
                {/*begin::Details*/}
                <div className="d-flex align-items-center">
                  {/*begin::Avatar*/}
                  <div className="symbol symbol-35px symbol-circle">
                    <span className="symbol-label bg-light-primary text-primary fw-semibold">
                      N
                    </span>
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Details*/}
                  <div className="ms-5">
                    <a
                      href="#"
                      className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"
                    >
                      Neil Owen
                    </a>
                    <div className="fw-semibold text-muted">
                      owen.neil@gmail.com
                    </div>
                  </div>
                  {/*end::Details*/}
                </div>
                {/*end::Details*/}
                {/*begin::Access menu*/}
                <div className="ms-2 w-100px">
                  <select
                    className="form-select form-select-solid form-select-sm"
                    data-control="select2"
                    data-dropdown-parent="#kt_modal_invite_friends"
                    data-hide-search="true"
                  >
                    <option defaultValue={1}>Guest</option>
                    <option defaultValue={2}>Owner</option>
                    <option defaultValue={3} selected="selected">
                      Can Edit
                    </option>
                  </select>
                </div>
                {/*end::Access menu*/}
              </div>
              {/*end::User*/}
            </div>
            {/*end::List*/}
          </div>
          {/*end::Users*/}
          {/*begin::Notice*/}
          <div className="d-flex flex-stack">
            {/*begin::Label*/}
            <div className="me-5 fw-semibold">
              <label className="fs-6">Adding Users by Team Members</label>
              <div className="fs-7 text-muted">
                If you need more info, please check budget planning
              </div>
            </div>
            {/*end::Label*/}
            {/*begin::Switch*/}
            <label className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue={1}
                defaultChecked="checked"
              />
              <span className="form-check-label fw-semibold text-muted">
                Allowed
              </span>
            </label>
            {/*end::Switch*/}
          </div>
          {/*end::Notice*/}
        </div>
        {/*end::Modal body*/}
      </div>
      {/*end::Modal content*/}
    </div>
    {/*end::Modal dialog*/}
  </div>
  {/*end::Modal - Invite Friend*/}
  {/*end::Modals*/}
  {/*begin::Javascript*/}
  {/*begin::Global Javascript Bundle(mandatory for all pages)*/}
  {/*end::Global Javascript Bundle*/}
  {/*begin::Vendors Javascript(used for this page only)*/}
  {/*end::Vendors Javascript*/}
  {/*begin::Custom Javascript(used for this page only)*/}
  {/*end::Custom Javascript*/}
  {/*end::Javascript*/}
</>

  )
}

export default DashbordPage