import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Layout/menu/Footer";
import Header from "../../Layout/menu/Header";
import LoadingIndicator from "../../components/LoadingIndicator";

function ResultIntervention() {
  const location = useLocation();
  const navigate = useNavigate();
  const { searchResults } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);

  const [Searchstring, setSearchstring] = useState("");
  const [filteredSearchResults, setFilteredSearchResults] =
    useState(searchResults);

  useEffect(() => {
    // setFilteredSearchResults(
    //   searchResults.filter((intervention) =>
    //     intervention?.description
    //       .toLowerCase()
    //       .includes(Searchstring?.toLowerCase())
    //   )
    // );
  }, [Searchstring, searchResults]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  useEffect(() => {
    console.log(searchResults);
    if (!searchResults) {
      navigate("/search-intervention");
    }
    setIsLoading(false);
  }, [navigate, searchResults]);

  const columns = [
    {
      name: "Descrition",
      accessor: "client",
      selector: (row) => row.description,
    },

    {
      name: "Status",
      selector: (row) => (
        <span className={`badge ${getBadgeClass(row.status)}`}>
          {row.status}
        </span>
      ),
    },
    {
      name: "Date Creation",
      accessor: "created_at",
      selector: (row) => {
        // Format the created_at date
        const createdAtDate = new Date(row.created_at);
        return createdAtDate.toLocaleDateString(); // Adjust date format as needed
      },
    },
  ];
  const getBadgeClass = (status) => {
    switch (status) {
      case "Pré-planifiée":
        return "badge-secondary";
      case "Planifiée":
        return "badge-primary";
      case "Synchronisée":
        return "badge-info";
      case "Démarrée":
        return "badge-warning";
      case "Réalisée":
        return "badge-success";
      case "Validée":
        return "badge-dark";
      case "Temps de trajet":
        return "badge-light";
      default:
        return "badge-secondary";
    }
  };
  const handleRowClick = (row) => {
    // Assuming `row.id` is the identifier for the row
    navigate(`/interventiondetail/${row.id}`);

    console.log("row===>", row);
  };
  if (isLoading) {
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingIndicator />
    </div>;
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        {/* {/*begin::Page */}
        <div className="page d-flex flex-row flex-column-fluid">
          {/*begin::Wrapper */}
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            {/*begin::Header */}

            <Header />

            {/*end::Header */}
            <div className="Customcontainer bg-white">
              <div
                className="row bg-white"
                style={{ flex: 1, minHeight: "91.1vh" }}
              >
                <div
                  className="col-2 p-5 "
                  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
                >
                  {/* Contenu de la colonne de gauche */}
                  <p
                    className="etech-sidebar-section"
                    style={{
                      color: "#3a3838",
                      fontWeight: "700",
                      padding: ".25em 0",
                    }}
                  >
                    Résultats de la recherche
                  </p>
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                      type="text"
                      data-kt-subscription-table-filter="search"
                      className="form-control form-control-solid w-250px ps-12"
                      placeholder="Recherche ..."
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-10">
                  {/* Contenu de la colonne de droite */}
                  <h1
                    className="d-flex  fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Résultats de la recherche
                  </h1>
                  {/*begin::Card body */}
                  <div className="card-body pt-0">
                    <DataTable
                      columns={columns}
                      data={filteredSearchResults}
                      pagination
                      highlightOnHover={true}
                      paginationPerPage={10}
                      onRowClicked={handleRowClick}
                      customStyles={customStyles}
                    />
                  </div>

                  {/*end::Card body */}
                </div>
              </div>
            </div>
            {/* <Footer /> */}

            {/*end::Footer */}
          </div>
          {/*end::Wrapper */}
        </div>
        {/*end::Page */}
      </div>
    </div>
  );
}

export default ResultIntervention;
