import React from "react";
import Select from "react-select";

const SortControlRow = ({ sortBy, sortOrder, setSortBy, setSortOrder }) => {
  const sortByOptions = [
    { value: "id", label: "ID" },
    { value: "name", label: "Name" },
    { value: "date", label: "Date" },
    // Add more sorting fields as needed
  ];

  const sortOrderOptions = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];

  const handleSortByChange = (selectedOption) => {
    setSortBy(selectedOption.value);
  };

  const handleSortOrderChange = (selectedOption) => {
    setSortOrder(selectedOption.value);
  };

  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      {/* <label style={{ minWidth: "100px" }}>
        Sort By:
        <Select
          value={sortByOptions.find((option) => option.value === sortBy)}
          onChange={handleSortByChange}
          options={sortByOptions}
          styles={{ container: (base) => ({ ...base, width: "150px" }) }}
        />
      </label> */}

      <label style={{ minWidth: "100px" }}>
        Order:
        <Select
          value={sortOrderOptions.find((option) => option.value === sortOrder)}
          onChange={handleSortOrderChange}
          options={sortOrderOptions}
          styles={{ container: (base) => ({ ...base, width: "150px" }) }}
        />
      </label>
    </div>
  );
};

export default SortControlRow;
