import React, { useState, useEffect } from "react";
import Header from "../../Layout/menu/Header";
import Footer from "../../Layout/menu/Footer";
import DataTable from "react-data-table-component";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import { CirclePicker } from "react-color";

import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import api from "../Auth/axiosSetup";
import LoadingIndicator from "../../components/LoadingIndicator";
function Flags() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flags, setFlags] = useState([]);
  const [formData, setFormData] = useState({});
  const [Searchstring, setSearchstring] = useState("");
  const [filteredFlags, setFilteredFlags] = useState(flags);

  const [showModal, setShowModal] = useState(false);
  const [formName, setFormName] = useState("");

  const [editModal, setEditModal] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const [editFlagId, setEditFlagId] = useState(null);

  const handleShowEditModal = (flag) => {
    setEditFormData({ name: flag.name, color: flag.color });
    setEditFlagId(flag.id);
    setEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    setEditFormData({});
    setEditFlagId(null);
  };

  const handleEditFormNameChange = (event) => {
    setEditFormData({ ...editFormData, name: event.target.value });
  };

  const handleEditColorChange = (color) => {
    setEditFormData({ ...editFormData, color: color.hex });
  };

  const handleEditFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.put(`${apiHost}/flags/${editFlagId}`, {
        name: editFormData.name,
        color: editFormData.color,
      });
      const updatedFlag = response.data.data; // Assuming your API returns data in this structure
      setFlags(
        flags.map((flag) => (flag.id === editFlagId ? updatedFlag : flag))
      ); // Update flags state with the updated flag
      handleCloseEditModal();
    } catch (error) {
      console.error("Error editing Flag:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setFormName("");
  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
    setFormData({ ...formData, name: event.target.value });
  };
  const colors = [
    "#FF5252", // Red
    "#E040FB", // Purple
    "#448AFF", // Blue
    "#FFFF00", // Yellow
    "#00FF00", // Green
    "#000000", // Black
  ];
  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/flags`, {
        name: formData.name,
        color: formData.color,
      });

      const newFlag = response.data; // Assuming your API returns data in this structure
      setFlags([...flags, newFlag]); // Update flags state with the new flag
      handleCloseModal();
    } catch (error) {
      console.error("Error adding Flag:", error);
    } finally {
      setLoading(false);
    }
  };

  //   const handleRowClick = (row) => {
  //     // Assuming `row.id` is the identifier for the row
  //     navigate(`/formbuilder/${row.id}`);

  //     console.log("row===>",row);
  //   };
  useEffect(() => {
    api.get(`/flags`)
      .then((response) => response.data)
      .then((data) => {
        setFlags(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);
  const handleColorChange = (color) => {
    setFormData({ ...formData, color: color.hex });
  };
  useEffect(() => {
    setFilteredFlags(
      flags.filter((flag) =>
        flag.name.toLowerCase().includes(Searchstring.toLowerCase())
      )
    );
  }, [Searchstring, flags]);

  const handleInputChange = (e) => {
    setSearchstring(e.target.value);
  };

  const columns = [
    {
      name: "Nom",
      selector: (row) => row.name,
    },
    {
      name: "Couleur",
      selector: (row) => (
        <div
          style={{ width: "30px", height: "30px", backgroundColor: row.color }}
        ></div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <Button
          variant="link"
          onClick={() => handleShowEditModal(row)}
          style={{
            color: "#007bff",
          }}
        >
          <FaEdit
            style={{
              fontSize: "24px",
            }}
          />
        </Button>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "26px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontWeight: "700",
        backgroundColor: "#fafafa ",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div>
    <div className="d-flex flex-column flex-root">
{/* {/*begin::Page */}
<div className="page d-flex flex-row flex-column-fluid">
  {/*begin::Wrapper */}
  <div
    className="wrapper d-flex flex-column flex-row-fluid"
    id="kt_wrapper"
  >
    {/*begin::Header */}

    <Header />

    {/*end::Header */}
    <div
      className="Customcontainer bg-white" 
    >
      <div className="row bg-white" style={{ flex: 1,minHeight:"91.1vh" }}>
        <div className="col-2 p-5 " style={{borderRight:"1px solid rgba(58,56,56,.1)"}}>
          {/* Contenu de la colonne de gauche */}
          <p
            className="etech-sidebar-section"
            style={{
              color: "#3a3838",
              fontWeight: "700",
              padding: ".25em 0",
            }}
          >
          Liste des Drapeaux
          </p>
          <div className="d-flex align-items-center position-relative my-1">
                <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <input
                type="text"
                data-kt-subscription-table-filter="search"
                className="form-control form-control-solid w-250px ps-12"
                placeholder="Recherche..."
                onChange={handleInputChange}
              />
              </div>
            <div class="d-grid gap-2 col-6 mx-auto">
              <button
                          onClick={handleShowModal}
                          className="btn btn-primary"
                        >
                        Nouveau
                        </button>
            </div>                    </div>
        <div className="col-10" >
          {/* Contenu de la colonne de droite */}
          <h1 className="d-flex  fw-bold my-1 fs-3 p-4" style={{fontSize:"1.5em", fontWeight:"400"}}>
            Liste des Drapeaux

          </h1>
          {/*begin::Card body */}
          <div className="card-body pt-0">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingIndicator />
              </div>
            ) : (
              <>
                {error ? (
                  "Data not Found"
                ) : (
                  <DataTable
                    columns={columns}
                    data={filteredFlags}
                    pagination
                    highlightOnHover={true}
                    paginationPerPage={10} // Set the number of rows per page
                    //   onRowClicked={handleRowClick}

                    customStyles={customStyles}
                  />
                )}
              </>
            )}
          </div>

          {/*end::Card body */}
        </div>
      </div>
    </div>
    {/* <Footer /> */}

    {/*end::Footer */}
  </div>
  {/*end::Wrapper */}
</div>
{/*end::Page */}
</div>
      {/* Modal for adding new form */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un drapeau</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                value={formName}
                onChange={handleFormNameChange}
              />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Couleur</Form.Label>

              <CirclePicker
                color={formData.color}
                colors={colors}
                onChange={handleColorChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleFormSubmit}
            disabled={!formName || !formData.color || loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                style={{ width: "1rem", height: "1rem" }}
              />
            ) : (
              "Ajouter"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={editModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier un drapeau</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editFormName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                value={editFormData.name}
                onChange={handleEditFormNameChange}
              />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group controlId="editFormColor">
              <Form.Label>Couleur</Form.Label>
              <CirclePicker
                color={editFormData.color}
                colors={colors}
                onChange={handleEditColorChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={handleEditFormSubmit}
            disabled={!editFormData.name || !editFormData.color || loading}
          >
            {loading ? (
              <Spinner
                animation="border"
                style={{ width: "1rem", height: "1rem" }}
              />
            ) : (
              "Modifier"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Flags;
