import React from 'react';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';

const EditForm = ({
  data,
  editFormName,
  setEditFormName,
  editSelectedParent,
  setEditSelectedParent,
  editFormContact,
  setEditFormContact,
  editFormAdresse,
  setEditFormAdresse,
  editFormCodePostal,
  setEditFormCodePostal,
  editFormVille,
  setEditFormVille,
  editFormPays,
  setEditFormPays,
  editFormCoordonnees,
  setEditFormCoordonnees,
  editFormDescription,
  setEditFormDescription,
  editFormInfosSite,
  setEditFormInfosSite,
  editFormLatitudeLongitude,
  setEditFormLatitudeLongitude,
  editFormSite,
  setEditFormSite,
  editFormStatut,
  setEditFormStatut,
  statutOptions,
  handleCloseEditModal,
  handleEditFormSubmit,
  loading,
}) => {
  return (
    <Form>
      <Row>
          {/* Nom Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Nom <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormName}
                onChange={(e) => setEditFormName(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Référentiel Parent Field */}
          {data.parent && data.parent.valeurs && (
            <div className="row mb-3">
              <div className="col-md-3 d-flex justify-content-end">
                <label
                  style={{
                    color: "#0099e5",
                    whiteSpace: "nowrap",
                    paddingRight: "10px",
                    paddingTop: "5px",
                    fontSize: "14px",
                  }}
                  className="fw-semibold text-right"
                >
                  Référentiel Parent ({data.parent.nom})
                </label>
              </div>
              <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
                <Select
                  options={data.parent.valeurs.map((referentiel) => ({
                    value: referentiel.id,
                    label: referentiel.valeur,
                  }))}
                  value={editSelectedParent}
                  onChange={setEditSelectedParent}
                  placeholder="Sélectionner un parent"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                      maxWidth: "160px",
                      marginLeft: "5px",
                    }),
                  }}
                />
              </div>
            </div>
          )}

          {/* Contact Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Contact
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormContact}
                onChange={(e) => setEditFormContact(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Adresse Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Adresse
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormAdresse}
                onChange={(e) => setEditFormAdresse(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Code Postal Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Code Postal
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormCodePostal}
                onChange={(e) => setEditFormCodePostal(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Ville Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Ville
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormVille}
                onChange={(e) => setEditFormVille(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Pays Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Pays
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormPays}
                onChange={(e) => setEditFormPays(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Coordonnées Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Coordonnées
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormCoordonnees}
                onChange={(e) => setEditFormCoordonnees(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Description Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Description
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <textarea
                rows={3}
                value={editFormDescription}
                onChange={(e) => setEditFormDescription(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Infos Site Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Infos Site
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <textarea
                rows={3}
                value={editFormInfosSite}
                onChange={(e) => setEditFormInfosSite(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Latitude/Longitude Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Latitude/Longitude
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormLatitudeLongitude}
                onChange={(e) => setEditFormLatitudeLongitude(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

          {/* Site Field */}
          <div className="row mb-3">
            <div className="col-md-3 d-flex justify-content-end">
              <label
                style={{
                  color: "#0099e5",
                  whiteSpace: "nowrap",
                  paddingRight: "10px",
                  paddingTop: "5px",
                  fontSize: "14px",
                }}
                className="fw-semibold text-right"
              >
                Site
              </label>
            </div>
            <div className="col-md-9" style={{ borderLeft: "1px solid #ccc" }}>
              <input
                type="text"
                value={editFormSite}
                onChange={(e) => setEditFormSite(e.target.value)}
                style={{
                  width: "100%",
                  maxWidth: "160px",
                  height: "27px",
                  marginLeft: "5px",
                }}
                className="form-control"
              />
            </div>
          </div>

        
        
      </Row>

      {/* Action Buttons */}
      <div className="d-flex justify-content-end mt-4">
        <Button variant="secondary" onClick={handleCloseEditModal} style={{ marginRight: "10px" }}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleEditFormSubmit} disabled={!editFormName || loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Modifier"}
        </Button>
      </div>
    </Form>
  );
};

export default EditForm;
