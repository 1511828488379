// FormBuilderPage.js
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import Header from '../../Layout/menu/Header';
import Footer from '../../Layout/menu/Footer';

const FormBuilderPage = () => {
  const saveForm = (data) => {
    console.log('Form data:', data);
    // You can send the form data to your backend API here
  };

  return (
    <div>

            <div className="d-flex flex-column flex-root">
                {/* {/*begin::Page */}
                <div className="page d-flex flex-row flex-column-fluid">
                    {/*begin::Wrapper */}
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        {/*begin::Header */}
                       <Header />
                        {/*end::Header */}
                        {/*begin::Toolbar */}
                        <div className="toolbar py-5 pb-lg-15" id="kt_toolbar">
                            {/*begin::Container */}
                            <div id="kt_toolbar_container" className="container-xxl d-flex flex-stack flex-wrap">
                                {/*begin::Page title */}
                                <div className="page-title d-flex flex-column me-3">
                                    {/*begin::Title */}
                                    <h1 className="d-flex text-white fw-bold my-1 fs-3">Form Builder</h1>
                                    {/*end::Title */}
                                    {/*begin::Breadcrumb */}
                                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                                        {/*begin::Item */}
                                        <li className="breadcrumb-item text-white opacity-75">
                                            <a href="/" className="text-white text-hover-primary">Home</a>
                                        </li>
                                        {/*end::Item */}
                                    
                                      
                                        {/*begin::Item */}
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                                        </li>
                                        {/*end::Item */}
                                        {/*begin::Item */}
                                        <li className="breadcrumb-item text-white opacity-75">Form</li>
                                        {/*end::Item */}
                                        {/*begin::Item */}
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                                        </li>
                                        {/*end::Item */}
                                        {/*begin::Item */}
                                        <li className="breadcrumb-item text-white opacity-75">Form Builder</li>
                                        {/*end::Item */}
                                    </ul>
                                    {/*end::Breadcrumb */}
                                </div>
                                {/*end::Page title */}
                             
                            </div>
                            {/*end::Container */}
                        </div>
                        {/*end::Toolbar */}
                        {/*begin::Container */}
                        <div id="kt_content_container" className=" ">
                            {/*begin::Post */}
                            <div className="content flex-row-fluid" id="kt_content">
                            <div className="card">
                                   
                                    {/*begin::Card body */}
                                    <div className="card-body p-2">
                                    <ReactFormBuilder />

                                    </div>

                                {/*end::Card body */}
                            </div>
      
      

                          
                        </div>
                        {/*end::Post */}
                    </div>
                    {/*end::Container */}
                    {/*begin::Footer */}
                  {/* <Footer /> */}
                    {/*end::Footer */}
                </div>
                {/*end::Wrapper */}
            </div>
            {/*end::Page */}
        </div>
     
    </div >
   
  );
};

export default FormBuilderPage;
