import React, { useState, useEffect, useMemo } from "react";
import Header from "../../Layout/menu/Header";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ColumnSelectionModal from "../../components/ColumnSelectionModal";
import { CustomProvider, DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import frFR from 'rsuite/locales/fr_FR';
import api from "../Auth/axiosSetup";
import LoadingIndicator from "../../components/LoadingIndicator";

function Historique() {
  const apiHost = process.env.REACT_APP_API_HOST;
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const navigate = useNavigate();

  // Function to fetch data based on the selected date range
  const fetchUserActivities = async (startDate, endDate) => {
    setLoading(true);
    try {
      const response = await api.get(`/user/${id}/location`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      setForms(response.data);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch data from the API.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchUserActivities(dateRange[0].toISOString(), dateRange[1].toISOString());
    }
  }, [dateRange, id]);

  const initialColumns = [
    { name: "Latitude", selector: (row) => row.latitude },
    { name: "Longitude", selector: (row) => row.longitude },
    { name: "Accuracy", selector: (row) => row.accuracy },
    { name: "Altitude", selector: (row) => row.altitude },
    { name: "Altitude Accuracy", selector: (row) => row.altitude_accuracy },
    { name: "Captured At", selector: (row) => row.captured_at },
  
    { name: "Heading", selector: (row) => row.heading },
    { name: "Speed", selector: (row) => row.speed },
  ];

  const [selectedColumns, setSelectedColumns] = useState(initialColumns.map(col => col.name));

  const columns = useMemo(() =>
    initialColumns.map(col => ({
      ...col,
      omit: !selectedColumns.includes(col.name),
    })),
    [selectedColumns]
  );

  const handleColumnChange = (newSelectedOptions) => {
    const selectedColumnNames = newSelectedOptions.map(option => option.value);
    setSelectedColumns(selectedColumnNames);
  };

  const handleViewChange = () => {
    console.log("Here")
    // Derive points for the Cartographie component
    const points = forms.map(form => ({
      lat: form.latitude,
      lng: form.longitude,
    }));

    // Navigate to Cartographie page with points as state
    navigate("/cartographie", { state: { points } });
  };

  return (
    <div>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <Header />
            <div className="Customcontainer bg-white">
              <div className="row bg-white" style={{ flex: 1, minHeight: "91.1vh" }}>
              <div
  className="col-2 p-5"
  style={{ borderRight: "1px solid rgba(58,56,56,.1)" }}
>
  <p
    className="etech-sidebar-section"
    style={{
      color: "#3a3838",
      fontWeight: "700",
      padding: ".25em 0",
      fontSize: "1.25em",
      borderBottom: "2px solid #3a3838",
    }}
  >
    Historique Technicien
  </p>
  
  <div className="mt-4">
    <ColumnSelectionModal
      columns={initialColumns}
      selectedColumns={selectedColumns}
      handleColumnChange={handleColumnChange}
      pageKey="page_historique" // Unique for each page

    />
  </div>
  
  <div className="mt-4">
    <hr />
    <h4
      style={{
        fontSize: "1.2em",
        fontWeight: "600",
        marginBottom: "1em",
      }}
    >
      Intervalle de Dates
    </h4>
    <CustomProvider locale={frFR} > 
    <DateRangePicker
      value={dateRange}
      onChange={setDateRange}
      placeholder="Sélectionner une période"
      format="yyyy-MM-dd"
      block
      style={{ marginBottom: "20px" }}
    />
    </CustomProvider>
    <Button
      onClick={handleViewChange}
      variant="primary"
      style={{ marginBottom: "20px" }}
    >
      Afficher sur la carte
    </Button>
  </div>
</div>

                <div className="col-10">
                  <h1
                    className="d-flex fw-bold my-1 fs-3 p-4"
                    style={{ fontSize: "1.5em", fontWeight: "400" }}
                  >
                    Historique
                  </h1>
                  <div className="card-body pt-0">
                    
                    
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingIndicator />
                      </div>
                    ) : (
                      <>
                        {error ? (
                          "Data not Found"
                        ) : (<>
                          
                          <DataTable
                            columns={columns}
                            data={forms}
                            pagination
                            highlightOnHover={true}
                            paginationPerPage={10}
                            customStyles={{
                              headCells: {
                                style: {
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  textTransform: "uppercase",
                                  color: "black",
                                },
                              },
                            }}
                          />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Historique;
