import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashbordPage from "./Pages/Dashboard/DashbordPage";
import Formulaires from "./Pages/Formulaires/Formulaires";
import Interventions from "./Pages/Interventions/Interventions";
import Activities from "./Pages/Activities/Activities";
import CreateActivity from "./Pages/Activities/CreateActivity";
import CreateIntervention from "./Pages/Interventions/CreateIntervention";
import ListTechnicien from "./Pages/Techniciens/ListTechnicien";
import FormBuilderPage from "./Pages/Formulaires/FormbuilderPage";
import Flags from "./Pages/Flags/Flags";
import Referentiel from "./Pages/Referentiel/Referentiel";
import ReferencielList from "./Pages/Referentiel/RefencielList";
import ValeurReferentielList from "./Pages/Referentiel/ValeurReferentielList";
import InterventionDetail from "./Pages/Interventions/InterventionDetail";
import EditForm from "./Pages/Formulaires/EditForm";
import SearchIntervention from "./Pages/SearchIntervention/SearchIntervention";
import ResultIntervention from "./Pages/SearchIntervention/ResultIntervention";
import UserActivitiesDetail from "./Pages/Planing/UserActivitiesDetail";
import EditActivity from "./Pages/Activities/EditActivity";
import CategoryReferentiel from "./Pages/Referentiel/CategoryReferentiel";
import CategoryReferentielValReferentiel from "./Pages/Referentiel/CategoryReferentielValReferentiel";
import InterventionType from "./Pages/Interventions/Type/InterventionType";
import CreateTechnicien from "./Pages/Techniciens/CreateTechnicien";
import CreateInterventionType from "./Pages/Interventions/Type/CreateInterventionType";
import EditTechnicien from "./Pages/Techniciens/EditTechnicien";
import Cartographie from "./Pages/Catrographie/Cartographie";
import UserActivites from "./Pages/Planing/UserActivites";
import EditInterventionType from "./Pages/Interventions/Type/EditInterventionType";
import DernieresPositions from "./Pages/Geolocalisation/dernierespositions";
import Historique from "./Pages/Geolocalisation/Historique";
import GoogleMapsLoader from "./components/GoogleMapsLoader";
import PrivateRoute from "./Pages/Auth/PrivateRoute"; 
import LoginPage from "./Pages/Auth/LoginPage";
import Test from "./Pages/Test";
import { AuthProvider } from "./Pages/Auth/AuthProvider";

function App() {
  return (
    <AuthProvider >
    <Router>
      <div className="App">
        <GoogleMapsLoader googleMapsApiKey="AIzaSyDLsf_Bg2fD6QlB2DpIFWgsOJ4JX8CWXO8">
          <Routes>
           {/* Protected Routes */}
           <Route path="/" element={<PrivateRoute><DashbordPage /></PrivateRoute>} />
            <Route path="/formulaires" element={<PrivateRoute><Formulaires /></PrivateRoute>} />
            <Route path="/formbuilder/:id" element={<PrivateRoute><FormBuilderPage /></PrivateRoute>} />
            <Route path="/interventions" element={<PrivateRoute><Interventions /></PrivateRoute>} />
            <Route path="/interventiondetail/:id" element={<PrivateRoute><InterventionDetail /></PrivateRoute>} />
            <Route path="/techniciens" element={<PrivateRoute><ListTechnicien /></PrivateRoute>} />
            <Route path="/techniciens/create" element={<PrivateRoute><CreateTechnicien /></PrivateRoute>} />
            <Route path="/techniciens/edit/:id" element={<PrivateRoute><EditTechnicien /></PrivateRoute>} />
            <Route path="/activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
            <Route path="/creatactivity" element={<PrivateRoute><CreateActivity /></PrivateRoute>} />
            <Route path="/create-intervention" element={<PrivateRoute><CreateIntervention /></PrivateRoute>} />
            <Route path="/flags" element={<PrivateRoute><Flags /></PrivateRoute>} />
            <Route path="/referentiel" element={<PrivateRoute><Referentiel /></PrivateRoute>} />
            <Route path="/referentiellist/:idCategory" element={<PrivateRoute><ReferencielList /></PrivateRoute>} />
            <Route path="/categories-referentiel" element={<PrivateRoute><CategoryReferentiel /></PrivateRoute>} />
            <Route path="/valeurreferentiel/:id" element={<PrivateRoute><ValeurReferentielList /></PrivateRoute>} />
            <Route path="/editform/:id" element={<PrivateRoute><EditForm /></PrivateRoute>} />
            <Route path="/search-intervention" element={<PrivateRoute><SearchIntervention /></PrivateRoute>} />
            <Route path="/search-intervention/result" element={<PrivateRoute><ResultIntervention /></PrivateRoute>} />
            <Route path="/edit-activitie/:id" element={<PrivateRoute><EditActivity /></PrivateRoute>} />
            <Route path="/planing/:id" element={<PrivateRoute><UserActivitiesDetail /></PrivateRoute>} />
            <Route path="/type-intervention" element={<PrivateRoute><InterventionType /></PrivateRoute>} />
            <Route path="/type-intervention/create" element={<PrivateRoute><CreateInterventionType /></PrivateRoute>} />
            <Route path="/type-intervention/edit/:id" element={<PrivateRoute><EditInterventionType /></PrivateRoute>} />
            <Route path="/cartographie" element={<PrivateRoute><Cartographie /></PrivateRoute>} />
            <Route path="/useractivites" element={<PrivateRoute><UserActivites /></PrivateRoute>} />
            <Route path="/dernierespositions" element={<PrivateRoute><DernieresPositions /></PrivateRoute>} />
            <Route path="/historique/:id" element={<PrivateRoute><Historique /></PrivateRoute>} />
            
            <Route path="/test" element={<Test />} />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </GoogleMapsLoader>
      </div>
    </Router>
    </AuthProvider >
  );
}

export default App;
