import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Layout/menu/Footer";
import Header from "../../Layout/menu/Header";

import Select from "react-select";
import DatePicker from "react-datepicker";
import { addDays, addHours, addMinutes, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import CategoryReferentielValReferentiel from "../Referentiel/CategoryReferentielValReferentiel";
import api from "../Auth/axiosSetup";

function CreateIntervention() {
  const [values, setValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [error, setError] = useState(null);
  const [selectedPhrase, setSelectedPhrase] = useState("");
  const apiHost = process.env.REACT_APP_API_HOST;
  const [lastReferentiel, setLastReferentiel] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [valReferentiel, setValReferentiel] = useState(null);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    form_id: "",
    date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    time: "",
    withAppointment: false,

    typeInt: "",
    unit: "",
    flag_id: null,
    description: "",
    afterDate: null, // Initialize with current date and time
    afterText: null,
    beforeDate: null, // Optionally, initialize beforeDate as well
    beforeText: null,
    valeur_referentiels_id: valReferentiel,
    intervention_type_id: null,
  });
  useEffect(() => {
    api
      .get(`${apiHost}/referentiels-intervention`)
      .then((response) => {
        setValues({
          0: response.data.referentiel_parent.map((item) => ({
            value: item.id,
            label: getDisplayValue(item),
          })),
        });
      })
      .catch((error) => {
        console.error(error);
        setError("Failed to fetch data from the API.");
      });
  }, []);

  const handleChangeReferentiel = (level, selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    let newSelectedValues = { ...selectedValues, [level]: value };
    // Sauvegarder le dernier référentiel sélectionné et l'ajouter à formData
    setLastReferentiel(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      valeur_referentiels_id: value,
    }));
    // Réinitialiser les valeurs sélectionnées pour les niveaux suivants
    Object.keys(newSelectedValues).forEach((key) => {
      if (parseInt(key) > level) {
        delete newSelectedValues[key];
      }
    });

    setSelectedValues(newSelectedValues);

    // Réinitialiser les options pour les niveaux suivants
    let newValues = { ...values };
    Object.keys(newValues).forEach((key) => {
      if (parseInt(key) > level) {
        delete newValues[key];
      }
    });

    setValues(newValues);

    // Construire l'URL en fonction des sélections précédentes
    let apiUrl = `${apiHost}/referentiels-intervention`;
    for (let i = 0; i <= level; i++) {
      if (newSelectedValues[i]) {
        apiUrl += `/${newSelectedValues[i]}`;
      }
    }

    api
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          const nextLevelData =
            level % 2 === 0 ? response.data : response.data.enfants;
          if (!nextLevelData.length && level % 2 !== 0) return;
          setValues({
            ...newValues,
            [level + 1]: nextLevelData.map((item) => ({
              value: item.id,
              label: getDisplayValue(item),
            })),
          });
        } else {
          console.log(`Received non-200 status code: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error(error);
        setError("Failed to fetch data from the API.");
      });

    // Mettre à jour la phrase sélectionnée
    updateSelectedPhrase(newSelectedValues);
  };

  const updateSelectedPhrase = (selectedValues) => {
    const selectedLabels = Object.keys(selectedValues)
      .map((level) => {
        const selectedValue = selectedValues[level];
        const selectedOption = values[level].find(
          (option) => option.value === selectedValue
        );
        return selectedOption ? selectedOption.label : "";
      })
      .filter((label) => label !== "");
    setSelectedPhrase(`Intervention à : ${selectedLabels.join(", ")}`);
  };

  const getDisplayValue = (value) => {
    return value.valeur || value.nom || "Unknown";
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      beforeDate: date !== null ? format(date, "yyyy-MM-dd HH:mm:ss") : null,
      beforeText: date !== null ? formData.beforeText : null,
    });
  };

  const handleDateChange2 = (date) => {
    setFormData({
      ...formData,
      afterDate: date !== null ? format(date, "yyyy-MM-dd HH:mm:ss") : null,
      afterText: date !== null ? formData.afterText : null,
    });
  };
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formulairesOptions, setFormulairesOptions] = useState([]);
  const [selectedFormulaire, setSelectedFormulaire] = useState(null);

  useEffect(() => {
    const fetchParents = async () => {
      try {
        const response = await api.get(
          `${apiHost}/referentiels-intervention`
        );
        //  console.log("HIi ==>", response.data.referentiel_parent);
        const parents = response.data.referentiel_parent.map((parent) => ({
          value: parent.id,
          label: parent.valeur,
        }));
        setOptions(parents);
      } catch (error) {
        console.error("Error fetching parents:", error);
      }
    };
    const fetchFormulaires = async () => {
      try {
        const response = await api.get(`/forms`);
        const formulaires = response.data.data.map((formulaire) => ({
          value: formulaire.id,
          label: formulaire.name,
        }));
        setFormulairesOptions(formulaires);
      } catch (error) {
        console.error("Error fetching formulaires:", error);
      }
    };
    fetchFormulaires();

    fetchParents();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log("Selected Option:", selectedOption);
    // You can perform additional actions based on the selected option
  };
  const handleFormulaireChange = (selectedFormulaire) => {
    setSelectedFormulaire(selectedFormulaire);

    if (selectedFormulaire) {
      setFormData({ ...formData, form_id: selectedFormulaire.value });
      console.log("Selected Formulaire:", selectedFormulaire);
    } else {
      setFormData({ ...formData, form_id: null }); // or handle it as per your requirement
      console.log("Selected Formulaire cleared");
    }
  };

  const [flags, setFlags] = useState([]);
  const [users, setUsers] = useState([]);
  const [typeInterventions, setTypeInterventions] = useState([]);

  const [levels, setLevels] = useState([[]]); // To store options for each level
  const [selected, setSelected] = useState([]); // To store selected values for each level
  const [hierarchicalPath, setHierarchicalPath] = useState(""); // To store the hierarchical path

  useEffect(() => {
    // Fetch the initial level data
    fetchLevelData();
    fetchFlags();
    fetchUsers();
    fetchTypeIntervention();
  }, []);

  const fetchFlags = async () => {
    try {
      const response = await api.get(`/flags`);
      setFlags(response.data);
    } catch (error) {
      console.error("Error fetching flags:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get(`/users`);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching flags:", error);
    }
  };

  const fetchTypeIntervention = async () => {
    try {
      const response = await api.get(`/intervention-types`);
      setTypeInterventions(response.data);
      console.log("Intervention Types:", response.data);
    } catch (error) {
      console.error("Error fetching flags:", error);
    }
  };
  const handleTimeChange = (e, type) => {
    const value = e.target.value;
  
    setFormData((prevData) => {
      // Met à jour soit les heures soit les minutes dans formData
      const updatedData = {
        ...prevData,
        [type]: value
      };
  
      // Si les deux champs sont remplis, concatène les heures et minutes au format HH:MM
      if (updatedData.hours && updatedData.minutes) {
        updatedData.time = `${updatedData.hours.padStart(2, '0')}:${updatedData.minutes.padStart(2, '0')}`;
      }
  
      return updatedData;
    });
  };
  

  const Usersoptions = users.map((user) => ({
    value: user.id, // Assuming your flag object has an 'id' field
    label: user.nom, // Assuming your flag object has a 'name' field
  }));
  const Flagsoptions = flags.map((flag) => ({
    value: flag.id, // Assuming your flag object has an 'id' field
    label: flag.name, // Assuming your flag object has a 'name' field
    color: flag.color, // Assuming your flag object has a 'color' field
  }));

  const TypeInterventionsOptions = typeInterventions.map((type) => ({
    value: type.id,
    label: type.name,
    duration:type.duration,

    form: {
      id: type.form.id,
      name: type.form.name,
    },
    category: {
      id: type.category.id,
      name: type.category.name,
    },
  }));
  
  const [selectedInterventionType, setSelectedInterventionType] = useState(null);
  const [interventionTypes, setInterventionTypes] = useState([]);

  const handleTypeInterventionChange = (selectedOption) => {
    if (selectedOption) {
      console.log("selectedOption ===>",selectedOption)
      const selectedType = typeInterventions.find(
        (type) => type.id === selectedOption.value
      );
  
      if (selectedType) {
        // Update formData with the selected intervention type, form, category, and referentiel
        setFormData((prevFormData) => ({
          ...prevFormData,
          intervention_type_id: selectedOption.value,
          form_id: selectedType.form.id,
          category_id: selectedType.category.id,
          valeur_referentiels_id: selectedType.referentiel_id, 
          time : selectedType.duration,
          unit: selectedType.unit        }));
  
        // Set selectedFormulaire and referentiel
        // setInterventionselectedType(selectedType);
        setSelectedFormulaire(selectedType.form);
      }
    } else {
      // Clear formData and reset selectedFormulaire and referentiel
      setFormData((prevFormData) => ({
        ...prevFormData,
        intervention_type_id: null,
        form_id: null,
        category_id: null,
        valeur_referentiels_id: null,
        time :null
      }));
      setSelectedFormulaire(null);
    }
  };
  
  
  const fetchLevelData = async (path = "") => {
    let url = `${apiHost}/referentiels-intervention`;
    if (path) {
      url += `/${path}`;
    }

    try {
      const response = await api.get(url);
      const data = response.data.referentiel_parent || response.data; // Adjust based on response structure

      setLevels((prevLevels) => {
        const newLevels = [...prevLevels];
        const levelIndex = path.split("/").length - 1;
        newLevels[levelIndex] = data;
        return newLevels.slice(0, levelIndex + 1); // Remove any subsequent levels
      });

      setSelected((prevSelected) =>
        prevSelected.slice(0, path.split("/").length - 1)
      );
    } catch (error) {
      console.error("Error fetching level data:", error);
    }
  };
  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      flag_id: selectedOption ? selectedOption.value : null,
    });
  };
  const handleChangeUser = (selectedOption) => {
    setFormData({
      ...formData,
      user_id: selectedOption ? selectedOption.value : null,
    });
  };

  const handleSelection = (level, selectedValue) => {
    setSelected((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[level] = selectedValue;
      return newSelected;
    });

    const updatedHierarchicalPath = selected
      .slice(0, level)
      .map((item) => item.id)
      .concat(selectedValue.id)
      .join("/");

    setHierarchicalPath(updatedHierarchicalPath);

    // Update formData with the hierarchical identifier
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`level${level}`]: selectedValue.id,
      hierarchicalPath: updatedHierarchicalPath,
    }));

    fetchLevelData(updatedHierarchicalPath);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleTextareaChange = (event) => {
    setFormData({
      ...formData,
      description: event.target.value, // Update the description field in formData
    });
  };
  const calculateEndDate = (startDate, duration) => {
    if (!duration) return startDate; // Return startDate if no duration

    const [hours, minutes] = duration.split(':').map(Number); // Split duration into hours and minutes
    let endDate = startDate;

    if (!isNaN(hours)) {
      endDate = addHours(endDate, hours); // Add hours
    }

    if (!isNaN(minutes)) {
      endDate = addMinutes(endDate, minutes); // Add minutes
    }

    return endDate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await api.post(`/interventionsstoreFront`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoading(false);
      if (response.status >= 200 && response.status < 300) {
        navigate("/interventions");
      } else {
        const errorData = response.data; 
        setErrorMessages(errorData.errors);
        throw new Error("Failed to create intervention");
      }
    } catch (error) {
      setLoading(false);

      console.error("Error:", error);
    }
  };
  const handleUnitChange = (selectedOption) => {
    setFormData({
      ...formData,
      unit: selectedOption ? selectedOption.value : "Heures/Minutes",
    });
  };
  const optionsRDV = [
    { value: "Heures/Minutes", label: "Heures/Minutes" },
    { value: "Jours", label: "Jours" },
  ];
  const handleDurationChange = (event) => {
    setFormData({
      ...formData,
      time: event.target.value,
    });
  };
  return (
    <div class="d-flex flex-column flex-root">
      {/*begin::Page */}
      <div class="page d-flex flex-row flex-column-fluid">
        {/*begin::Wrapper */}
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          {/*begin::Header */}
          <Header />
          {/*end::Header */}

          <div className="toolbar py-5 pb-lg-15" id="kt_toolbar">
            {/*begin::Container */}
            <div
              id="kt_toolbar_container"
              className="container-xxl d-flex flex-stack flex-wrap"
            >
              {/*begin::Page title */}
              <div className="page-title d-flex flex-column me-3">
                {/*begin::Title */}
                <h1 className="d-flex text-white fw-bold my-1 fs-3">
                  Creation d'intervention
                </h1>
                {/*end::Title */}
                {/*begin::Breadcrumb */}
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                  {/*begin::Item */}
                  <li className="breadcrumb-item text-white opacity-75">
                    <a href="/" className="text-white text-hover-primary">
                      Home
                    </a>
                  </li>
                  {/*end::Item */}
                  {/*begin::Item */}
                  <li className="breadcrumb-item">
                    <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                  </li>

                  {/*begin::Item */}
                  <Link to="/interventions">
                    <li className="breadcrumb-item text-white opacity-75">
                      Intervention
                    </li>
                  </Link>
                  {/*end::Item */}
                  {/*begin::Item */}
                  <li className="breadcrumb-item">
                    <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                  </li>
                  {/*end::Item */}
                  {/*begin::Item */}
                  <li className="breadcrumb-item text-white opacity-75">
                    Cree Intervontion
                  </li>
                  {/*end::Item */}
                </ul>
                {/*end::Breadcrumb */}
              </div>
              {/*end::Page title */}
            </div>
            {/*end::Container */}
          </div>
          {/*begin::Container */}
          <div
            id="kt_content_container"
            class="d-flex flex-column-fluid align-items-start container-xxl"
          >
            {/*begin::Post */}
            <div class="content flex-row-fluid" id="kt_content">
              {/*begin::Careers - Apply */}
              <div class="card">
                {/*begin::Body */}
                <div class="card-body p-lg-17">
                  {/*begin::Hero */}
                  {/* {JSON.stringify(formData)} */}
                  <div class="d-flex flex-column flex-lg-row mb-17">
                    {/*begin::Content */}
                    <div class="flex-lg-row-fluid me-0 me-lg-20">
                      {/*begin::Form */}
                      <form
                        action="m-0"
                        class="form mb-15"
                        method="post"
                        id="kt_careers_form"
                      >
                        {/*begin::Input group */}
                        <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class=" fs-5 fw-semibold mb-2">
                              Description
                            </label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div class="col-md-4 fv-row">
                            {/*end::Input */}
                            <textarea
                              className="form-control form-control-solid"
                              rows={4}
                              name="application"
                              placeholder=""
                              defaultChecked={formData.description}
                              onChange={handleTextareaChange}
                            />
                            {/*end::Input */}
                          </div>
                          {/*end::Col */}
                        </div>
                        {/*end::Input group */}
                        {/*begin::Input group */}
                        <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class="fs-5 fw-semibold mb-2">
                              Drapeaux
                            </label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div class="col-md-4 fv-row">
                            <Select
                              name="Catégorie_de_l_activité"
                              options={Flagsoptions}
                              value={Flagsoptions.find(
                                (option) =>
                                  option.value ===
                                  formData.Catégorie_de_l_activité
                              )}
                              onChange={handleChange}
                              className="basic-single"
                              isClearable
                              classNamePrefix="select"
                              required
                            />
                            {errorMessages.flag_id && (
                              <div className="text-danger">
                                {errorMessages.flag_id}
                              </div>
                            )}
                          </div>
                        </div>
                        {/*end::Input group */}

                        {/*end::Col */}

                        <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class=" fs-5 fw-semibold mb-2">
                              Date debut
                            </label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div className="col-md-4 fv-row">
                            <DatePicker
                              selected={new Date(formData.date)}
                              onChange={(_date) =>
                                setFormData({
                                  ...formData,
                                  date: _date
                                    ? format(_date, "yyyy-MM-dd HH:mm:ss")
                                    : format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                                })
                              }
                              showTimeSelect
                              className="form-control form-control-solid"
                              name="beforeText"
                              aria-label=""
                              dateFormat="yyyy-MM-dd HH:mm"
                            />
                          </div>
                          {/*end::Col */}
                        </div>
                        {/*begin::Input group */}
                        <div class="row mb-5">
                          <div class="col-md-2 fv-row">
                            <label class=" fs-5 fw-semibold mb-2">
                              A faire apres
                            </label>
                          </div>

                          <div className="col-md-4 fv-row">
                            <DatePicker
                              isClearable
                              selected={
                                formData.beforeDate &&
                                new Date(formData.beforeDate)
                              }
                              onChange={handleDateChange}
                              showTimeSelect
                              className="form-control form-control-solid"
                              name="beforeDate"
                              aria-label=""
                              dateFormat="yyyy-MM-dd HH:mm"
                              maxDate={
                                formData.afterDate &&
                                new Date(formData.afterDate)
                              }
                              placeholderText="Sélectionnez une date"
                            />
                          </div>
                        </div>

                        {formData.beforeDate && (
                          <div class="row mb-5">
                            <div class="col-md-2 fv-row">
                              <label class=" fs-5 fw-semibold mb-2">
                                Texte apres
                              </label>
                            </div>
                            <div class="col-md-4 fv-row">
                              {/*end::Input */}
                              <textarea
                                className="form-control form-control-solid"
                                rows={4}
                                name="application"
                                placeholder=""
                                defaultChecked={formData.beforeText}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    beforeText: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class=" fs-5 fw-semibold mb-2">
                              A faire Avant
                            </label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div className="col-md-4 fv-row">
                            <DatePicker
                              selected={
                                formData.afterDate &&
                                new Date(formData.afterDate)
                              }
                              onChange={handleDateChange2}
                              showTimeSelect
                              dateFormat="yyyy-MM-dd HH:mm"
                              className="form-control form-control-solid"
                              name="beforeText"
                              placeholderText="Sélectionnez une date"
                              isClearable
                              minDate={
                                formData.beforeDate &&
                                (() => {
                                  const date = new Date(formData.beforeDate);
                                  date.setDate(date.getDate() + 1);
                                  return date;
                                })()
                              }
                            />
                          </div>
                          {/*end::Col */}
                        </div>
                        {formData.afterDate && (
                          <div class="row mb-5">
                            <div class="col-md-2 fv-row">
                              <label class=" fs-5 fw-semibold mb-2">
                                Texte Avant
                              </label>
                            </div>
                            <div class="col-md-4 fv-row">
                              {/*end::Input */}
                              <textarea
                                className="form-control form-control-solid"
                                rows={4}
                                name="application"
                                placeholder=""
                                defaultChecked={formData.afterDate}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    afterText: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div class="row mb-5" style={{display:"none"}}>
                          <div class="col-md-2 fv-row">
                            <label class="fs-5 fw-semibold mb-2">
                              Formulaires
                            </label>
                          </div>
                          <div class="col-md-4 fv-row">
                            <Select
                              options={formulairesOptions}
                              value={selectedFormulaire}
                              onChange={handleFormulaireChange}
                              isSearchable
                              isClearable
                            />
                            {errorMessages.form_id && (
                              <div className="text-danger">
                                {errorMessages.form_id}
                              </div>
                            )}
                          </div>
                        </div>
                        {/*begin::Input group */}
                        <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class="fs-5 fw-semibold mb-2">
                              Techniciens
                            </label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div class="col-md-4 fv-row">
                            <Select
                              name="user_id"
                              options={Usersoptions}
                              value={Usersoptions.find(
                                (option) => option.value === formData.user_id
                              )}
                              onChange={handleChangeUser}
                              className="basic-single"
                              isClearable
                              classNamePrefix="select"
                              required
                            />
                            {errorMessages.user_id && (
                              <div className="text-danger">
                                {errorMessages.user_id}
                              </div>
                            )}
                          </div>
                        </div>
                        {/*end::Input group */}

                        {/* Intervention Type */}
                        <div class="row mb-5">
                          {/*begin::Col */}
                          <div class="col-md-2 fv-row">
                            {/*begin::Label */}
                            <label class="fs-5 fw-semibold mb-2">Type d'interv</label>
                            {/*end::Label */}
                          </div>
                          {/*end::Col */}
                          {/*begin::Col */}
                          <div class="col-md-4 fv-row">
                            <Select
                              name="intervention_type_id"
                              options={TypeInterventionsOptions}
                              value={TypeInterventionsOptions.find(
                                (option) =>
                                  option.value === formData.intervention_type_id
                              )}
                              onChange={handleTypeInterventionChange}

                              className="basic-single"
                              isClearable
                              classNamePrefix="select"
                            />
                            {errorMessages.intervention_type_id && (
                              <div className="text-danger">
                                {errorMessages.intervention_type_id}
                              </div>
                            )}
                          </div>
                          
                        </div>
                        {/* Unit Selection */}
      {formData.intervention_type_id && (
        <div className="row mb-5">
          {/*begin::Col */}
          <div className="col-md-2 fv-row">
            {/*begin::Label */}
            <label className="fs-5 fw-semibold mb-2">Unité</label>
            {/*end::Label */}
          </div>
          {/*end::Col */}
          {/*begin::Col */}
          <div className="col-md-4 fv-row">
          <Select
            // isClearable
            options={optionsRDV}
            value={optionsRDV.find((option) => option.value === formData.unit)}
            onChange={handleUnitChange}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={optionsRDV[0]} // Set the default value to the first option
          />

          </div>
          {/*end::Col */}
        </div>
      )}
     {formData.intervention_type_id && (
        <div className="row mb-5">
          {/*begin::Col */}
          <div className="col-md-2 fv-row">
            {/*begin::Label */}
            <label className="fs-5 fw-semibold mb-2">Durée prévue</label>
            {/*end::Label */}
          </div>
          {/*end::Col */}
          {/*begin::Col */}
          <div className="col-md-4 fv-row">
            {formData.unit === 'Heures/Minutes' ? (
              // Deux champs pour heures et minutes
              <div className="d-flex align-items-center">
                <input
                  type="number"
                  name="hours"
                  placeholder="HH"
                  value={(formData.time && formData.time.split(':')[0]) || ""}  // Champs pour les heures
                  onChange={(e) => handleTimeChange(e, 'hours')}
                  className="form-control me-2"
                  min="0"
                  max="23"
                />
                <span style={{fontWeight:"bold"}}>:</span>
                <input
                  type="number"
                  name="minutes"
                  placeholder="MM"
                  value={(formData.time && formData.time.split(':')[1]) || ""}  // Champs pour les minutes
                  onChange={(e) => handleTimeChange(e, 'minutes')}
                  className="form-control ms-2"
                  min="0"
                  max="59"
                />
              </div>
            ) : formData.unit === 'Jours' ? (
              // Champ pour le nombre de jours
              <input
                type="number"
                name="duration"
                placeholder="e.g., 3 jours"
                value={formData.time || ""}
                onChange={handleDurationChange}
                className="form-control"
                min="0"
              />
            ) : (
              // Si aucune unité sélectionnée
              <input
                type="text"
                name="duration"
                value={formData.time || ""}
                onChange={handleDurationChange}
                className="form-control"
              />
            )}

            {errorMessages.time && (
              <div className="text-danger">
                {errorMessages.time}
              </div>
            )}
          </div>
          {/*end::Col */}
        </div>
      )}


      {formData.intervention_type_id && (
        <div className="row mb-5">
          {/*begin::Col */}
          <div className="col-md-2 fv-row">
            {/*begin::Label */}
            <label className="fs-5 fw-semibold mb-2">Date Fin</label>
            {/*end::Label */}
          </div>
          {/*end::Col */}
          {/*begin::Col */}
          <div className="col-md-4 fv-row">

<DatePicker
  selected={
    formData.datefin 
      ? new Date(formData.datefin) 
      : (() => {
          // Calculating the end date based on `date` and `time`
          let calculatedEndDate = calculateEndDate(new Date(formData.date), formData.time);
          
          // Check if the unit is "Jours" and apply conversion
          if (formData.unit === 'Jours') {
            console.log('Conversion des jours en heures');
            const days = parseFloat(formData.time) || 0;  // Ensure time is a number
            const hours = days * 24;  // Convert days to hours
            calculatedEndDate.setHours(calculatedEndDate.getHours() + hours);  // Add the hours to the start date
          }
          
          // Update `formData` with the formatted calculated `datefin`
          const formattedDate = format(calculatedEndDate, "yyyy-MM-dd HH:mm:ss");
          setFormData((prevData) => ({
            ...prevData,
            datefin: formattedDate  // Update `datefin` with the formatted date
          }));

          return calculatedEndDate;  // Return the adjusted end date
        })()
  }
  onChange={(date) => {
    const formattedDateFin = date 
      ? format(date, "yyyy-MM-dd HH:mm:ss")  // Format the selected date
      : format(new Date(), "yyyy-MM-dd HH:mm:ss");  // Fallback to current date if null
    setFormData((prevData) => ({
      ...prevData,
      datefin: formattedDateFin  // Update `datefin` with the formatted date
    }));
  }}
  showTimeSelect
  className="form-control form-control-solid"
  name="afterText"
  aria-label=""
  dateFormat="yyyy-MM-dd HH:mm"
/>


            {errorMessages.time && (
              <div className="text-danger">
                {errorMessages.time}
              </div>
            )}
          </div>
          {/*end::Col */}
        </div>
      )}
                        {/*end::Section */}

                        <div class="mt-3">
                          <div className="d-flex justify-content-start">
                            <button
                              onClick={handleSubmit}
                              type="button"
                              className="btn btn-primary mx-2"
                              disabled={loading}
                            >
                              {loading ? "Chargement..." : "Enregistrer"}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary mx-2"
                            >
                              Annuler
                            </button>
                          </div>
                        </div>
                      </form>
                      {/*end::Form */}
                      {/*begin::Job */}
                      <div class="mb-10 mb-lg-0"></div>
                      {/*end::Job */}
                    </div>
                    {/*end::Content */}
                    {/*begin::Sidebar*/}
                    <div className="flex-lg-row-auto w-100 w-lg-275px w-xxl-350px">
                      {/*begin::Careers about*/}
                      <div className="card bg-light">
                        {/*begin::Body*/}
                        {valReferentiel ? valReferentiel : null}
                        <div className="card-body">
                          {errorMessages.valeur_referentiels_id && (
                            <div className="text-danger">
                              {errorMessages.valeur_referentiels_id}
                            </div>
                          )}
                          <h1>Réferentiel</h1>
                          <CategoryReferentielValReferentiel
                            valRefCallback={(val) => {
                              // cons(val)
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                valeur_referentiels_id: val,
                              }));
                            }}
                            preselectedCategoryId={formData.category_id} // Pass the selected category id

                          />
                          {/* {errorMessages.valeur_referentiels_id && (
                           <div className="text-danger">{errorMessages.valeur_referentiels_id}</div>
                         )}
      {Object.keys(values).map((level) => (
        <div key={level} className='mb-3'>
          <Select
            value={selectedValues[level] ? { value: selectedValues[level], label: values[level].find(option => option.value === selectedValues[level])?.label } : null}
            onChange={(selectedOption) => handleChangeReferentiel(parseInt(level), selectedOption)}
            options={values[level]}
            isClearable
          />
          <div className="form-text">
            {level % 2 === 0 ? 'Referentiel' : 'Valeur référentiel'}
          </div>
        </div>
      ))}

      <p className="mt-3">{selectedPhrase}</p>                       */}
                        </div>
                        {/*end::Body*/}
                      </div>
                      {/*end::Careers about*/}
                    </div>
                    {/*end::Sidebar*/}
                  </div>
                  {/*end::Layout */}
                </div>
              </div>
              {/*end::Careers - Apply */}
            </div>
            {/*end::Post */}
          </div>
          {/*end::Container */}
          {/*begin::Footer */}
          {/* <Footer /> */}
          {/*end::Footer */}
        </div>
        {/*end::Wrapper */}
      </div>
      {/*end::Page */}
    </div>
  );
}

export default CreateIntervention;
